
// import { Plants } from '@/Interface/register';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';

export interface Plants {
  data: string;
}
import { cdetails } from '@/Interface/register';
import { AppService } from '@services/app.service';
@Component({
  selector: 'app-plants',
  templateUrl: './plants.component.html',
  styleUrls: ['./plants.component.scss']
})

export class PlantsComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  public plant: Plants;
  public columns: any;
  public rows: any;
  temp = [];
city:cdetails[]=[]
  pieChartOptions = {
    responsive: true
  }

  pieChartLabels = ['Lahore', 'Karachi', 'Multan', 'Rawalpindi', 'Faisalabad'];

  // CHART COLOR.
  pieChartColor: any = [
    {
      backgroundColor: ['rgba(30, 169, 224, 0.8)',
        'rgba(255,165,0,0.9)',
        'rgba(139, 136, 136, 0.9)',
        'rgba(255, 161, 181, 0.9)',
        'rgba(255, 102, 0, 0.9)'
      ]
    }
  ]

  pieChartData: cdetails[] = [
    
  ];

  // pdetail: Plants
  // this.columns = [
  //   { prop: 'name' },
  //   { name: 'Capacity' },
  //   { name: 'CurrentPower' },
  //   { name: 'TodayGeneration' },
  //   { name: 'UpdatedAT' },
  //   { name: 'Status' }];
  constructor(private ngx: NgxDatatableModule, private http: HttpClient, private app: AppService) {
    this.columns = [
      { name: 'Plant Name' },
      { name: 'Capacity' },
      { name: 'Current Power' },
      { name: 'Today Generation' },
      { name: 'Last Updated' },
      { name: 'Status' }];
    this.http.get<Plants>('../../assets/plant.json').subscribe((res) => {
      
      this.rows = res.data
    })

    this.fetch(data => {
      // cache our list
      this.temp = [...data];

      // push our inital complete list
      this.rows = data;
    });
  }

  fetch(cb) {
    const req = new XMLHttpRequest();
    
    req.open('Get', '../../assets/plant.json');

    req.onload = () => {
      cb(JSON.parse(req.response));
      
    };
    req.send();
  }
  ngOnInit(): void {
  
    this.fetchg()

  }
  fetchg() {
    this.app.graphDetails().subscribe(res => {
      this.city = res.data.cities_count

    },
      
    )
 
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    
    this.rows = temp;
     this.table.offset = 0;
  }

}
