import { Category } from '@/Interface/category';
import { CategoryById } from '@/Interface/categorybyid';
import { Source } from '@/Interface/source';
import { CategoryModel } from '@/model/catlist';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '@services/utility.service';
import Swal from "sweetalert2";

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
    categoryForm: FormGroup;
    categoryadd: FormGroup;

    categoryupdate: CategoryById;
    categoryList: Category;
    categoryEdit = new CategoryModel();
    getSourceList: Source;
    categoryid: number;
    constructor(
        private modalService: NgbModal,
        private _utilitySevice: UtilityService,
        private formbuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.getCategory();
        this.getSourceName();
        this.categoryadd = this.formbuilder.group({
            categoryNameAdd: new FormControl(null, Validators.required),
            sourceNameAdd: new FormControl(null, Validators.required)
        });

        this.categoryForm = this.formbuilder.group({
            categoryName: new FormControl(null, Validators.required),
            sourceName: new FormControl(null, Validators.required)
        });
      
    }
    open(item: Category, content: any) {
        // this.categoryid = item.id;
        var sendIdCat = item.id;
        this._utilitySevice.selectCategoryRow(sendIdCat).subscribe((res) => {
            if (res) {
                this.categoryupdate = res[0];
                this.categoryForm.patchValue({
                    categoryName: this.categoryupdate.getCatName(),
                    sourceName: this.categoryupdate.getSouceId()
                });
            }
        });
        this.modalService.open(content);
    }

    getCategory() {
        this._utilitySevice.receiveCategory().subscribe((res) => {
            if (res) {
                this.categoryList = res;
            }
        });
    }

    getSourceName() {
        this._utilitySevice.receiveSourceName().subscribe((res) => {
            if (res) {
                this.getSourceList = res;
             }
        });
    }

    categorySubmit() {
        this.categoryEdit.id = this.categoryupdate.id;
        this.categoryEdit.category_name = this.categoryForm.value.categoryName;
        this.categoryEdit.source_id = this.categoryForm.value.sourceName;

        this._utilitySevice
            .updateCategory(this.categoryEdit)
            .subscribe((res) => {
                if (res) {
                    this.getCategory();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '`<h2>Category  Successfully Updated</h2>`',
                        showConfirmButton: false,
                        timer: 1500
                      })
                  
                }
            });
    }

    delateCategory(item: Category) {
        var id = item.getId();
        Swal.fire({
            title: 'Are you sure to delete this Category?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this._utilitySevice.deleteCategory(id).subscribe((res) => {
                    if (res) {
                        this.getCategory();
                        
                    }
                });
              Swal.fire(
                'Deleted!',
                'Category has been deleted.',
                'success',
                
              )
            }
          })
       
    }

    addCategory(contentadd: any) {
        this.modalService.open(contentadd);
     }

    categoryAddSubmit() {
        this.categoryEdit.category_name =
            this.categoryadd.value.categoryNameAdd;
        this.categoryEdit.source_id = this.categoryadd.value.sourceNameAdd;

        this._utilitySevice
            .updateCategory(this.categoryEdit)
            .subscribe((res) => {
                if (res) {
                    
                    this.getCategory();
                    this.categoryadd.reset();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '`<h2>Category Successfully Added</h2>`',
                        showConfirmButton: false,
                        timer: 1500
                      })
                }
            });
    }
}
