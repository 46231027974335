import { StatusActive } from "@/Interface/statusActive";

export class StatusActiveModel implements StatusActive{
    ticket_id: number;
    status: number
    

    constructor(values:Object = {}){
     Object.assign(this,values)
    }
    getTicketId(){
        return this.ticket_id;
    }
    getStatus(){
       return this.status; 
    }
} 