<!-- <div class="float-right d-none d-sm-block"></div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="https://thetowertech.com/"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
        Thetowertech.com</a
    >
    <span>.</span>
</strong>
<span> All rights reserved.</span> -->
<!-- <b>Version</b> {{ appVersion }} -->

<div class="app-wrapper-footer">
    <div class="app-footer">
        <div class="app-footer__inner">
            <div class="app-footer-left">
                <ul class="nav">
                    <li class="nav-item">
                        <a href="javascript:void(0);" class="nav-link">
                            Copyright &copy; {{ currentYear }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0);" class="nav-link">
                            <a
                            href="https://thetowertech.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style="margin: 0"
                        >
                            Thetowertech.com</a
                        >
                        <span>.</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="app-footer-right">
                <ul class="nav">
                    <li class="nav-item">
                        <a href="javascript:void(0);" class="nav-link">
                            All rights reserved.
                        </a>
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
</div>