<section class="dashboard-main container-fluid">
    <!-- <app-comming-soon></app-comming-soon> -->
    <div class="row mb-4 pt-4 block-topbar">
        <div class="col-12 d-sm-flex align-items-center justify-content-between">
            <!-- <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0">Source </h4>
                <button type="button" class="btn btn-orange btn-sm btn-label waves-effect waves-light" (click)="addSourceOpen(addConent)" ><i class="ri-add-line label-icon align-middle fs-16 me-2"></i>Source</button>
            </div> -->
            <h4 class="mb-sm-0">Source </h4>
            <button type="button" class="btn btn-orange btn-sm btn-label waves-effect waves-light" (click)="addSourceOpen(addConent)" ><i class="ri-add-line label-icon align-middle fs-16 me-2"></i>Add Source</button>
        </div>
    </div>


    <div class="contents-main">
        <!-- <table class="table table-hover text-center">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Source Name</th>
    
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of source; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.getName() }}</td>
                    <td>
                        <button type="button" (click)="open(item, content)" class="dropdown-item editcategory"
                            data-toggle="modal" data-target="#edit-category-vt"
                            data-category-detail='{"id":16,"ticket_category_id":2,"sub_category_name":"Melted PV Connector","duration":12,"is_hidden":0,"created_at":"2021-02-01 03:12:48","updated_at":"2021-02-01 03:13:35","category_id":2,"category_name":"Quality of Service"}'>
                            <i class="fa fa-edit mr-1"></i>
                        </button>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between">
                            <button type="button" (click)="deleteSource(item)" class="dropdown-item deletecategory">
                                <i class="fa fa-times-circle mr-1"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table> -->
        <div class="table-wrap">
            <div class="table-main">
                <table class="table-data">
                    <thead>
                        <tr>
                            <th scope="col">Sr. No</th>
                            <th scope="col">Source Name</th>
            
                            <th scope="col" style="width: 80px;text-align: center;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of source; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item.getName() }}</td>
                            
                            
                            <td style="width: 40px;text-align: center">
                                <div class="  d-flex justify-content-center">
                                    <button type="button"  (click)="open(item, content)" class="dropdown-item editcategory d-inline"
                                    data-toggle="modal" data-target="#edit-category-vt"
                                        >
                                
                                    <!-- <i class="bx bx-edit"></i> -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.433" height="18" viewBox="8 4 14.433 18">
                                    <path d="M8.9 20.2h12.6a.9.9 0 0 1 0 1.8H8.9a.9.9 0 1 1 0-1.8ZM8 15.7l9-9 2.7 2.7-9 9H8v-2.7Zm9.9-9.9L19.7 4l2.7 2.7-1.8 1.8-2.7-2.7Z" fill="currentColor"/>
                                </svg>
                                </button>
                                    <button (click)="deleteSource(item)" type="button"
                                        class="dropdown-item deletecategory d-inline">
                                            <!-- <i class=" ri-close-circle-line  "></i>  -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.017" height="18" viewBox="9 6 15.017 18">
                                            <path d="M10.5 22.004c0 1.098.9 1.996 2 1.996h8c1.1 0 2-.898 2-1.996V10.5h-12v11.504ZM24 7.5h-3.75L18.994 6h-4.988L12.75 7.5H9V9h15V7.5Z" fill="currentColor"/>
                                        </svg>
                                        
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<ng-template #addConent let-c="close" let-d="dismiss">
    <!-- (ngSubmit)="cardDetail(payment,content)" #payment="ngForm" -->
    <form [formGroup]="addSourceForm" (ngSubmit)="addSource()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Source</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
                    <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
                </svg>           
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-styles">
                    <label for="typeahead-basic">Name:</label>
                    <input id="typeahead-basic" type="text" class="form-control" formControlName="sourcName"
                        maxlength="50" />
                    <small class="text-danger" *ngIf="
                            !addSourceForm?.get('sourcName').valid &&
                            addSourceForm?.get('sourcName').touched
                        ">Please Enter Source Name</small>
                </div>
            </div>
        </div>
        <!-- (click)="c('Save click')" -->
        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click')"
            >
                Cancel
            </button>
            <button type="button" class="btn btn-orange" [disabled]="addSourceForm.invalid"
                (click)="c('Save click')" type="submit">
                Save
            </button>
        </div>
    </form>
</ng-template>
<ng-template #content let-c="close" let-d="dismiss">
    <!-- (ngSubmit)="cardDetail(payment,content)" #payment="ngForm" -->
    <form [formGroup]="updateSourceForm" (ngSubmit)="updateSource()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Source</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
                    <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
                </svg>        
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-styles">
                    <label for="typeahead-basic">Name:</label>
                    <input id="typeahead-basic" type="text" class="form-control" formControlName="sourcNameupdate"
                        maxlength="50" />
                    <small class="text-danger" *ngIf="
                            !updateSourceForm?.get('sourcNameupdate').valid &&
                            updateSourceForm?.get('sourcNameupdate').touched
                        ">Please Enter Source Name</small>
                </div>
            </div>
        </div>
        <!-- (click)="c('Save click')" -->
        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click')"
            >
                Cancel
            </button>
            <button type="button" class="btn btn-orange" [disabled]="updateSourceForm.invalid"
                (click)="c('Save click')" type="submit">
                Save
            </button>
        </div>
    </form>
</ng-template>