import { PlantList } from "@/Interface/plantList";
import { retry } from "rxjs-compat/operator/retry";

export class PlantListModel implements PlantList{

    station_id: number;
    plant_name: string;

    constructor(values:Object = {}){
     Object.assign(this,values)
    }
    getStationId(){
        return this.station_id;
    }
    getPlantName(){
        return this.plant_name;
    }
}