
export interface login {
    username: string,
    password: string
    token:string,
    role:string;
    
}
// export interface token{
//     access_token:string,
//     refresh_token:string
// }
export interface Plants {
    plantName: string,
    capacity: string,
    currentPower: string,
    todayGeneration: string,
    updatedAT:Date,
    status:string,
    address: string,
    city: string
  }

  export interface PlantsNew {
    plantName: string,    
    plantAddress: string,
    capacity: string,
    currentPower: string,
    specificYield : string,    
    status:string,
    type : string,
    energyToday : string,
    specificYieldYesterday : string,
    stationCode : string,
    majorAlarms: string,
    minorAlarms: string,
    activeWorkOrders : string,
    dataDelay : string,
    inverterState : any
  }

export interface cdetails{
    city:string;
    city_count:number;
}
export interface provinces{
    Islamabad:number,
    Punjab:number,
    Sindh:number
}
