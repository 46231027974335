import {Users} from '@/Interface/user';
import {Component, NgModuleRef, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UtilityService} from '@services/utility.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PlantList} from '@/Interface/plantList';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LinkPlantUser} from '@/Interface/linkPlantUser';
import Swal from 'sweetalert2';
import {PlantByUser} from '@/Interface/plantByUser';
import {RemoveLinkUser} from '@/Interface/removePlantUser';
import { UserAdd } from '@/Interface/addUser';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountType } from '@/Interface/userType';
import {UserProfile} from '@/Interface/userProfile';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            )
        ])
    ]
})
export class UserListComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private _utilityService: UtilityService,
        private _utiltiySevice: UtilityService,
        private formBuilders: FormBuilder,
        private _router:Router,
        private activatedRoute:ActivatedRoute,
        private toaster:ToastrService

    ) { 
        this.userList = this.formBuilders.group({
            full_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
            contact: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(11), Validators.maxLength(11)]],
            username: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]],
            user_type: ['', [Validators.required]]
        });
    }
    @ViewChild('search', {static: false}) search: any;
    userTypelist:AccountType;
    pageSize: number;
    pageToGo: number = 1;
    searchElement = '';
    serialNumberArray: Array<number> = [];
    next_page: number;
    current_page: number;
    previous_page: number;
    total_pages: number;
    total_items: number;
    private latestQueryParams: any = {};
    sortColumn: string = null; 
    sortOrder: 'asc' | 'desc' = null; 

    public pageSizeControl = new FormControl(10);
    public pageRecordOptions = [
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
        { value: '100', label: '100' },
        { value: '200', label: '200' },
      ];

    public rolesList = [
        {id:'1', name:'Superuser'},
        {id:'2', name:'NOC User'},
        {id:'3', name:'Customer'},
      ]

    // userList  = new UserAdd();
    modalRef: NgbModalRef | null=null;
    userList:FormGroup;
    emailAlreadyExists:boolean = false;

    userName: any;
    userEmail: any;
    userProfile : UserProfile;

    user: Users[];
    @ViewChild('myTable') table: any;
    selectedCars: number[] = [];
    dataSource: Users;
    rows: any[] = [];
    row: Users[] = [];

    USerID: number;
    LinkPlantU: LinkPlantUser = {
        station_id: [],
        user_id: 0
    };
    removeLinkPlantList: RemoveLinkUser = {
        station_id: 0,
        user_id: 0
    };
    // row: Users;
    expandedElement: Users;
    allPlantList: PlantList;
    linkPlant: FormGroup;
    list: any;
    searchSubject: Subject<string> = new Subject();


    PlantByUserlist: PlantByUser;
    message: any;
    loader : boolean = false
    ngOnInit(): void {
        // this.loader = true;
        this.linkPlant = this.formBuilders.group({
            plantName: new FormControl(null, Validators.required)
        });
        this.searchSubject.pipe(debounceTime(300)).subscribe((searchTerm: string) => {
            this.searchElement = searchTerm;
            this.updateQueryParams({ search: this.searchElement });
          });
        // this.getUser();
        this.getTableData();
        this.plants();
        this.getUserType();
        // this.loader = false;
    }
    showSuccess(message:string) {
        this.toaster.success(message);
      }
      showError(message:string) {
        this.toaster.error(message);
      }
      sortUsers(column: string): void {
        if (this.sortColumn === column) {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortColumn = column;
          this.sortOrder = 'asc';
        }
      
        let sortValue: string | null = null;
        let columnName: string;
      
        if (this.sortOrder === 'asc') {
          sortValue = '0';
        } else if (this.sortOrder === 'desc') {
          sortValue = '1';
        }
      
        // Mapping column names used in the template to the corresponding column names expected by the API
        switch (column) {
          case 'username':
            columnName = 'username';
            break;
          default:
            columnName = column;
            break;
        }
      
        this.updateQueryParams({ sort: sortValue, column: columnName });
      }
      

    // private updateQueryParams(paramsToUpdate: any): void {
    //     const queryParams = {
    //         ...this.activatedRoute.snapshot.queryParams,
    //         ...paramsToUpdate
    //     };
    //     console.log('Updated queryParams:', queryParams);

    //     if (queryParams.search === '') {
    //         queryParams.search = null;
    //     }
    //     if (queryParams.page === 1) {
    //         queryParams.page = null;
    //     }
        
    //     if (queryParams.size === 10) {
    //         console.log('inside size query',queryParams.size)
    //         queryParams.size = null;
    //     }
    //     this._router
    //         .navigate([], {
    //             relativeTo: this.activatedRoute,
    //             queryParams: queryParams,
    //             queryParamsHandling: 'merge'
    //         })
    //         .then(() => {
    //             this.getTableData();
    //         });
    // }
    // private getTableData(): void {
    //     const name =
    //         this.activatedRoute.snapshot.queryParams['search'] ||
    //         this.searchElement;
    //     console.log('name search', name);
    //     this.current_page = this.activatedRoute.snapshot.queryParams['page']
    //         ? +this.activatedRoute.snapshot.queryParams['page']
    //         : 1;
    //     console.log('current Page', this.current_page);
    //     this.pageSize = this.activatedRoute.snapshot.queryParams['size']
    //         ? +this.activatedRoute.snapshot.queryParams['size']
    //         : 10;
    //     this.getAllUsers(name, this.current_page, this.pageSize);
    // }
    private updateQueryParams(paramsToUpdate: any): void {
        this.latestQueryParams = {
            ...this.latestQueryParams,
            ...paramsToUpdate
        };
        console.log('Updated queryParams:', this.latestQueryParams);
    
        if (this.latestQueryParams.search === '') {
            this.latestQueryParams.search = null;
        }
        if (this.latestQueryParams.page === 1) {
            this.latestQueryParams.page = null;
        }
        
        if (this.latestQueryParams.size === 10) {
            this.latestQueryParams.size = null;
        }
        if (this.latestQueryParams.size === 10) {
            this.latestQueryParams.size = null;
        }
        if (this.latestQueryParams.sort === null) {
            delete this.latestQueryParams.sort;
        }
        
        if (this.latestQueryParams.column === null) {
            delete this.latestQueryParams.column;
        }
        if (this.latestQueryParams.user_type === null) {
            delete this.latestQueryParams.user_type;
        }
        if (this.latestQueryParams.page === 1 || (this.latestQueryParams.page && (this.latestQueryParams.search || this.latestQueryParams.user_type))) {
            if (this.latestQueryParams.page > 1) {
                this.latestQueryParams.page = paramsToUpdate.page;
                if (this.latestQueryParams.size > 10) {
                    this.latestQueryParams.size = paramsToUpdate.size;
                }
            } else {
                this.latestQueryParams.page = null;
            }
        } 
        
        if (!this.next_page && (paramsToUpdate.size || paramsToUpdate.search)) {
            // Only include size or search in the query params
            this.latestQueryParams = {
                size: paramsToUpdate.size || null,
                search: paramsToUpdate.search || null
            };
        }
        this.getTableData(); 
    }
    
    private getTableData(): void {
        const name = this.latestQueryParams['search'] || this.searchElement;
        console.log('name search', name);
        const page = this.latestQueryParams['page'] ? +this.latestQueryParams['page'] : 1;
        console.log('current Page', page);
        const size = this.latestQueryParams['size'] ? +this.latestQueryParams['size'] : 10;
        const sort = this.latestQueryParams['sort'] || null;
        const column = this.latestQueryParams['column'] || null;
        const user_type = this.latestQueryParams['user_type'] || null;
        this.getAllUsers(name, page, size,sort,column,user_type);
    }


    getAllUsers(name: string, page: number, size: number,sort:string | null ,column :string | null,user_type:number): void {
        this.loader = true;
        this.row = [];
        this.serialNumberArray = [];
        this._utiltiySevice
            .receiveAllUsers(name, page, size,sort,column,user_type)
            .subscribe((res: any) => {
                if (res && res['status'] === 200) {
                    this.row = res.data;
                    this.row = res.data.map((ticket: any, index: number) => ({
                        ...ticket,
                        srNo: (res.current_page - 1) * size + index + 1
                    }));
                    console.log(this.row);
                    this.current_page = res.current_page;
                    console.log('res.current_page',this.current_page)
                    this.next_page = res.next_page;
                    this.previous_page = res.previous_page;
                    this.total_pages = res.total_pages;
                    this.total_items = res.total_users;
                    this.enablePagination();
                }
                this.loader = false;
            });
    }
    public searchData(event: Event): void {
        this.searchElement = (event.target as HTMLInputElement)?.value || '';
        console.log('search Value', this.searchElement);
        // this.updateQueryParams({search: this.searchElement});
        this.searchSubject.next(this.searchElement);


        // if (this.searchElement.trim() === '') {
        //     this.updateQueryParams({search: null}); 
        // } else {
        //     this.updateQueryParams({search: this.searchElement});
        // }
    }

    goToPage(page: number): void {
        console.log('move to page', page);
        if (page >= 1 && page <= this.total_pages) {
            this.updateQueryParams({page:page});
        }
    }

    navigatePage(direction: string): void {
        if (direction === 'prev' && this.current_page > 1) {
            this.goToPage(this.current_page - 1);
        } else if (direction === 'next' && this.next_page !== null) {
            this.goToPage(this.current_page + 1);
        }
    }
    
    // Add a method to navigate to the entered page
    navigateToPage(): void {
        if (this.pageToGo >= 1 && this.pageToGo <= this.total_pages) {
            this.goToPage(this.pageToGo);
        }
    }

    public changePageSize(): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.pageSize = this.pageSizeControl.value!;
        this.updateQueryParams({size:this.pageSize})
        }

        public selectRoleslistStatus(selectedRoleId){
            this.updateQueryParams({ user_type: selectedRoleId });
        }
    onSort(event: any): void {
        const sortBy = event.sorts[0];

        // Assuming your data is an array of objects (this.alarmList), use Array.prototype.sort()
        this.row.sort((a, b) => {
            const valueA = a[sortBy.prop];
            const valueB = b[sortBy.prop];

            if (typeof valueA === 'number' && typeof valueB === 'number') {
                // Compare numbers directly
                return sortBy.dir === 'asc' ? valueA - valueB : valueB - valueA;
            } else if (
                typeof valueA === 'string' &&
                typeof valueB === 'string'
            ) {
                // Compare strings using localeCompare
                return sortBy.dir === 'asc'
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            } else {
                // Fallback to comparing as strings
                return String(valueA).localeCompare(String(valueB));
            }
        });
    }
    
    closeModal() {
        if (this.modalRef) {
          this.modalRef.close('Closed');
        }
      }
    
      // Method to reset the form
      resetForm() {
        this.userList.reset();
      }
      addUser(user: any) {
        this.modalRef = this.modalService.open(user);

    // Subscribe to the closed observable
    if (this.modalRef) {
      this.modalRef.hidden.subscribe((result) => {
          this.resetForm();
        });
    }
      }

      getUserType(){
        this._utiltiySevice.selectUserType().subscribe((res)=>{
          this.userTypelist = res.data;
        })
      }

    open(content: any, row: Users) {
        this.selectedCars = [];
        this.modalService.open(content);
        this.USerID = row.user_id;
    }

    showLink(link: any, row: Users) {
        //debugger;
        this.modalService.open(link, {windowClass: 'complainModelClass'});
        var userInlist = row.user_id;
        this.table.rowDetail.toggleExpandRow(row);
        this._utilityService.showLinkPlant(userInlist).subscribe((res) => {
            if (res) {
                this.PlantByUserlist = res.data;                
            }
        });
    }
    getUser() {
        this._utilityService.receiveUser().subscribe((res) => {
            if (res) {
                var response = res;
                console.log('response',response)
                //this.row = res;
                this.setExtraData(res);
            }
        });
    }

    FiltergetUser(filter: string, filterType: string) {
       
        this._utilityService.receiveUserFilter(filterType + "=" + filter).subscribe((res) => {
            if (res) {
                var response = res;
                //this.row = res;
                this.setExtraData(res);
            }
        });
    }

    setExtraData(response: any){
        var totalCount = response.total_users;
        console.log('setExtraData',totalCount)
        var totalPages = response.total_pages;
        var currentPage = response.current_page;
        var rowIndex = 0;
        this.row = [];
        for(var i=1;  i<=totalPages ; i++)
        {
            
            for(var k=0; k<10; k++,rowIndex++)
            {
                if(rowIndex < response.total_users)
                {
                    this.row.push(response.data[k]);
                }
                else{
                    break;
                }
            }
        }

    }

    usersPageChange(event: any) {
        // Handle page change here
       
        var newPage =  parseInt(event.offset) + 1;
        console.log('usersPageChange',newPage)
        this.FiltergetUser(event.offset + 1, 'page');
      }
  
    Search() {
        if (this.userName == '') {
            this.ngOnInit();
        } else {
            console.log('inside search function')
            this.row = this.row.filter((res) => {
                return res.username
                    ?.toLocaleLowerCase()
                    .match(this.userName?.toLocaleLowerCase());
            });
        }
    }

    searchByEmail() {
        if (this.userEmail == '') {
            this.ngOnInit();
        } else {
            this.row = this.row.filter((res) => {
                return res.email
                    ?.toLocaleLowerCase()
                    .match(this.userEmail?.toLocaleLowerCase());
            });
        }
    }

    toggleExpandRow(row) {}
    listInfo(row) {
        this.toggleExpandRow(row);
    }
    onDetailToggle(event) {}
    plants() {
        this._utilityService.PlantsdetailsAll().subscribe((res) => {
            if (res) {
                this.allPlantList = res.data;
            }
        });
    }
    onMaterialGroupChange() {
        this.list = this.selectedCars;
    }
    linkUserList() {
        this.LinkPlantU.station_id = this.selectedCars;
        this.LinkPlantU.user_id = this.USerID;
        this._utilityService
            .linkUserPlants(this.LinkPlantU)
            .subscribe((res) => {
                if (res) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '`<h2>Plant Linked  Successfully</h2>`',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });
    }

    removePlantLink(item: PlantByUser) {
        this.removeLinkPlantList.station_id = item.stationId;
        this.removeLinkPlantList.user_id = item.userId;
        this._utilityService.removeUserPlant(this.removeLinkPlantList).subscribe((res)=>{
            if(res){
                var userInlist = item.userId;
             
                this._utilityService.showLinkPlant(userInlist).subscribe((res) => {
                    if (res) {
                        this.PlantByUserlist = res.data;
                        if (res) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: '`<h2>Plant Unlinked  Successfully</h2>`',
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    }
                });
            }
        })
    }


    addUserAccout(){
        this._utiltiySevice.saveUser(this.userList.value).subscribe((res)=>{
         if(res){
           if(res.message === 'Username or Email already exists. Kindly, use another email/username.'){
             this.emailAlreadyExists = true;
             this.showError(res.message);
           
           }else{
             this.emailAlreadyExists = false;
            //  this.modalService.dismissAll();
            this.showSuccess(res.message);
             this._router.navigate(['/user']);  
             this.closeModal(); 
          this.resetForm();
        //   this.userList  = new UserAdd();
 
         
         }}
        })
       }


       disableUser(userId){
        this.disableUserPopup(userId);
        // this._utiltiySevice.disableUserApi(userId).subscribe((res)=>{
        //     if(res){
        //      console.log("Success");
        //      this.row.filter(item => item.user_id === userId)[0].is_active = false;
            
        //     }
        //    })
       }


       disableUserPopup(userId) {
        Swal.fire({
          title: 'Are you sure you want to disable this User?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, disable it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this._utiltiySevice.disableUserApi(userId).subscribe((res)=>{
                if(res){
                 console.log("Success");
                 this.row.filter(item => item.user_id === userId)[0].is_active = false;
                
                }
               })
            Swal.fire(
              'Disabled!',
              'user has been disabled.',
              'success'
            )
            this.getTableData();
          }
        })
     
      }

      enablePagination() {
        const linkElement = document.getElementById('prevPage');

        if (this.previous_page === null) {
            linkElement.setAttribute('disabled', 'true');
            linkElement.style.pointerEvents = 'none';
            linkElement.style.color = 'gray'; // You can set the color to indicate it's disabled
            linkElement.addEventListener('click', (event) => {
                event.preventDefault(); // Prevent the default behavior (navigation)
            });
        } else {
            linkElement.removeAttribute('disabled');
            linkElement.style.pointerEvents = 'auto'; // Enable clicking
            linkElement.style.color = ''; // Reset the color to its default
            linkElement.removeEventListener('click', (event) => {
                event.preventDefault();
            });
        }
    }
 
}
