<!-- <video autoplay muted loop id="myVideo">
  <source src="assets/img/blue.mp4" type="video/mp4">
</video>


  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card    bg-vicious-stance text-white" style="border-radius: 1rem;">
          <div class="card-body p-4 text-center ">
            <div class="mb-md-5 mt-md-4">
              <div class="login_area_vt">
                <div class="alert alert-danger" role="alert" *ngIf="submitted">
                  The username or password is incorrect!
                </div>
                <div class="text-center in-down w-25 mx-auto"><img class="w-100 " src="assets/img/zerocarbon-logo-new.png" alt=""></div>
                 <h3 class="fw-bold mb-2 ">Welcome, Let's get Started!</h3> 
                
                <form class=" gradient-custom" #applicationForm="ngForm"  (ngSubmit)="saveUser(applicationForm)" >
                 
                <div class="form-outline form-white mb-4"> 
                 
                  <input  type="text" class="form-control  " name="username"   
                    placeholder="Enter your username" [(ngModel)]="user.username" #username="ngModel" required/>
                  
                  <div>
                    <div class="has-danger text-left text-danger"
                        *ngIf="username.errors && (username.dirty || username.touched)">
                        Username is a required field
                         
                    </div>
                </div>

                </div>
                <div class="form-outline form-white mb-4"> 
                  <input name="password" type="password" autocomplete="on" class="form-control "    [(ngModel)]="user.password" #password="ngModel"
                    placeholder="Enter your password" required />
                    
                  <div>
                    <div class="has-danger text-left text-danger" *ngIf="password.errors && (password.dirty || password.touched  )">
                        Password is a required field
                        
                    </div>
                </div>

                </div>
                
                <button [disabled]="!applicationForm.valid" class="btn btn-outline-light  px-5"  type="submit" >Login</button>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   -->


<!-- <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    
    <div class="auth-page-content overflow-hidden pt-lg-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card overflow-hidden">
                        <div class="row g-0">
                            <div class="col-lg-6">
                                <div class="p-lg-5 p-4 auth-one-bg h-100">
                                    <div class="bg-overlay"></div>
                                    <div class="position-relative h-100 d-flex flex-column">
                                        <div class="mb-4">
                                            <a class="d-block">
                                                <img src="assets/img/zerocarbon-logo-new.png" alt="" height="38" />
                                            </a>
                                        </div>
                                        <div class="mt-auto">
                                            <div class="mb-3">
                                                <i class="ri-double-quotes-l display-4 text-success"></i>
                                            </div>

                                            <div id="qoutescarouselIndicators" class="carousel slide"
                                                data-bs-ride="carousel">
                                                <div class="carousel-indicators">
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="0" class="active" aria-current="true"
                                                        aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="3" aria-label="Slide 4"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="4" aria-label="Slide 5"></button>
                                                </div>
                                                <div class="carousel-inner text-center text-white pb-5">
                                                    <div class="carousel-item active">
                                                        <p class="fs-15 fst-italic">
                                                            " Join the renewable
                                                            energy revolution
                                                            with ZeroCarbon
                                                            solar solutions."
                                                        </p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">
                                                            " Illuminating your
                                                            world with clean,
                                                            renewable energy
                                                            from ZeroCarbon."
                                                        </p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">
                                                            " Empowering a
                                                            sustainable future
                                                            through renewable
                                                            energy solutions. "
                                                        </p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">
                                                            "Switch to a
                                                            brighter future with
                                                            ZeroCarbon, the
                                                            experts in solar
                                                            power."
                                                        </p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">
                                                            "Energize your life
                                                            with ZeroCarbon
                                                            solar technology,
                                                            leading the way to a
                                                            greener tomorrow."
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="p-lg-5 p-4">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="text-primary">
                                            Welcome Back !
                                        </h5>
                                        <h5 *ngIf="forgetPasswordFlag">
                                            <a style="text-decoration: underline; cursor: pointer;" (click)="loginBack()"></a>
                                        </h5>
                                    </div>
                                    <p class="text-muted">
                                        Sign in to continue to ZeroCarbon.
                                    </p>
                                    <div class="alert alert-danger" role="alert" *ngIf="submitted">
                                        The username or password is incorrect!
                                    </div>
                                    <div class="mt-4">
                                        <div *ngIf="loginFlag">
                                            <form class="gradient-custom" #applicationForm="ngForm" (ngSubmit)="
                                                    saveUser(applicationForm)
                                                ">
                                                <div class="mb-3">
                                                    <label for="username" class="form-label">Username</label>
                                                    <input type="text" class="form-control" placeholder="Enter username"
                                                        name="username" [(ngModel)]="
                                                            user.username
                                                        " #username="ngModel" required />
                                                    <div>
                                                        <div class="has-danger text-left text-danger" *ngIf="
                                                                username.errors &&
                                                                (username.dirty ||
                                                                    username.touched)
                                                            ">
                                                            Username is a
                                                            required field
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3">
                                                   
                                                    <label class="form-label">Password</label>
                                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                                        <input type="password" name="password" class="form-control pe-5"
                                                            [(ngModel)]="
                                                                user.password
                                                            " #password="ngModel" placeholder="Enter your password"
                                                            required />
                                                        <div class="has-danger text-left text-danger" *ngIf="
                                                                password.errors &&
                                                                (password.dirty ||
                                                                    password.touched)
                                                            ">
                                                            Password is a
                                                            required field
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="d-flex justify-content-between">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="auth-remember-check" />
                                                        <label class="form-check-label"
                                                            for="auth-remember-check">Remember
                                                            Password</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label" for="auth-remember-check"
                                                            (click)="
                                                                showForget()
                                                            ">Forget
                                                            Password</label>
                                                    </div>
                                                </div>
                                                <div class="mt-4">
                                                    <button class="btn btn-success w-100" [disabled]="
                                                            !applicationForm.valid
                                                        " type="submit">
                                                        Sign In
                                                    </button>
                                                </div>

                                                <div class="mt-4 text-center">
                                                    <div class="signin-other-title">
                                                        <h5 class="fs-13 mb-4 title">
                                                            Sign In with
                                                        </h5>
                                                    </div>

                                                    <div>
                                                        <button type="button"
                                                            class="btn btn-primary btn-icon waves-effect waves-light">
                                                            <i class="ri-facebook-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-danger btn-icon waves-effect waves-light">
                                                            <i class="ri-google-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-dark btn-icon waves-effect waves-light">
                                                            <i class="ri-github-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-info btn-icon waves-effect waves-light">
                                                            <i class="ri-twitter-fill fs-16"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div *ngIf="forgetPasswordFlag">
                                            <form class="gradient-custom" #forget="ngForm"
                                                (ngSubmit)="submitForget(forget)">
                                                <div class="mb-3">
                                                    <label for="username" class="form-label">Email</label>
                                                    <input type="text" class="form-control" placeholder="Enter username"
                                                        name="email" [(ngModel)]="
                                                        forgotPassword.email
                                                        " #email="ngModel" required />
                                                    <div>
                                                        <div class="has-danger text-left text-danger" *ngIf="
                                                        email.errors &&
                                                                (email.dirty ||
                                                                email.touched)
                                                            ">
                                                            Email is a
                                                            required field
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="d-flex justify-content-between">
                                                    <div class="form-check">
                                                        
                                                        <label class="form-check-label" for="auth-remember-check">
                                                        </label>
                                                    </div>
                                                    <div class="form-check"></div>
                                                </div>
                                                <div class="mt-4">
                                                    <button class="btn btn-success w-100" [disabled]="
                                                            !forget.valid
                                                        " type="submit">
                                                        Sign In
                                                    </button>
                                                </div>

                                                <div class="mt-4 text-center">
                                                    <div class="signin-other-title">
                                                        <h5 class="fs-13 mb-4 title">
                                                            Sign In with
                                                        </h5>
                                                    </div>

                                                    <div>
                                                        <button type="button"
                                                            class="btn btn-primary btn-icon waves-effect waves-light">
                                                            <i class="ri-facebook-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-danger btn-icon waves-effect waves-light">
                                                            <i class="ri-google-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-dark btn-icon waves-effect waves-light">
                                                            <i class="ri-github-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-info btn-icon waves-effect waves-light">
                                                            <i class="ri-twitter-fill fs-16"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="mt-5 text-center">
                                        <p class="mb-0">
                                            Don't have an account ?
                                            <a href="auth-signup-cover.html"
                                                class="fw-semibold text-primary text-decoration-underline">
                                                Signup</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
               
            </div>
            
        </div>
        
    </div> -->

    <!-- <video autoplay muted loop id="myVideo">
  <source src="assets/img/blue.mp4" type="video/mp4">
</video>


  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card    bg-vicious-stance text-white" style="border-radius: 1rem;">
          <div class="card-body p-4 text-center ">
            <div class="mb-md-5 mt-md-4">
              <div class="login_area_vt">
                <div class="alert alert-danger" role="alert" *ngIf="submitted">
                  The username or password is incorrect!
                </div>
                <div class="text-center in-down w-25 mx-auto"><img class="w-100 " src="assets/img/zerocarbon-logo-new.png" alt=""></div>
                 <h3 class="fw-bold mb-2 ">Welcome, Let's get Started!</h3> 
                
                <form class=" gradient-custom" #applicationForm="ngForm"  (ngSubmit)="saveUser(applicationForm)" >
                 
                <div class="form-outline form-white mb-4"> 
                 
                  <input  type="text" class="form-control  " name="username"   
                    placeholder="Enter your username" [(ngModel)]="user.username" #username="ngModel" required/>
                  
                  <div>
                    <div class="has-danger text-left text-danger"
                        *ngIf="username.errors && (username.dirty || username.touched)">
                        Username is a required field
                         
                    </div>
                </div>

                </div>
                <div class="form-outline form-white mb-4"> 
                  <input name="password" type="password" autocomplete="on" class="form-control "    [(ngModel)]="user.password" #password="ngModel"
                    placeholder="Enter your password" required />
                    
                  <div>
                    <div class="has-danger text-left text-danger" *ngIf="password.errors && (password.dirty || password.touched  )">
                        Password is a required field
                        
                    </div>
                </div>

                </div>
                
                <button [disabled]="!applicationForm.valid" class="btn btn-outline-light  px-5"  type="submit" >Login</button>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   -->
  <!--<div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    
    <div class="auth-page-content overflow-hidden pt-lg-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card overflow-hidden">
                        <div class="row g-0">
                            <div class="col-lg-6">
                                <div class="p-lg-5 p-4 auth-one-bg h-100">
                                    <div class="bg-overlay"></div>
                                    <div class="position-relative h-100 d-flex flex-column">
                                        <div class="mb-4">
                                            <a class="d-block">
                                                <img src="assets/img/zerocarbon-logo-new.png" alt="" height="38" />
                                            </a>
                                        </div>
                                        <div class="mt-auto">
                                            <div class="mb-3">
                                                <i class="ri-double-quotes-l display-4 text-success"></i>
                                            </div>

                                            <div id="qoutescarouselIndicators" class="carousel slide"
                                                data-bs-ride="carousel">
                                                <div class="carousel-indicators">
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="0" class="active" aria-current="true"
                                                        aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="3" aria-label="Slide 4"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators"
                                                        data-bs-slide-to="4" aria-label="Slide 5"></button>
                                                </div>
                                                <div class="carousel-inner text-center text-white pb-5">
                                                    <div class="carousel-item active">
                                                        <p class="fs-15 fst-italic">
                                                            " Join the renewable
                                                            energy revolution
                                                            with ZeroCarbon
                                                            solar solutions."
                                                        </p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">
                                                            " Illuminating your
                                                            world with clean,
                                                            renewable energy
                                                            from ZeroCarbon."
                                                        </p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">
                                                            " Empowering a
                                                            sustainable future
                                                            through renewable
                                                            energy solutions. "
                                                        </p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">
                                                            "Switch to a
                                                            brighter future with
                                                            ZeroCarbon, the
                                                            experts in solar
                                                            power."
                                                        </p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">
                                                            "Energize your life
                                                            with ZeroCarbon
                                                            solar technology,
                                                            leading the way to a
                                                            greener tomorrow."
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="p-lg-5 p-4">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="text-primary">
                                            Welcome Back !
                                        </h5>
                                        <h5 *ngIf="forgetPasswordFlag">
                                            <a style="text-decoration: underline; cursor: pointer;" (click)="loginBack()">Back To Login</a>
                                        </h5>
                                    </div>
                                    <p class="text-muted">
                                        Sign in to continue to ZeroCarbon.
                                    </p>
                                    <div class="alert alert-danger" role="alert" *ngIf="submitted">
                                        The username or password is incorrect!
                                    </div>
                                    <div class="mt-4">
                                        <div *ngIf="loginFlag">
                                            <form class="gradient-custom" #applicationForm="ngForm" (ngSubmit)="
                                                    saveUser(applicationForm)
                                                ">
                                                <div class="mb-3">
                                                    <label for="username" class="form-label">Username</label>
                                                    <input type="text" class="form-control" placeholder="Enter username"
                                                        name="username" [(ngModel)]="
                                                            user.username
                                                        " #username="ngModel" required />
                                                    <div>
                                                        <div class="has-danger text-left text-danger" *ngIf="
                                                                username.errors &&
                                                                (username.dirty ||
                                                                    username.touched)
                                                            ">
                                                            Username is a
                                                            required field
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3">
                                                   
                                                    <label class="form-label">Password</label>
                                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                                        <input type="password" name="password" class="form-control pe-5"
                                                            [(ngModel)]="
                                                                user.password
                                                            " #password="ngModel" placeholder="Enter your password"
                                                            required />
                                                        <div class="has-danger text-left text-danger" *ngIf="
                                                                password.errors &&
                                                                (password.dirty ||
                                                                    password.touched)
                                                            ">
                                                            Password is a
                                                            required field
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="d-flex justify-content-between">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="auth-remember-check" />
                                                        <label class="form-check-label"
                                                            for="auth-remember-check">Remember
                                                            Password</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label" for="auth-remember-check"
                                                            (click)="
                                                                showForget()
                                                            ">Forget
                                                            Password</label>
                                                    </div>
                                                </div>
                                                <div class="mt-4">
                                                    <button class="btn btn-success w-100" [disabled]="
                                                            !applicationForm.valid
                                                        " type="submit">
                                                        Sign In
                                                    </button>
                                                </div>

                                                <div class="mt-4 text-center">
                                                    <div class="signin-other-title">
                                                        <h5 class="fs-13 mb-4 title">
                                                            Sign In with
                                                        </h5>
                                                    </div>

                                                    <div>
                                                        <button type="button"
                                                            class="btn btn-primary btn-icon waves-effect waves-light">
                                                            <i class="ri-facebook-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-danger btn-icon waves-effect waves-light">
                                                            <i class="ri-google-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-dark btn-icon waves-effect waves-light">
                                                            <i class="ri-github-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-info btn-icon waves-effect waves-light">
                                                            <i class="ri-twitter-fill fs-16"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div *ngIf="forgetPasswordFlag">
                                            <form class="gradient-custom" #forget="ngForm"
                                                (ngSubmit)="submitForget(forget)">
                                                <div class="mb-3">
                                                    <label for="username" class="form-label">Email</label>
                                                    <input type="text" class="form-control" placeholder="Enter username"
                                                        name="email" [(ngModel)]="
                                                        forgotPassword.email
                                                        " #email="ngModel" required />
                                                    <div>
                                                        <div class="has-danger text-left text-danger" *ngIf="
                                                        email.errors &&
                                                                (email.dirty ||
                                                                email.touched)
                                                            ">
                                                            Email is a
                                                            required field
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="d-flex justify-content-between">
                                                    <div class="form-check">
                                                        
                                                        <label class="form-check-label" for="auth-remember-check">
                                                        </label>
                                                    </div>
                                                    <div class="form-check"></div>
                                                </div>
                                                <div class="mt-4">
                                                    <button class="btn btn-success w-100" [disabled]="
                                                            !forget.valid
                                                        " type="submit">
                                                        Sign In
                                                    </button>
                                                </div>

                                                <div class="mt-4 text-center">
                                                    <div class="signin-other-title">
                                                        <h5 class="fs-13 mb-4 title">
                                                            Sign In with
                                                        </h5>
                                                    </div>

                                                    <div>
                                                        <button type="button"
                                                            class="btn btn-primary btn-icon waves-effect waves-light">
                                                            <i class="ri-facebook-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-danger btn-icon waves-effect waves-light">
                                                            <i class="ri-google-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-dark btn-icon waves-effect waves-light">
                                                            <i class="ri-github-fill fs-16"></i>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-info btn-icon waves-effect waves-light">
                                                            <i class="ri-twitter-fill fs-16"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="mt-5 text-center">
                                        <p class="mb-0">
                                            Don't have an account ?
                                            <a href="auth-signup-cover.html"
                                                class="fw-semibold text-primary text-decoration-underline">
                                                Signup</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
               
            </div>
            
        </div>
        
    </div>
-->
    <!-- footer -->
    <!--
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="mb-0">
                            &copy; 2025 Tower Technologies (Pvt) Ltd. All rights
                            reserved 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div> -->


<div class="authScreen">
    <div class="box-login">
        <div class="box-holder">
            <div class="logo">
                <img src="assets/img/logo-zc.png" alt="Zero Carbon"> 
            </div>
            <h1>Sign In</h1>

            <h3 class="form-signin" *ngIf="forgetPasswordFlag">
                <label class="form-check-label" style="cursor: pointer;" (click)="loginBack()">Back To Login</label>
            </h3>
            <!-- <p>This is a secure site. Please enter your login information to enter or you can register yourself. -->
            <!-- </p> -->
            <div class="form-signin"  *ngIf="this.loginFlag">
                <form class="gradient-custom" #applicationForm="ngForm" (ngSubmit)="saveUser(applicationForm)">
                    <!-- <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" placeholder="admin@demo.com">
                    </div> -->
                    <div class="form-group">
                        <label for="username" class="form-label">User Name</label>
                        <input type="text" class="form-control" placeholder="Enter username"
                            name="username" [(ngModel)]="
                                user.username
                            " #username="ngModel" required (focus)="hideErrorMessage()"/>
                        <div>
                            <div class="has-danger text-left text-danger" *ngIf="
                                    username.errors &&
                                    (username.dirty ||
                                        username.touched)
                                ">
                                Username is a
                                required field
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" placeholder="********">
                    </div> -->
                    <div class="form-group">
                                                   
                        <label class="form-label">Password</label>
                        <div class="position-relative auth-pass-inputgroup mb-3">
                            <input type="password" name="password" class="form-control pe-5"
                                [(ngModel)]="
                                    user.password
                                " #password="ngModel" placeholder="Enter your password"
                                required (focus)="hideErrorMessage()"/>
                            <div class="has-danger text-left text-danger" *ngIf="
                                    password.errors &&
                                    (password.dirty || password.touched)
                                ">
                                Password is a  required field
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="has-danger text-left text-danger" *ngIf="loginError">
                                Incorrect Username / Password
                        </div>
                    </div>
                    
                    <div class="form-meta">
                        <!-- <label class="checkbox">
                            <input type="checkbox">
                            <span>Remember me</span>
                        </label> -->
                        <!-- <a routerLink="" (click)="showForget()">Forgot Password?</a> -->
                        <label class="form-check-label" for="auth-remember-check" (click)="showForget()">Forget Password?</label>
                    </div>
                    <div class="btn-block">
                        <button type="submit" class="btnPrimary">Login</button>
                        <!-- <span class="text-12 d-block text-center mb-40">New on our platform? <a href="#">Create an account</a></span> -->
                        <!-- <span class="text-or"><span>or</span></span>
                        <ul class="social-networks">
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" class="google"><i class="fab fa-google"></i></a></li>
                            <li><a href="#" class="twitter"><i class="fab fa-twitter"></i></a></li>
                        </ul> -->
                    </div>
                </form>
            </div>
            <div class="form-signin" *ngIf="this.forgetPasswordFlag">
                <form class="gradient-custom" #forget="ngForm"
                    (ngSubmit)="submitForget(forget)">
                    <div class="mb-3">
                        <label for="username" class="form-label">Email</label>
                        <input type="text" class="form-control" placeholder="Enter username"
                            name="email" [(ngModel)]="
                            forgotPassword.email
                            " #email="ngModel" required />
                        <div>
                            <div class="has-danger text-left text-danger" *ngIf="
                            email.errors &&
                                    (email.dirty ||
                                    email.touched)
                                ">
                                Email is a
                                required field
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <div class="form-check">
                            
                            <label class="form-check-label" for="auth-remember-check">
                            </label>
                        </div>
                        <div class="form-check"></div>
                    </div>
                    <div class="mt-4">
                        <button class="btn btn-success w-100 btnPrimary" [disabled]="
                                !forget.valid
                            " type="submit">
                            Sign In
                        </button>
                    </div>
                    <!-- <div class="mt-4 text-center">
                        <div class="signin-other-title">
                            <h5 class="fs-13 mb-4 title">
                                Sign In with
                            </h5>
                        </div>

                        <div>
                            <button type="button"
                                class="btn btn-primary btn-icon waves-effect waves-light">
                                <i class="ri-facebook-fill fs-16"></i>
                            </button>
                            <button type="button"
                                class="btn btn-danger btn-icon waves-effect waves-light">
                                <i class="ri-google-fill fs-16"></i>
                            </button>
                            <button type="button"
                                class="btn btn-dark btn-icon waves-effect waves-light">
                                <i class="ri-github-fill fs-16"></i>
                            </button>
                            <button type="button"
                                class="btn btn-info btn-icon waves-effect waves-light">
                                <i class="ri-twitter-fill fs-16"></i>
                            </button>
                        </div>
                    </div> -->
                </form>
            </div>
        </div>
    </div>
    <div class="box-content">
        <div class="content-holder">
            <h2>" Join the renewable energy revolution<span> with ZeroCarbon solar solutions."</span></h2>
        </div>
    </div>
</div>

    <!-- footer -->
    <!--
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="mb-0">
                            &copy; 2025 Tower Technologies (Pvt) Ltd. All rights
                            reserved 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div> -->

<!--
<div class="authScreen">
    <div class="box-login">
        <div class="box-holder">
            <div class="logo">
                <img src="assets/img/logo-zc.png" alt="Zero Carbon">
            </div>
            <h1>Sign In</h1>
            <p>This is a secure site. Please enter your login information to enter or you can register yourself.
            </p>
            <div class="form-signin">
                <form>
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" placeholder="admin@demo.com">
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" placeholder="********">
                    </div>
                    <div class="form-meta">
                        <label class="checkbox">
                            <input type="checkbox">
                            <span>Remember me</span>
                        </label>
                        <a href="#" class="link">Forgot Password?</a>
                    </div>
                    <div class="btn-block">
                        <button class="btnPrimary"  [disabled]="
                                                            !forget.valid
                                                        " type="submit">Login</button>
                        <span class="text-12 d-block text-center mb-40">New on our platform? <a href="#">Create an account</a></span>
                        <span class="text-or"><span>or</span></span>
                        <ul class="social-networks">
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" class="google"><i class="fab fa-google"></i></a></li>
                            <li><a href="#" class="twitter"><i class="fab fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="box-content">
        <div class="content-holder">
            <h2>" Join the renewable energy revolution<span> with ZeroCarbon solar solutions."</span></h2>
        </div>
    </div>
</div>
-->