import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { single } from '../../data';
import { AppService } from '@services/app.service';
import { Pdetails } from '@/Interface/plants';
import {AlarmStatusInterface, PlantStatus} from '@/Interface/plants'
import { city } from '../../data';
import { cdetails, Plants } from '@/Interface/register';
import { provinces } from '@/Interface/register';
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
import CanvasJS from '@canvasjs/charts';
import { Chart } from 'chart.js';

import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  single: cdetails[] = [];
  city: provinces[] = [];
  cdetails: any[] = [680, 350];
  pro: any[] = [680, 350];
  blockinfo: Array<Object> = [];
  blockinfop: Array<Object> = [];


  plantStatusChart: Array<Object> = [];
  AlaramStatusChart: Array<Object> = [];

  plantStatus: any[] = [];
  AlarmStatus: any[] = [];
  alarmSts : AlarmStatusInterface = new AlarmStatusInterface();
  PlantSts : PlantStatus = new PlantStatus();
  pdetail: Pdetails[];
  cities: cdetails;
  cityLength: number = 0;
  proLength: number = 0;
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  seriesAlarm : any[];
  seriesPlant : any[];

  colorScheme = {
    domain: ['#5AA454', '#33cc33', '#C7B42C', '#AAAAAA']
  };
  colorScheme1 = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#1dd068']
  };

  constructor(private appservice: AppService) {
    Object.assign(this, { city });
  }
  Highcharts: typeof Highcharts = Highcharts;

  blockOption: Highcharts.Options = {
    title: {
      text: '',

      style: {
        color: 'black',
        align: 'left'
      }
    },
    tooltip: {
      // pointFormat:  'Open: <b>{point.'+this.open+'}</b><br/>Closed: <b>{point.:.0f}</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true
        },
        showInLegend: true
      }
    },
    series: [
      {
        data: this.blockinfo,
        type: 'pie',
        dataLabels: {
          enabled: true
        }
      }
    ]
  };
  blockOption2: Highcharts.Options = {
    title: {
      text: '',

      style: {
        color: 'black',
        align: 'left'
      }
    },
    tooltip: {
      // pointFormat:  'Open: <b>{point.'+this.open+'}</b><br/>Closed: <b>{point.:.0f}</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true
        },
        showInLegend: true
      }
    },
    series: [
      {
        data:this.blockinfop,
        type: 'pie',
        dataLabels: {
          enabled: true
        }
      }
    ]
  };

  // Plant Status  chart rounded start

  chartOptionsPlantStatus = 
   {
	  // animationEnabled: true,
    exportEnabled: false,
	  // title:{
		// text: "Project Cost Breakdown"
	  // },
	  data: [{
		type: "doughnut",
		yValueFormatString: "#,###.##",
		indexLabel: "{name}",
		dataPoints: this.plantStatusChart
	  }]
	}	

  // Plant Status chart rounded End
  
  
  // Alarm chart rounded start

  chartOptionsAlarm = 
  {
	  // animationEnabled: true,	 
    exportEnabled: false,
	  data: [{
		type: "doughnut",
		yValueFormatString: "#,###.##",
		indexLabel: "{name}",
		dataPoints: this.AlaramStatusChart
	  }]
	}	

  labelFormatting(dataPoint) {
    return `${dataPoint.data.name}: ${dataPoint.data.value}%`;
  }
  
  // Status chart rounded End
  
  ngOnInit() {
   // this.AlarmStatus = new AlarmStatusInterface();
    this.getdashboardDetails();
    this.fetchgraph();
    this.getPlantStatus();
    this.getAlarmStatus();
    this.initalizePlantLocationMarker();
    // this.getgraph();
  }
  getdashboardDetails() {
    this.appservice.dashboard().subscribe((res) => {
      const data = res.data[0];
  
      // Convert today's generation to kWh with commas and up to two decimal places
      data.today_generation = this.formatNumber(data.today_generation) + " MWh";
  
      // Convert total generation to kWh with commas and up to two decimal places
      data.total_generation = this.formatNumber(data.total_generation / 1000) + " MWh";
  
      this.pdetail = [data]; // Reassigning the modified data back to the original variable
      console.log('this.pdetails',this.pdetail)
    });
  }
  
  formatNumber(value: number): string {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
  // getdashboardDetails(){
  //   this.appservice.dashboard().subscribe((res)=>{
  //     this.pdetail = res.data
  //   })
  // }
  
  getPlantStatus() {
    this.appservice.GetPlantStatus().subscribe((res) => {
      this.plantStatus = res.data;
      
      
    });
  }

  getAlarmStatus() {
    this.appservice.GetAlarmStatus().subscribe((res) => {

      this.AlarmStatus = res.data[0].alarm_status;
      this.plantStatus = res.data[0].plant_status;
      for (var item of this.AlarmStatus) {        
        if(item.alarm_type == 'Critical')
        {
          this.alarmSts.Critical = item.count;
          this.AlaramStatusChart.push({
            y: item.count,
            name: item.alarm_type,
            color: "#FA2A2D"
          });
          
        }
        else if(item.alarm_type == 'Major')
        {
          this.alarmSts.Major = item.count;
          this.AlaramStatusChart.push({
            y: item.count,
            name: item.alarm_type,
            color: "#FFDDB4"
          });
        }
        else if(item.alarm_type == 'Minor')
        {
          this.alarmSts.Minor = item.count;
          this.AlaramStatusChart.push({
            y: item.count,
            name: item.alarm_type,
            color: "#FFF1C8"
          });
        }
        else if(item.alarm_type == 'Warning')
        {
          this.alarmSts.Warning = item.count;
          this.AlaramStatusChart.push({
            y: item.count,
            name: item.alarm_type,
            color: "#AADBFF"
          });
        }
      }; 

      this.alarmSts.TotalAlarms = this.alarmSts.Critical + this.alarmSts.Major + this.alarmSts.Minor + this.alarmSts.Warning;

      
      this.seriesAlarm = [
        {
          "name": "Critical",
          "value":  (this.alarmSts.Critical/this.alarmSts.TotalAlarms *100).toFixed(1),
          "label": Math.round((this.alarmSts.Critical/this.alarmSts.TotalAlarms *100)).toString() + "%"
        },
        {
          "name": "Major",
          "value": (this.alarmSts.Major/this.alarmSts.TotalAlarms *100).toFixed(1),
          "label": Math.round((this.alarmSts.Major/this.alarmSts.TotalAlarms *100)).toString() + "%"
        },
        {
          "name": "Minor",
          "value":(this.alarmSts.Minor/this.alarmSts.TotalAlarms *100).toFixed(1),
          "label": Math.round((this.alarmSts.Minor/this.alarmSts.TotalAlarms *100)).toString() + "%"
        },
        {
          "name": "Warning",
          "value": (this.alarmSts.Warning/this.alarmSts.TotalAlarms *100).toFixed(1),
          "label": Math.round((this.alarmSts.Warning/this.alarmSts.TotalAlarms *100)).toString() + "%"
        }
      ];

      for (var item of this.plantStatus) {
       
        // this.AlarmStatus.forEach(function (item) {
          if(item.name == 'Offline')
          {
            this.PlantSts.offline = item.count;
            this.plantStatusChart.push({
              y: item.count,
              name: item.name,
              color: "#6D6D6D"
            });
          }
          else if(item.name == 'Faulty')
          {
            this.PlantSts.faulty = item.count;
            this.plantStatusChart.push({
              y: item.count,
              name: item.name,
              color: "#FA2A2D"
            });          
          }
          else if(item.name == 'Online')
          {
            this.PlantSts.normal = item.count;
            this.plantStatusChart.push({
              y: item.count,
              name: item.name,
              color: "green"
            });          
          }
         
        }; 

      this.PlantSts.total_plants = this.PlantSts.offline + this.PlantSts.faulty + this.PlantSts.normal;
        
      this.seriesPlant = [
        {
          "name": "Offline",
          "value":  (this.PlantSts.offline/this.PlantSts.total_plants *100).toFixed(1),
          "label": Math.round((this.PlantSts.offline/this.PlantSts.total_plants *100)).toString() + "%"
        },
        {
          "name": "Faulty",
          "value": (this.PlantSts.faulty/this.PlantSts.total_plants *100).toFixed(1),
          "label": Math.round((this.PlantSts.faulty/this.PlantSts.total_plants *100)).toString() + "%"
        },
        {
          "name": "Online",
          "value":(this.PlantSts.normal/this.PlantSts.total_plants *100).toFixed(1),
          "label": Math.round((this.PlantSts.normal/this.PlantSts.total_plants *100)).toString() + "%"
        }
      ];
    });
  }

  
  getgraph() {
    this.appservice.graphDetails().subscribe((res) => {
      
      this.city = res.data.provinces_count;
      
      this.blockOption2 = {
        title: {
          text: '',

          style: {
            color: 'black',
            align: 'left'
          }
        },
        tooltip: {
         },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true
            },
            showInLegend: true
          }
        },
        series: [
          {
            data: [this.city],
            type: 'pie',
            dataLabels: {
              enabled: true
            }
          }
        ]
      };
    });
  }
  fetchgraph() {
    this.blockinfo = [];
    this.blockinfop = [];
    this.appservice.graphDetails().subscribe((res) => {
      if (res) {
        //  res.data.cities_count.map(datum => ({ name: datum.city, value: datum.city_count }));
        var blocklist = [];
        var blocklistp = [];
        blocklist = res.data.cities_count;
        blocklistp = res.data.provinces_count;
        this.cityLength = blocklist.length;
        this.proLength = blocklistp.length;
        blocklist.forEach((e) => {
          this.blockinfo.push({
            y: e.city_count,
            name: e.city
          });
        });
        blocklistp.forEach((e) => {
          this.blockinfop.push({
            y: e.count,
            name: e.province
          });
        });
      }

      this.blockOption = {
        title: {
          text: '',

          style: {
            color: 'black',
            align: 'left'
          }
        },
        tooltip: {
          // pointFormat:  'Open: <b>{point.'+this.open+'}</b><br/>Closed: <b>{point.:.0f}</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true
            },
            showInLegend: true
          }
        },
        series: [
          {
            data: this.blockinfo,
            type: 'pie',
            dataLabels: {
              enabled: true
            }
          }
        ]
      };
      this.blockOption2 = {
        title: {
          text: '',

          style: {
            color: 'black',
            align: 'left'
          }
        },
        tooltip: {
          // pointFormat:  'Open: <b>{point.'+this.open+'}</b><br/>Closed: <b>{point.:.0f}</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true
            },
            showInLegend: true
          }
        },
        series: [
          {
            data: this.blockinfop,
            type: 'pie',
            dataLabels: {
              enabled: true
            }
          }
        ]
      };
    });
  }
  onSelect(data): void { }

  onActivate(data): void { }

  onDeactivate(data): void { }

  //////////////////////////////// Google Maps related code ///////////////////////////////////////

  initalizePlantLocationMarker() {
    //debugger;
    this.appservice.GetPlantLocation().subscribe((res) => {
      //debugger;
      this.markerPositions = res.data;      
    });
  }
  infoWindowText: string = '';

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  center: google.maps.LatLngLiteral = {
      lat: 30.33084,
      lng: 71.247499,
  };
  
  zoom = 8;

  
  markerPositions: any[];

  // = [
  //   {lat: 35.76579536532549, lng: 69.39516275554563, station: "Lahore", stationCode: "NE=33723600"}, 
  //   {lat: 28.557147688289916, lng: 69.57094400554563, station: "Karachi", stationCode: "NE=33985839"}
  //   ,{lat: 34.833309862745814, lng: 74.66860025554563, station: "Bahawalpur", stationCode: "NE=33699614"}]

 
  addMarker(event: google.maps.MapMouseEvent) {
    
      //debugger;
      if (event.latLng != null)
         this.markerPositions.push(event.latLng.toJSON());
    }
    
    openInfoWindow(marker: MapMarker, station : string, stationCode : string) {
      
      this.infoWindowText =  station + " | " + stationCode;
        if (this.infoWindow != undefined) 
          this.infoWindow.open(marker);
    }

  /////////////////////////////////////////////////////////////////////////////////////////////////
}
