<div class="loaderwrapper" *ngIf="this.loader">
  <span class="loader" ></span> 
</div> 

<section class="dashboard-main container-fluid">
  <div class="row mb-0 pt-4 block-topbar">
    <div class="col-12 d-sm-flex align-items-center justify-content-between mb-4">
      <h4 class="mb-sm-0">All User</h4>
    </div>
    <div class="col-12 d-sm-flex align-items-center justify-content-between pb-4 pt-3">
        <!-- <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h3 class="mb-sm-0">All User</h3>

            <div class="page-title-right">
                <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Layouts</a></li>
                    <li class="breadcrumb-item active">Vertical</li>
                </ol>
            </div>
        </div> -->
        <div class="dataTables_length p-0">
          <label class="show-entries-list">
            Show
            <!-- (change)="changePageSize()" -->
            <!-- [formControl]="pageSizeControl" -->
            <select [formControl]="pageSizeControl" (change)="changePageSize()"
            class="form-control form-control-sm">
            <option *ngFor="let limit of pageRecordOptions" [value]="limit.value">{{limit.label}}</option>
          </select>
            entries
          </label>
      </div>
      <!-- Filter by Role -->
      <div class="col-md-3">
        <div class="form-group role">
            <label>Select Role</label>
            <ng-select (change)="selectRoleslistStatus($event)">
                <ng-option *ngFor="let Item of rolesList" [value]="Item.id">{{Item.name}}</ng-option>
            </ng-select>  
        </div>
      </div>
        <div class="d-flex justify-content-end">
          <div class="row userSearch d-flex justify-content-center pe-3 m-0">
              <div class="userSearcForm form-styles p-0">
                  <input class="form-control searchTerm" type="search" name="userName" (input)="searchData($event)" placeholder="Search"  />
                  <span class="search-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.973" height="12.973" viewBox="0 0 12.973 12.973">
                      <g id="search-outline" transform="translate(0.5 0.5)">
                        <path id="Path_2581" data-name="Path 2581" d="M9.313,4.5a4.813,4.813,0,1,0,4.813,4.813A4.813,4.813,0,0,0,9.313,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
                        <path id="Path_2582" data-name="Path 2582" d="M23.786,23.786l3.362,3.362" transform="translate(-15.382 -15.381)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                      </g>
                    </svg>
                  </span>
              </div>
              <!-- <div class="col-md-3">
                  <input class="form-control searchTerm" type="search" name="userEmail" [(ngModel)]="userEmail"
                  (ngModelChange)="searchByEmail()" placeholder="Search By Email " />
              </div> -->
          </div>
          <button type="button" class="btn btn-orange btn-sm btn-label waves-effect waves-light" (click)="addUser(user)">
            <i class="ri-add-line label-icon align-middle fs-16 me-2"></i> Add User</button>
        </div>
    </div>
  </div>

  <section class="contents-main">
    <div class="container-fluid card p-0">
      <div class="card pt-0 m-0">
        <ngx-datatable #myTable [style.width]="'100%'" class="material expandable table-responsive" [rows]="row" [headerHeight]="50" 
        [footerHeight]="50"
          [columnMode]="'force'" [scrollbarH]="true" [rowHeight]="'auto'" [externalSorting]="true"
          [messages]="{emptyMessage: 'No User found.'}" (sort)="onSort($event)">
          <ngx-datatable-row-detail [rowHeight]="0" #myDetailRow (toggle)="onDetailToggle(row)">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            </ng-template>
          </ngx-datatable-row-detail>

          <ngx-datatable-column [sortable]="false" [minWidth]="'10%'" name="Sr. No" prop="user_id">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                <div class="form-group" style="margin-bottom: 0px"></div>
                <span>{{ row.srNo }}</span>
            </ng-template>
        </ngx-datatable-column>    

          <!-- <ngx-datatable-column   [sortable]="true" [minWidth]="'17%'" prop="complaintNo"
            name="Name">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="row.priorityID">
              <div class="form-group" style="margin-bottom: 0px"></div>
              <span>
                {{ row.username }}
              </span>
            </ng-template>
          </ngx-datatable-column> -->
          <ngx-datatable-column [sortable]="false" [minWidth]="'17%'" prop="complaintNo" name="Name">
            <ng-template ngx-datatable-header-template let-column="column">
              <a class="cursor-pointer" (click)="sortUsers('username')">
                Name
                <span class="sort-btn"
                      [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'username',
                                  'datatable-icon-up': sortColumn === 'username' && sortOrder === 'asc',
                                  'datatable-icon-down': sortColumn === 'username' && sortOrder === 'desc'}">
                </span>
              </a>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="form-group" style="margin-bottom: 0px"></div>
              <span>{{ row.username }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column  [sortable]="false" [minWidth]="'17%'"   prop="department"
            name="Email">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="row.priorityID">
              <div class="form-group" style="margin-bottom: 0px"></div>
              <span>
                {{ row.email }}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column   [sortable]="false" [minWidth]="'17%'"  prop="role" name="Role">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ row.role }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column   [sortable]="false" [minWidth]="'17%'"   prop="assignedTo"
            name="Join Date">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ row.date_joined | date: 'short' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column  [sortable]="false" [minWidth]="'17%'"   prop="requestType"
            name="Status">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span class="badge badge-success badge-sm" style="font-size: 8px; padding: 5px 13px"
                *ngIf="row.is_active === true">Active</span>
              <!-- <span class="badge badge-secondary" *ngIf="row.is_active === false"
                style="font-size: 8px; padding: 5px 13px">InActive</span> -->
            </ng-template>
          </ngx-datatable-column>
          

          <ngx-datatable-column name="Action" [sortable]="false" [resizeable]="true"   [minWidth]="'5%'"
            >
            <ng-template let-row="row" ngx-datatable-cell-template>
            
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="View"
                  >
                    <a
                    (click)="showLink(link, row)" 
                      data-bs-toggle="modal" title="View plants linked with this user"
                      class="text-primary d-inline-block"
                    >
                      <!-- <i class="ri-eye-fill fs-16"></i> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="21.841" height="14.56" viewBox="0 0 21.841 14.56">
                        <g id="eye" transform="translate(-1.498 -9)">
                          <circle id="Ellipse_1436" data-name="Ellipse 1436" cx="3.105" cy="3.105" r="3.105" transform="translate(9.315 13.175)" fill="currentColor"/>
                          <path id="Path_5515" data-name="Path 5515" d="M23.1,15.488a15.422,15.422,0,0,0-4.517-4.574A11.43,11.43,0,0,0,12.4,9a10.9,10.9,0,0,0-5.654,1.643,16.574,16.574,0,0,0-4.991,4.826,1.452,1.452,0,0,0-.029,1.617A15.2,15.2,0,0,0,6.2,21.666a11.271,11.271,0,0,0,6.2,1.894,11.478,11.478,0,0,0,6.2-1.9,15.2,15.2,0,0,0,4.508-4.592,1.465,1.465,0,0,0,0-1.582Zm-10.685,5.16a4.368,4.368,0,1,1,4.368-4.368,4.368,4.368,0,0,1-4.368,4.368Z" fill="currentColor"/>
                        </g>
                      </svg>
                    </a>
                  </li>
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Edit"
                  >
                    <a
                    title="Add plants" (click)="open(content, row)"
                      data-bs-toggle="modal"
                      class="text-primary d-inline-block edit-item-btn"
                    >
                      <!-- <i class="ri-pencil-fill fs-16"></i> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="14.433" height="18" viewBox="8 4 14.433 18">
                          <path d="M8.9 20.2h12.6a.9.9 0 0 1 0 1.8H8.9a.9.9 0 1 1 0-1.8ZM8 15.7l9-9 2.7 2.7-9 9H8v-2.7Zm9.9-9.9L19.7 4l2.7 2.7-1.8 1.8-2.7-2.7Z" fill="currentColor"/>
                      </svg>
                    </a>
                  </li>
                  
                  <li
                    class="list-inline-item delete"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Delete"
                    (click)="disableUser(row.user_id);"
                  >
                    <a
                    title="Delete User"
                      data-bs-toggle="modal"
                      class="text-primary d-inline-block edit-item-btn"
                    >
                      <!-- <i class=" ri-close-circle-line  "></i>  -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.017" height="18" viewBox="9 6 15.017 18">
                          <path d="M10.5 22.004c0 1.098.9 1.996 2 1.996h8c1.1 0 2-.898 2-1.996V10.5h-12v11.504ZM24 7.5h-3.75L18.994 6h-4.988L12.75 7.5H9V9h15V7.5Z" fill="currentColor"/>
                      </svg>
                    </a>
                  </li>
                </ul>
              </td>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <div class="table-meta">
          <div class="entries">
              <!-- <span>Show</span>
              <select>
                  <option>12</option>
              </select> -->
              <span>Total Records: {{this.total_items }}</span>               
          </div>
          <!-- <nav class="table-pagination">
              <ul>
                  <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                  <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                  <li><a class="active">{{ current_page }}</a></li>
                  <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                  <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
              </ul>
          </nav> -->
          <nav class="table-pagination">
            <ul>
                <li>
                    <div class="go-to-page d-flex align-items-center">
                        <label>Go to Page:</label>
                        <input type="number" class="form-control form-control-sm" [(ngModel)]="pageToGo" min="1" [max]="total_pages" (keydown.enter)="goToPage(pageToGo); pageToGo = null">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" (click)="goToPage(pageToGo); pageToGo = null" [disabled]="pageToGo < 1 || pageToGo > total_pages">Go</button>
                        </div>
                    </div>
                </li>                                                                                                
                <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                <li><a class="active">{{ current_page }}</a></li>
                <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
            </ul>
        </nav>
          
      </div>
        
        <ng-template #content let-c="close" let-d="dismiss">
          <form>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Add Plants</h4>
              <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                  <line x1="5" y1="5" x2="25" y2="25" stroke="#fff" stroke-width="2"/>
                  <line x1="25" y1="5" x2="5" y2="25" stroke="#fff" stroke-width="2"/>
                </svg>
              </button>
            </div>
            <form [formGroup]="linkPlant" (ngSubmit)="linkUserList()">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12 my-3">
                    <label for="typeahead-basic"> Plants:</label>

                    <ng-select [items]="allPlantList" bindLabel="plant_name" bindValue="station_id" [multiple]="true"
                      formControlName="plantName" [(ngModel)]="selectedCars" (change)="onMaterialGroupChange()"
                      name="plantName">
                    </ng-select>
                    <small class="text-danger" *ngIf="
                                            !linkPlant.get('plantName').valid &&
                                            linkPlant.get('plantName').touched
                                        ">Please Enter Plant Name</small>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="c('Save click')" type="submit">
                  Save
                </button>
              </div>
            </form>
          </form>
        </ng-template>

        <ng-template #link let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Link Plants</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <line x1="5" y1="5" x2="25" y2="25" stroke="#fff" stroke-width="2"/>
                <line x1="25" y1="5" x2="5" y2="25" stroke="#fff" stroke-width="2"/>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="row p-4">
              <div class="col-md-6 p-2" *ngFor="let item of PlantByUserlist">
              
                

              <div  >
                <button type="button w-100" style="width: 100%; font-size: 12px" class="btn btn-dark" >
                  {{ item.stationName }}
                  <span class="badge badge-danger position-absolute top--10" style="top: -8px; right: -7px; font-size: 12px"
                    (click)="removePlantLink(item)">
                    <i class="fa fa-times-circle"></i></span>
                </button>
              </div>
    





              </div>
              
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </section>
</section>

<ng-template #user let-c="close" let-d="dismiss">
  
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add User</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
          x
      </button>
  </div>
  <div class="modal-body">
    <!-- #e="ngForm" (ngSubmit)="changePasswordsubmit()" -->
      <!-- <form  #e="ngForm" (ngSubmit)="addUserAccout()" >
          <div class="modal-body auditbody">
            <div class="form-group">
              <div class="row">
                <div class="col-md-12">
                  <div class="row mb-2">
                    
                    <div class="col-md-12  "> 
                      <label>Full Name</label>
                      <input type="text" class="form-control"  name="fullName" [(ngModel)]="userList.full_name" > 
                      
                       
                    </div>
                  </div>
                  <div class="row mb-2">
                  
                    <div class="col-md-12  "> 
                      <label>Contact No</label>
                      <input type="text" class="form-control "  name="contact" [(ngModel)]="userList.contact" > 
                      
                    </div>
                  </div>
                  <div class="row mb-2">
                   
                    <div class="col-md-12 "> 
                      <label>User Name</label> 
                      <input type="text" class="form-control "    name="userName" [(ngModel)]="userList.username" >
                       
                    
                    </div>
                  </div>
                  <div class="row mb-2">
                     <div class="col-md-12  "> 
                      <label>Email</label>
                      <input type="email" class="form-control "   name="email" [(ngModel)]="userList.email" >
                       
                    
                    </div>
                  </div>
                  <div class="row mb-2">
                     <div class="col-md-12  "> 
                      <label>User Type</label>
                      <ng-select  [(ngModel)]="userList.user_type"  name="userType">
                        <ng-option *ngFor="let typeUser of userTypelist" [value]="typeUser.id">{{
                          typeUser.account_type }}</ng-option>
                      </ng-select>                       
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="right">
              
              <button class="btn btn-raised btn-danger mr-1 " (click)="d('Cross click')" type="button" 
               >
                <i class="ft-x"></i> Cancel
              </button>
            
              <button class="btn btn-dark " type="submit"   >
                <i class="fa fa-check-square-o"></i> Save
              </button>
           
            </div>
          </div>
        </form> -->




        <form #e="ngForm" [formGroup]="userList" (ngSubmit)="addUserAccout()" novalidate>
          <div class="modal-body auditbody">
              <div class="form-group">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="row mb-2">
                              <div class="col-md-12">
                                  <label>Full Name</label>
                                  <input type="text" class="form-control" formControlName="full_name" required minlength="2" maxlength="50">
                                  <div *ngIf="userList.get('full_name').invalid && (userList.get('full_name').dirty || userList.get('full_name').touched)" class="text-danger">
                                      <div *ngIf="userList.get('full_name').hasError('required')">Name is required.</div>
                                      <div *ngIf="userList.get('full_name').hasError('minlength')">Name must be at least 2 characters long.</div>
                                      <div *ngIf="userList.get('full_name').hasError('maxlength')">Name cannot be more than 50 characters long.</div>
                                      <div *ngIf="userList.get('full_name').hasError('pattern')">Name can only contain letters.</div>
                                  </div>
                              </div>
                          </div>
                          <div class="row mb-2">
                              <div class="col-md-12">
                                  <label>Contact No</label>
                                  <input type="text" class="form-control" formControlName="contact" required pattern="[0-9]*" minlength="11" maxlength="11">
                                  <div *ngIf="userList.get('contact').invalid && (userList.get('contact').dirty || userList.get('contact').touched)" class="text-danger">
                                    <div *ngIf="userList.get('contact').hasError('required')">Contact number is required.</div>
                                    <div *ngIf="userList.get('contact').hasError('pattern')">Contact number can only contain numbers.</div>
                                    <div *ngIf="userList.get('contact').hasError('minlength')">Contact number must be at least 11 digits long.</div>
                                </div>
                              </div>
                          </div>
                          <div class="row mb-2">
                              <div class="col-md-12">
                                  <label>User Name</label>
                                  <input type="text" class="form-control" formControlName="username" required minlength="2" maxlength="50">
                                  <div *ngIf="userList.get('username').invalid && (userList.get('username').dirty || userList.get('username').touched)" class="text-danger">
                                      <div *ngIf="userList.get('username').hasError('required')">Please enter a valid username.</div>
                                      <div *ngIf="emailAlreadyExists">This User Name is already registered. Please enter a different User Name.</div>
                                  </div>
                              </div>
                          </div>
                          <div class="row mb-2">
                              <div class="col-md-12">
                                  <label>Email</label>
                                  <input type="email" class="form-control" formControlName="email" required email>
                                  <div *ngIf="userList.get('email').invalid && (userList.get('email').dirty || userList.get('email').touched)" class="text-danger">
                                    <div *ngIf="userList.get('email').hasError('required')">Email address is required.</div>
                                    <!-- <div *ngIf="userList.get('email').hasError('email')">Please enter a valid email address.</div> -->
                                    <div *ngIf="userList.get('email').hasError('pattern')">Please enter a valid email address.</div>
                                </div>
                              </div>
                          </div>
                          <div class="row mb-2">
                              <div class="col-md-12">
                                  <label>User Type</label>
                                  <ng-select formControlName="user_type" required>
                                      <ng-option *ngFor="let typeUser of userTypelist" [value]="typeUser.id">{{ typeUser.account_type }}</ng-option>
                                  </ng-select>
                                  <div *ngIf="userList.get('user_type').invalid && (userList.get('user_type').dirty || userList.get('user_type').touched)" class="text-danger">
                                      Please select a user type.
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <div class="right">
                  <button class="btn btn-raised btn-danger mr-1" (click)="closeModal()" type="button">
                      <i class="ft-x"></i> Cancel
                  </button>
                  <button class="btn btn-dark" type="submit" [disabled]="!e.valid">
                      <i class="fa fa-check-square-o"></i> Save
                  </button>
              </div>
          </div>
      </form>
      
        
</div>

</ng-template> 