import { AppState } from '@/store/state';
import { ToggleControlSidebar, ToggleSidebarMenu } from '@/store/ui/actions';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { login } from '@/Interface/register';
const BASE_CLASSES = '';
// main-header navbar navbar-expand bgParpale
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public searchForm: UntypedFormGroup;
    getauth: string = '';
    getDecodedTokan: string ='';
    authTokan:  login;

    constructor(
        public appService: AppService,
        private store: Store<AppState>,
        private _apiService: ApiService
    ) { }

    ngOnInit() {
      
        this.showUserName();
    }

    profilePic()
    {
        return this.appService.profileImage$;
    }
    logout() {
        this.appService.logout();
    }

    onToggleMenuSidebar() {
        this.store.dispatch(new ToggleSidebarMenu());
    }

    onToggleControlSidebar() {
        this.store.dispatch(new ToggleControlSidebar());
    }
    showUserName() {
        this.getDecodedTokan = localStorage.getItem('tokan');

        this.authTokan =   jwt_decode(this.getDecodedTokan);
        console.log("🚀 ~ file: header.component.ts:53 ~ HeaderComponent ~ showUserName ~ this.authTokan", this.authTokan)
 
    
    }
}
