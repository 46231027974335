<section class="content-header">
        
        <div class="container-fluid">
                <div class="row mb-2">
                        <div class="col-sm-6">
                                <h1>Plants List</h1>
                        </div>
                        <div class="col-sm-6">
                              
                        </div>
                </div>
        </div>

</section>

<section>


        <div>
                <div>
                        
                </div>
        </div>
</section>
 