import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  HostBinding
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormBuilder,
  FormGroup
} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {ApiService} from '@services/api.service';
import {Router, ActivatedRoute} from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';

import {login} from '@/Interface/register';
import {LoginClass} from '@/user';
import {first} from 'rxjs/internal/operators/first';
import { Login } from '../../Interface/login';
import { UtilityService } from '@services/utility.service';
import { ForgotPassword, IChangePassword } from '@/Interface/forgotPassword';
import Swal from "sweetalert2";

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  
  forgotPassword =new ForgotPassword();
  loginForm: FormGroup;
  loading = false;
  submitted: boolean = false;

  returnUrl: string;
  user = new Login();
   loginFlag:boolean = true;
   forgetPasswordFlag:boolean = false;

  error = '';
  users: login[] = [];
  toastr: any;
  userInfo:Login
  jwtTokan: string;
  userNew = new LoginClass();
  ChangePassword = new IChangePassword();
  getDecodedTokan: any;
  keyid:string;
  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AppService,
      private _utilityService:UtilityService,
      private _serviceApi:ApiService,
   ) {}
  ngOnInit() {
    this.keyid = this.route.snapshot.queryParamMap.get('key');
       this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
      });

     
  }
  get f() {
      return this.loginForm.controls;
  }

  saveUser(applicationForm:NgForm) {
     
      // if (this.loginForm.invalid) {
      //     return;
      // }
      var username = this.user.username;
      var password = this.user.password;
      this._utilityService.setUserInfo(username,password).subscribe((res)=>{
          if(res.success === true){
              this.submitted = false;
         
          this.jwtTokan = res.data.access_token;
         localStorage.setItem('tokan', this.jwtTokan);
        this.getDecodedTokan = this._serviceApi.decodeAuth();
         this.router.navigate(['/dashboard']);
          }else{
              this.submitted = true;
          }
      })

   
  }



  showForget(){
       
      this.loginFlag = false;
      this.forgetPasswordFlag = true;
  }

  loginBack(){
       
      this.loginFlag = true;
      this.forgetPasswordFlag = false;
  }

  submitForget(forget:NgForm){
     this.forgotPassword.email = this.forgotPassword.email;
     this._utilityService.sendForgot(this.forgotPassword).subscribe((res)=>{
      if(res){
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '`<h2>Change password Link sent to your email</h2>`',
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigate(['/login']);

      }
     })
  }

  changePass(cpassword:NgForm){
    this.ChangePassword.key = this.keyid ;
    debugger;

    if(this.ChangePassword.key == null)
    {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: '`<h2>Change Password Key is Empty</h2>`',
          showConfirmButton: true,
          // timer: 1500
        });

        //return; 
    }
    else if(this.ChangePassword.password == null){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: '`<h2>Some Fields are not porvided</h2>`',
        showConfirmButton: true,
        // timer: 1500
      });

      return;
    }
    else if(this.ChangePassword.confirm_password == null)
    {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: '`<h2>Some Fields are not porvided</h2>`',
        showConfirmButton: true,
        // timer: 1500
      });

      return;
    }
    else if(this.ChangePassword.password != this.ChangePassword.confirm_password)
    {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: '`<h2>Password and Confirm Password Not Match</h2>`',
          showConfirmButton: true,
          // timer: 1500
        });

        return;
    }
    this._utilityService.setChangePassword(this.ChangePassword).subscribe((res)=>{
      if(res){

        if(res.message == "Please provide all required fields.")
        {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: '`<h2>Password Not changed</h2>`',
              showConfirmButton: false,
              timer: 1500
            });
        }
        if(res.message == "This link has been expired or invalid")
        {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: '`<h2>This link has been Expired or Invalid</h2>`',
              showConfirmButton: false,
              timer: 1500
            });            
          this.router.navigate(['/login']);
        }        
        else{
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '`<h2>Password Change Successfully</h2>`',
            showConfirmButton: false,
            timer: 1500
          });
          this.router.navigate(['/login']);
      }
        
      }
    })
  }

}
