  <div class="emiChart w-100">
    <div class="row d-flex  ">
        <div class="col-md-7  py-3 pl-5">
            <div class="row d-flex  align-items-center ">
                <div class="col-md-6 pr-0">
                    <ng-select [multiple]="true" [(ngModel)]="selectedCars" >
                        <ng-option *ngFor="let inGv of inverterGV" [value]="inGv.id" [disabled]="inGv.disabled" >{{
                            inGv.name }}</ng-option>
                       
                    </ng-select>
                </div>
                <div class="col-md-3  pr-0">
                    <input type="date" class="CurrentDate" [(ngModel)]="gDate"  (change)="openDate(gDate)" />
                </div>
                <div class="col-md-1 pl-0">
                    <button type="button" class="btn btn-secondary btn-sm serchBtn" (click)="toggleDisabled()">
                        Search
                    </button>
                </div>
            </div>
        </div>
       
    </div>
    <div class="row">
        <div class="col-md-12">
            <div   >
                <highcharts-chart [Highcharts]="Highcharts" [options]="blockOption" style="
                width: 100%;
                height: 400px;
                display: block;
                margin: 10px 0px;
                background-color: #fcffc5;
            ">
        </highcharts-chart>
        <div *ngIf="loading" class="loading">
            <img class="w-100" src="../../../assets/images/loading.gif" />
        </div>
            </div>
        
        </div>
    </div>
</div>