<html>

<head>
  <title>Zero Carbon Privacy Policy</title>
</head>

<body>
  <header>
    <div class="logoOuter">
      <div class="container">
        <a><img src="../../../assets/images/zerocarbon-logo-new.png" /></a>
      </div>
    </div>
  </header>
  <div class="container">
    <div class="privacyPolicy">
      <h1>Zero Carbon Privacy Policy</h1>
      <p>
        Our company, Zero Carbon, values the privacy of our users.
        This Privacy Policy explains the types of information we
        collect and how we use it. By using our application, you
        consent to our collection and use of your information as
        described in this Privacy Policy.
      </p>
      <h2>Scope and Application</h2>
      <p>
        This Privacy Policy applies to all information collected
        through the Zero Carbon Apps and by the Zero Carbon company.
        It does not apply to information collected through any other
        means, including websites or services that may be linked to
        or from the Zero Carbon Apps.
      </p>
      <h2>Information Collection and Use</h2>
      <p>
        We collect information from you when you use our
        application. The types of information we collect include:
      </p>
      <ul>
        <li>
          Device information: We may collect information about
          your device, such as the device type, operating system,
          and unique device identifier for troubleshooting
          purposes
        </li>
        <li>
          Log information: We collect log information about your
          use of our application, such as the type of browser you
          use, access times, pages viewed, and the page you
          visited before navigating to our application.
        </li>
        <li>
          Location information: We may collect information about
          your location if you enable location services on your
          device.
        </li>
        <li>
          Information provided by you: We may collect information
          that you provide to us directly, such as when you create
          an account or send us feedback.
        </li>
        <li>
          Contact information: We collect your contact information
          which will include your name and your contact
          information and your address when receiving complaints
          from the application. This information is used to
          contact and reach you so our service staff can address
          the problems with the equipment.
        </li>
      </ul>
      <p>We use the information we collect to:</p>
      <ul>
        <li>
          Improve our application and provide customized content
          and features
        </li>
        <li>Respond to customer support requests</li>
        <li>
          Communicate with you about our application and updates
        </li>
        <li>
          Analyze usage trends and improve the overall user
          experience
        </li>
        <li>Provide location-based services, if enabled</li>
      </ul>
      <p>
        We do not share your personal information with third parties
        for their marketing purposes without your explicit consent.
      </p>
      <h2>Security</h2>
      <p>
        We take reasonable precautions to protect your information
        from loss, misuse, unauthorized access, disclosure,
        alteration, or destruction. However, no Internet or mobile
        transmission is ever fully secure or error-free. Therefore,
        we cannot guarantee that unauthorized access, hacking, data
        loss, or other breaches will never occur.
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time to
        reflect changes to our information collection and use
        practices. We will notify you of any changes by posting the
        new Privacy Policy on this page. You are advised to review
        this Privacy Policy periodically for any changes.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy or our practices, please contact us at
        <a style="color: #f1581f" href="mailto:support@zerocarbon.com.pk">support@zerocarbon.com.pk</a>
      </p>
    </div>
  </div>
</body>

</html>