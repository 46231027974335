

<div id="layout-wrapper">
    <button type="button" class="hamburger sidebar-menu-trigger" onclick="toggleBodyClass()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
    <app-header class="site-header"></app-header>
    <app-menu-sidebar class="sidebar-navigation"></app-menu-sidebar>
    <div class="vertical-overlay"></div>

   
     <div class="main-content">
       
         <div class="page-content">
             <div class="container-fluid p-0">
               
          <div class=" ">
                 <router-outlet></router-outlet>
             </div>
             
             </div>
         </div>
         <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <script>document.write(new Date().getFullYear())</script> © ZeroCarbon.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-end d-none d-sm-block">
                            © 2024 Tower Technologies (Pvt) Ltd. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
         <script src="http://maps.google.com/maps/api/js?sensor=true"></script>
     </div>
 </div>
