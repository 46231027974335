import { CategoryModel } from '@/model/catlist';
import { complainListModel } from '@/model/complainListModel';
import { NocUserModel } from '@/model/nocUser';
import { PlantListModel } from '@/model/plantList';
import { PriorityModel } from '@/model/priority';
import { SourceModel } from '@/model/source';
import { SubCategoryModel } from '@/model/subcategory';
import { UserModel } from '@/model/user';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from '@services/utility.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.scss']
})
export class AddTicketComponent implements OnInit {
  addTocketForm: FormGroup;
  selectedCar: number;
  plantitem = new PlantListModel();
  priority = new PriorityModel();
  source = new SourceModel();
  categoryList = new CategoryModel();
  getSubcat = new SubCategoryModel();
  addComplan = new complainListModel();
  catFlagDisabled: boolean = true;
  user = new NocUserModel();
  constructor(
    private _utilityService: UtilityService,
    private FormBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getPlants();
    this.getPriority();
    this.getSource();
    this.getCategory();
    this.getSubCategory();
    this.getUser();

    this.addTocketForm = this.FormBuilder.group({
      name: new FormControl(null, Validators.required),
      plantName: new FormControl(null, Validators.required),
      priorityName: new FormControl(null, Validators.required),
      sourceName: new FormControl(null, Validators.required),
       catName: new FormControl(null, Validators.required),
      subcatName: new FormControl(null, Validators.required),
      assignTo: new FormControl(null, Validators.required),
      contact: new FormControl(null, Validators.required),
      titleName: new FormControl(null, Validators.required),
      discription: new FormControl(null, Validators.required)
    });

    this.addTocketForm.get('catName').disable();
    this.addTocketForm.get('subcatName').disable();
  }

  getPlants() {
    this._utilityService.getPlantsList().subscribe((res) => {
      if (res) {
        this.plantitem = res;
        console.log('plant Item',this.plantitem)
      }
    });
  }

  getPriority() {
    this._utilityService.receivePreority().subscribe((res) => {
      if (res) {
        this.priority = res;
      }
    });
  }
  getSource() {
    this._utilityService.receiveSource().subscribe((res) => {
      if (res) {
        this.source = res;
      }
    });
  }

  getCategory() {
    this._utilityService.receiveCategory().subscribe((res) => {
      if (res) {
        this.categoryList = res;
      }
    });
  }

  getSubCategory() {
    this._utilityService.sendSubCat().subscribe((res) => {
      if (res) {
        this.getSubcat = res;
      }
    });
  }

  getUser() {
    this._utilityService.receiveNocUser().subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  submitAddTicketForm() {
    this.addComplan.name = this.addTocketForm.value.name;
    this.addComplan.plant_id = this.addTocketForm.value.plantName;
    this.addComplan.contact = this.addTocketForm.value.contact;
    this.addComplan.description = this.addTocketForm.value.discription;
    this.addComplan.source = this.addTocketForm.value.sourceName;
    this.addComplan.category = this.addTocketForm.value.catName;
    this.addComplan.sub_category = this.addTocketForm.value.subcatName;
    this.addComplan.priority = this.addTocketForm.value.priorityName;
    this.addComplan.assigned_to = this.addTocketForm.value.assignTo;
    this.addComplan.title = this.addTocketForm.value.titleName;
    this._utilityService
      .addComplainList(this.addComplan)
      .subscribe((res) => {
        if (res) {
          this.addTocketForm.reset();
          Swal.fire({
              position: 'center',
              icon: 'success',
              title: '`<h2>Ticket Successfully Added</h2>`',
              showConfirmButton: false,
              timer: 1500
            })
        }else{
          return
        }
      });
  }

  selctCategoryBySourceID(sourceId) {
    this._utilityService
      .getCategoryBySourceId(sourceId)
      .subscribe((res) => {
        if (res.length > 0) {
          this.categoryList = res;
          this.addTocketForm.get('catName').enable();
        } else {
          return;
        }
      });
  }

  selctSubCategoryByCategoryID(catId) {
    this._utilityService.getSubCategoryByCatId(catId).subscribe((res) => {
      if (res.length > 0) {
        this.getSubcat = res;
        this.addTocketForm.get('subcatName').enable();
      } else {
        return;
      }
    });
  }
}
