
import { CategoryById } from '@/Interface/categorybyid';

export class CategoryByIdModel implements CategoryById{
    id:number;
    category_name:string;
    source_id:number;
    source_name:string;

    constructor(values:object={}){
        Object.assign(this,values)
    }
    getidCategory(){
        return this.id;
    }
    getCatName(){
        return this.category_name;
    }
    getSouceId(){
        return this.source_id;
    }
    getSourceCategory(){
        return this.source_name;
    }
}