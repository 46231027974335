<div class="loaderwrapper" *ngIf="this.loader">
    <span class="loader"></span>
</div>
<section class="dashboard-main container-fluid">
    <div class="breadcrumb-main">
        <ul class="breadcrumb">
            <li><a [routerLink]="">Plants</a></li>
            <li>
                <a [routerLink]="['/plants']" (click)="showPlantList()"
                    >Plants List</a
                >
            </li>
            <li>Plant Details</li>
            <!-- <li class="separator skip-arrow"></li> -->
            <li class="skip-arrow" style="color: #fb8c04">
                {{ selectedPlantName }}
            </li>
        </ul>
    </div>
    <!-- <div class="search-holder">
            <div class="filter-select">
                <select>
                    <option>Option A</option>
                    <option>Option B</option>
                </select>
            </div>
            <div class="filter-select">
                <select>
                    <option>Option A</option>
                    <option>Option B</option>
                </select>
            </div>
            <form class="search-form d-none d-md-flex">
                <input type="search" placeholder="Search here...">
                <button type="submit" class="btn-search"><i><svg xmlns="http://www.w3.org/2000/svg" width="16.204" height="16.204" viewBox="0 0 16.204 16.204">
                    <g id="search-outline" transform="translate(0.5 0.5)">
                      <path id="Path_2581" data-name="Path 2581" d="M10.635,4.5a6.135,6.135,0,1,0,6.135,6.135A6.135,6.135,0,0,0,10.635,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
                      <path id="Path_2582" data-name="Path 2582" d="M23.786,23.786l4.285,4.285" transform="translate(-13.074 -13.074)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                    </g>
                  </svg>
                  </i></button>
            </form>
        </div>
    </div> -->
    <div class="contents-main">
        <div class="row">
            <div class="col-12">
                <div class="card-box card-plant">
                    <div class="card-header card-header-tabs">
                        <ul>
                            <li>
                                <a
                                    (click)="enertyFlow('energyFlow')"
                                    [ngClass]="{activeTab: ppf}"
                                    >Plant Power Flow</a
                                >
                            </li>
                            <li>
                                <a
                                    (click)="enertyFlow('inverter')"
                                    [ngClass]="{activeTab: invert}"
                                    >INVERTER + PV</a
                                >
                            </li>
                            <li>
                                <a
                                    (click)="enertyFlow('pv')"
                                    [ngClass]="{activeTab: pv}"
                                    >IRRADIANCE</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="tab-data">
                        <div id="tab-1" *ngIf="ppf">
                            <div class="card-content">
                                <div class="card-graphical">
                                    <div class="flow-graph col-4">
                                        <span class="text-16 d-block"
                                            >Flow Graph</span
                                        >
                                        <div
                                            class="graph-area"
                                            *ngIf="showplantGraph"
                                        >
                                            <!-- <img src="assets/images/flow-graph.png" alt=""> -->
                                            <div class="flow-grap-content">
                                                <div class="flow-graph-left">
                                                    <div class="flow-box one">
                                                        <div class="ico-box">
                                                            <div
                                                                class="ico-holder"
                                                            >
                                                                <img
                                                                    src="../../../assets/images/ico-solar.png"
                                                                    alt=""
                                                                />
                                                                <span
                                                                    class="title"
                                                                    >Generation</span
                                                                >
                                                            </div>
                                                        </div>
                                                        <i class="arrow">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="22"
                                                                height="22.044"
                                                                viewBox="0 0 22 22.044"
                                                            >
                                                                <g
                                                                    id="ic_sys_right_arrow"
                                                                    transform="translate(0 0.086)"
                                                                >
                                                                    <ellipse
                                                                        id="Ellipse_55"
                                                                        data-name="Ellipse 55"
                                                                        cx="11"
                                                                        cy="11.022"
                                                                        rx="11"
                                                                        ry="11.022"
                                                                        transform="translate(0 -0.086)"
                                                                        fill="#f15f12"
                                                                    />
                                                                    <g
                                                                        id="Group_107"
                                                                        data-name="Group 107"
                                                                        transform="translate(5.993 6.888)"
                                                                    >
                                                                        <g
                                                                            id="angles-right-small"
                                                                        >
                                                                            <path
                                                                                id="Path_126"
                                                                                data-name="Path 126"
                                                                                d="M411.776,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.844.844,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z"
                                                                                transform="translate(-405.883 -219.298)"
                                                                                fill="#fff"
                                                                            />
                                                                            <path
                                                                                id="Path_127"
                                                                                data-name="Path 127"
                                                                                d="M405.779,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.843.843,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z"
                                                                                transform="translate(-404.928 -219.298)"
                                                                                fill="#e1f9ff"
                                                                                opacity="0.4"
                                                                                style="
                                                                                    isolation: isolate;
                                                                                "
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        <div class="text-box">
                                                            <span
                                                                class="d-block text-12"
                                                                ><span>{{
                                                                    roundOffOneDec(
                                                                        this
                                                                            .PlantPowerDetails
                                                                            .solarGeneration
                                                                    )
                                                                }}</span>
                                                                kW</span
                                                            >
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="flow-box two"
                                                        [ngClass]="{
                                                            reverseable:
                                                                this.roundNullToZero(
                                                                    this
                                                                        .PlantPowerDetails
                                                                        .grid
                                                                ) > 0,
                                                            'no-animation':
                                                                this.roundNullToZero(
                                                                    this
                                                                        .PlantPowerDetails
                                                                        .grid
                                                                ) === 0
                                                        }"
                                                    >
                                                        <div class="ico-holder">
                                                            <img
                                                                src="../../../assets/images/ico-grid.png"
                                                                alt=""
                                                            />
                                                            <span class="title"
                                                                >Grid</span
                                                            >
                                                        </div>
                                                        <i class="arrow">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="22"
                                                                height="22.044"
                                                                viewBox="0 0 22 22.044"
                                                            >
                                                                <g
                                                                    id="ic_sys_right_arrow"
                                                                    transform="translate(0 0.086)"
                                                                >
                                                                    <ellipse
                                                                        id="Ellipse_55"
                                                                        data-name="Ellipse 55"
                                                                        cx="11"
                                                                        cy="11.022"
                                                                        rx="11"
                                                                        ry="11.022"
                                                                        transform="translate(0 -0.086)"
                                                                        fill="#f15f12"
                                                                    />
                                                                    <g
                                                                        id="Group_107"
                                                                        data-name="Group 107"
                                                                        transform="translate(5.993 6.888)"
                                                                    >
                                                                        <g
                                                                            id="angles-right-small"
                                                                        >
                                                                            <path
                                                                                id="Path_126"
                                                                                data-name="Path 126"
                                                                                d="M411.776,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.844.844,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z"
                                                                                transform="translate(-405.883 -219.298)"
                                                                                fill="#fff"
                                                                            />
                                                                            <path
                                                                                id="Path_127"
                                                                                data-name="Path 127"
                                                                                d="M405.779,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.843.843,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z"
                                                                                transform="translate(-404.928 -219.298)"
                                                                                fill="#e1f9ff"
                                                                                opacity="0.4"
                                                                                style="
                                                                                    isolation: isolate;
                                                                                "
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        <div class="text-box">
                                                            <span
                                                                class="d-block text-12"
                                                                ><span>{{
                                                                    roundOffOneDec(
                                                                        this
                                                                            .PlantPowerDetails
                                                                            .grid
                                                                    )
                                                                }}</span>
                                                                kW</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flow-grah-right">
                                                    <div class="flow-box three">
                                                        <div class="ico-holder">
                                                            <img
                                                                src="../../../assets/images/ico-inverter.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <i class="arrow">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="22"
                                                                height="22.044"
                                                                viewBox="0 0 22 22.044"
                                                            >
                                                                <g
                                                                    id="ic_sys_right_arrow"
                                                                    transform="translate(0 0.086)"
                                                                >
                                                                    <ellipse
                                                                        id="Ellipse_55"
                                                                        data-name="Ellipse 55"
                                                                        cx="11"
                                                                        cy="11.022"
                                                                        rx="11"
                                                                        ry="11.022"
                                                                        transform="translate(0 -0.086)"
                                                                        fill="#f15f12"
                                                                    />
                                                                    <g
                                                                        id="Group_107"
                                                                        data-name="Group 107"
                                                                        transform="translate(5.993 6.888)"
                                                                    >
                                                                        <g
                                                                            id="angles-right-small"
                                                                        >
                                                                            <path
                                                                                id="Path_126"
                                                                                data-name="Path 126"
                                                                                d="M411.776,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.844.844,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z"
                                                                                transform="translate(-405.883 -219.298)"
                                                                                fill="#fff"
                                                                            />
                                                                            <path
                                                                                id="Path_127"
                                                                                data-name="Path 127"
                                                                                d="M405.779,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.843.843,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z"
                                                                                transform="translate(-404.928 -219.298)"
                                                                                fill="#e1f9ff"
                                                                                opacity="0.4"
                                                                                style="
                                                                                    isolation: isolate;
                                                                                "
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </i>
                                                        <div
                                                            class="text-box"
                                                            style="top: 82%"
                                                        >
                                                            <span
                                                                class="d-block text-12"
                                                                ><span>{{
                                                                    roundOffOneDec(
                                                                        this
                                                                            .PlantPowerDetails
                                                                            .load
                                                                    )
                                                                }}</span>
                                                                kW</span
                                                            >
                                                        </div>
                                                        <div class="ico-holder">
                                                            <img
                                                                src="../../../assets/images/ico-house.png"
                                                                alt=""
                                                            />
                                                            <span class="title"
                                                                >Load</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="graph-area">
                                                <img src="assets/images/flow-graph.png" alt="">
                                                <div class="text-box">
                                                    <i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.044" viewBox="0 0 22 22.044">
                                                            <g id="ic_sys_right_arrow" transform="translate(0 0.086)">
                                                                <ellipse id="Ellipse_55" data-name="Ellipse 55" cx="11" cy="11.022" rx="11" ry="11.022" transform="translate(0 -0.086)" fill="#f15f12"/>
                                                                <g id="Group_107" data-name="Group 107" transform="translate(5.993 6.888)">
                                                                <g id="angles-right-small">
                                                                    <path id="Path_126" data-name="Path 126" d="M411.776,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.844.844,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z" transform="translate(-405.883 -219.298)" fill="#fff"/>
                                                                    <path id="Path_127" data-name="Path 127" d="M405.779,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.843.843,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z" transform="translate(-404.928 -219.298)" fill="#e1f9ff" opacity="0.4" style="isolation: isolate"/>
                                                                </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </i>
                                                    <span class="d-block text-12"><span>{{roundOffOneDec(this.PlantPowerDetails.solarGeneration)}}</span> kW</span>
                                                </div>
                                                <div class="text-box reverseable" style="top: 82%">
                                                    <i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.044" viewBox="0 0 22 22.044">
                                                            <g id="ic_sys_right_arrow" transform="translate(0 0.086)">
                                                                <ellipse id="Ellipse_55" data-name="Ellipse 55" cx="11" cy="11.022" rx="11" ry="11.022" transform="translate(0 -0.086)" fill="#f15f12"/>
                                                                <g id="Group_107" data-name="Group 107" transform="translate(5.993 6.888)">
                                                                <g id="angles-right-small">
                                                                    <path id="Path_126" data-name="Path 126" d="M411.776,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.844.844,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z" transform="translate(-405.883 -219.298)" fill="#fff"/>
                                                                    <path id="Path_127" data-name="Path 127" d="M405.779,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.843.843,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z" transform="translate(-404.928 -219.298)" fill="#e1f9ff" opacity="0.4" style="isolation: isolate"/>
                                                                </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </i>
                                                    <span class="d-block text-12"><span>{{roundOffOneDec(this.PlantPowerDetails.grid)}}</span> kW</span>
                                                </div>
                                                <div class="text-box" style="top: 53%; left: 67%">
                                                    <i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.044" viewBox="0 0 22 22.044">
                                                            <g id="ic_sys_right_arrow" transform="translate(0 0.086)">
                                                                <ellipse id="Ellipse_55" data-name="Ellipse 55" cx="11" cy="11.022" rx="11" ry="11.022" transform="translate(0 -0.086)" fill="#f15f12"/>
                                                                <g id="Group_107" data-name="Group 107" transform="translate(5.993 6.888)">
                                                                <g id="angles-right-small">
                                                                    <path id="Path_126" data-name="Path 126" d="M411.776,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.844.844,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z" transform="translate(-405.883 -219.298)" fill="#fff"/>
                                                                    <path id="Path_127" data-name="Path 127" d="M405.779,227.727a.843.843,0,0,1-.595-1.438l2.767-2.772-2.767-2.773a.841.841,0,0,1,1.167-1.211l.021.02,3.361,3.367a.843.843,0,0,1,0,1.191l-3.361,3.367a.844.844,0,0,1-.594.247Z" transform="translate(-404.928 -219.298)" fill="#e1f9ff" opacity="0.4" style="isolation: isolate"/>
                                                                </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </i>
                                                    <span class="d-block text-12"><span>{{roundOffOneDec(this.PlantPowerDetails.load)}}</span> kW</span>
                                                </div>
                                            </div> -->
                                    </div>
                                    <!-- <div class="status-box">
                                            <h4>Active Alarms</h4>
                                            <div class="status-holder">
                                                <div class="color-wheel color-wheel-2">
                                                    <div class="text-box">
                                                        <div class="holder"> 
                                                            <strong>80</strong>
                                                            <span>Total Alarms</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="box-content">
                                                    <ul class="bullet-list">
                                                        <li>
                                                            <i class="ico">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.736" height="17.099" viewBox="0 0 17.736 17.099">
                                                                    <path id="Subtraction_10" data-name="Subtraction 10" d="M-97.266,17.1H-115V17.07c0-.488,0-.95.023-1.436,0-.066.084-.131.157-.18a6.7,6.7,0,0,0,1.916-1.826,4.756,4.756,0,0,0,.857-2.839,23.109,23.109,0,0,1,.248-3.741,8.174,8.174,0,0,1,.856-2.726,4.718,4.718,0,0,1,3.177-2.547.084.084,0,0,1,.068-.022.936.936,0,0,0,.113-.36.723.723,0,0,1,.067-.316A1.477,1.477,0,0,1-106.133,0a1.348,1.348,0,0,1,.214.017,1.573,1.573,0,0,1,1.262,1.532.3.3,0,0,0,.113.2,5.152,5.152,0,0,1,3.786,3.921,12.706,12.706,0,0,1,.473,3.065c.022.426.034.86.045,1.279s.023.86.045,1.291a4.792,4.792,0,0,0,1.51,3.132,11.354,11.354,0,0,0,.92.756c.12.092.244.186.364.281a10.186,10.186,0,0,1,.135,1.622Zm-7.38-11.929a.251.251,0,0,0-.182.078l-4,4.245a.251.251,0,0,0-.047.27.248.248,0,0,0,.228.151h1.661l-.9,2.923a.249.249,0,0,0,.113.289.251.251,0,0,0,.126.034.25.25,0,0,0,.181-.078l4-4.245a.25.25,0,0,0,.047-.27.249.249,0,0,0-.229-.151h-1.659l.9-2.922a.249.249,0,0,0-.113-.29A.246.246,0,0,0-104.646,5.17Z" transform="translate(115.001 0)" fill="#ee2a2a"/>
                                                                </svg>
                                                                
                                                            </i>
                                                            <div class="text-box">
                                                                <span class="text-16 d-block">0</span>
                                                                <span class="text-12 d-block">Critical</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i class="ico" style="background: #FFDDB4;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.33" height="19.389" viewBox="0 0 13.33 19.389">
                                                                    <path id="Path_5439" data-name="Path 5439" d="M33.1.079a.606.606,0,0,1,.275.7L31.2,7.873h4.027a.606.606,0,0,1,.441,1.022l-9.694,10.3a.606.606,0,0,1-1.02-.594l2.182-7.092H23.1a.606.606,0,0,1-.441-1.022L32.357.187A.606.606,0,0,1,33.1.078Z" transform="translate(-22.498 0.004)" fill="#fb8c04"/>
                                                                </svg>
                                                                
                                                                
                                                            </i>
                                                            <div class="text-box">
                                                                <span class="text-16 d-block">78</span>
                                                                <span class="text-12 d-block">Major</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i class="ico" class="ico" style="background: #FFF1C8;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="3.864" height="21.254" viewBox="0 0 3.864 21.254">
                                                                    <path id="Path_5440" data-name="Path 5440" d="M21.01,8.126a1.932,1.932,0,1,1,3.845,0L24.1,20.395a1.179,1.179,0,0,1-2.346,0ZM21,25.322a1.932,1.932,0,1,1,1.932,1.932A1.932,1.932,0,0,1,21,25.322Z" transform="translate(-21 -6)" fill="#ffbf00"/>
                                                                </svg>
                                                                
                                                                
                                                            </i>
                                                            <div class="text-box">
                                                                
                                                                    <span class="text-16 d-block">0</span>
                                                                    <span class="text-12 d-block">Minor</span>
                                                                
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i class="ico" class="ico" style="background: #AADBFF;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="3.864" height="21.254" viewBox="0 0 3.864 21.254">
                                                                    <path id="Path_5440" data-name="Path 5440" d="M21.01,8.126a1.932,1.932,0,1,1,3.845,0L24.1,20.395a1.179,1.179,0,0,1-2.346,0ZM21,25.322a1.932,1.932,0,1,1,1.932,1.932A1.932,1.932,0,0,1,21,25.322Z" transform="translate(-21 -6)" fill="#32a8fe"/>
                                                                </svg>
                                                                
                                                                
                                                            </i>
                                                            <div class="text-box">
                                                                
                                                                    <span class="text-16 d-block">2</span>
                                                                    <span class="text-12 d-block">Warning</span>
                                                                
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                
                                            </div>
                                        </div> -->
                                    <div class="status-box mb-0 col-6">
                                        <h4>Active Alarms</h4>
                                        <div class="status-holder">
                                            <div class="box-content">
                                                <ul class="bullet-list">
                                                    <li>
                                                        <i class="ico">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17.736"
                                                                height="17.099"
                                                                viewBox="0 0 17.736 17.099"
                                                            >
                                                                <path
                                                                    id="Subtraction_10"
                                                                    data-name="Subtraction 10"
                                                                    d="M-97.266,17.1H-115V17.07c0-.488,0-.95.023-1.436,0-.066.084-.131.157-.18a6.7,6.7,0,0,0,1.916-1.826,4.756,4.756,0,0,0,.857-2.839,23.109,23.109,0,0,1,.248-3.741,8.174,8.174,0,0,1,.856-2.726,4.718,4.718,0,0,1,3.177-2.547.084.084,0,0,1,.068-.022.936.936,0,0,0,.113-.36.723.723,0,0,1,.067-.316A1.477,1.477,0,0,1-106.133,0a1.348,1.348,0,0,1,.214.017,1.573,1.573,0,0,1,1.262,1.532.3.3,0,0,0,.113.2,5.152,5.152,0,0,1,3.786,3.921,12.706,12.706,0,0,1,.473,3.065c.022.426.034.86.045,1.279s.023.86.045,1.291a4.792,4.792,0,0,0,1.51,3.132,11.354,11.354,0,0,0,.92.756c.12.092.244.186.364.281a10.186,10.186,0,0,1,.135,1.622Zm-7.38-11.929a.251.251,0,0,0-.182.078l-4,4.245a.251.251,0,0,0-.047.27.248.248,0,0,0,.228.151h1.661l-.9,2.923a.249.249,0,0,0,.113.289.251.251,0,0,0,.126.034.25.25,0,0,0,.181-.078l4-4.245a.25.25,0,0,0,.047-.27.249.249,0,0,0-.229-.151h-1.659l.9-2.922a.249.249,0,0,0-.113-.29A.246.246,0,0,0-104.646,5.17Z"
                                                                    transform="translate(115.001 0)"
                                                                    fill="#ee2a2a"
                                                                />
                                                            </svg>
                                                        </i>
                                                        <div class="text-box">
                                                            <span
                                                                class="text-16 d-block"
                                                                >{{
                                                                    this
                                                                        .alarmSts
                                                                        .Critical
                                                                }}</span
                                                            >
                                                            <span
                                                                class="text-12 d-block"
                                                                >Critical</span
                                                            >
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <i
                                                            class="ico"
                                                            style="
                                                                background: #ffddb4;
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="13.33"
                                                                height="19.389"
                                                                viewBox="0 0 13.33 19.389"
                                                            >
                                                                <path
                                                                    id="Path_5439"
                                                                    data-name="Path 5439"
                                                                    d="M33.1.079a.606.606,0,0,1,.275.7L31.2,7.873h4.027a.606.606,0,0,1,.441,1.022l-9.694,10.3a.606.606,0,0,1-1.02-.594l2.182-7.092H23.1a.606.606,0,0,1-.441-1.022L32.357.187A.606.606,0,0,1,33.1.078Z"
                                                                    transform="translate(-22.498 0.004)"
                                                                    fill="#fb8c04"
                                                                />
                                                            </svg>
                                                        </i>
                                                        <div class="text-box">
                                                            <span
                                                                class="text-16 d-block"
                                                                >{{
                                                                    this
                                                                        .alarmSts
                                                                        .Major
                                                                }}</span
                                                            >
                                                            <span
                                                                class="text-12 d-block"
                                                                >Major</span
                                                            >
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <i
                                                            class="ico"
                                                            class="ico"
                                                            style="
                                                                background: #fff1c8;
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="3.864"
                                                                height="21.254"
                                                                viewBox="0 0 3.864 21.254"
                                                            >
                                                                <path
                                                                    id="Path_5440"
                                                                    data-name="Path 5440"
                                                                    d="M21.01,8.126a1.932,1.932,0,1,1,3.845,0L24.1,20.395a1.179,1.179,0,0,1-2.346,0ZM21,25.322a1.932,1.932,0,1,1,1.932,1.932A1.932,1.932,0,0,1,21,25.322Z"
                                                                    transform="translate(-21 -6)"
                                                                    fill="#ffbf00"
                                                                />
                                                            </svg>
                                                        </i>
                                                        <div class="text-box">
                                                            <span
                                                                class="text-16 d-block"
                                                                >{{
                                                                    this
                                                                        .alarmSts
                                                                        .Minor
                                                                }}</span
                                                            >
                                                            <span
                                                                class="text-12 d-block"
                                                                >Minor</span
                                                            >
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <i
                                                            class="ico"
                                                            class="ico"
                                                            style="
                                                                background: #aadbff;
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="3.864"
                                                                height="21.254"
                                                                viewBox="0 0 3.864 21.254"
                                                            >
                                                                <path
                                                                    id="Path_5440"
                                                                    data-name="Path 5440"
                                                                    d="M21.01,8.126a1.932,1.932,0,1,1,3.845,0L24.1,20.395a1.179,1.179,0,0,1-2.346,0ZM21,25.322a1.932,1.932,0,1,1,1.932,1.932A1.932,1.932,0,0,1,21,25.322Z"
                                                                    transform="translate(-21 -6)"
                                                                    fill="#32a8fe"
                                                                />
                                                            </svg>
                                                        </i>
                                                        <div class="text-box">
                                                            <span
                                                                class="text-16 d-block"
                                                                >{{
                                                                    this
                                                                        .alarmSts
                                                                        .Warning
                                                                }}</span
                                                            >
                                                            <span
                                                                class="text-12 d-block"
                                                                >Warning</span
                                                            >
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-6">
                                                <ngx-charts-pie-chart
                                                    [view]="[250, 200]"
                                                    [scheme]="{
                                                        domain: [
                                                            '#EE2A2A',
                                                            '#FB8C04',
                                                            '#FFCE3C',
                                                            '#32A8FE'
                                                        ]
                                                    }"
                                                    [results]="series"
                                                    [doughnut]="true"
                                                    [tooltipText]="
                                                        labelFormatting
                                                    "
                                                >
                                                </ngx-charts-pie-chart>
                                                <div
                                                    class="text-box donut-chart-label"
                                                >
                                                    <div class="holder">
                                                        <strong>{{
                                                            this.alarmSts
                                                                .TotalAlarms
                                                        }}</strong
                                                        ><br />
                                                        <span
                                                            >Total Alarms</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <!-- <canvasjs-chart [options]="chartOptionsAlarm" [styles]="{width: '150px', height:'250px'}">                                       
                                                        <p>Testing...</p>
                                                    </canvasjs-chart>   -->

                                                <!-- <div class="color-wheel color-wheel-2">

                                                        
                                                        <div class="text-box">
                                                            <div class="holder"> 
                                                                <strong>{{
                                                                    this.alarmSts.TotalAlarms }}</strong>
                                                                <span>Total Alarms</span>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                            </div>

                                            <!-- <div class="color-wheel color-wheel-2">
                                                    <div class="text-box">
                                                        <div class="holder"> 
                                                            <strong>{{
                                                                this.alarmSts.TotalAlarms }}</strong>
                                                            <span>Total Alarms</span>
                                                        </div>
                                                    </div>
                                                </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="tab-2" *ngIf="invert">
                            <ngx-slick-carousel
                                class="carousel"
                                #slickModal="slick-carousel"
                                [config]="slideConfig"
                                (init)="slickInit($event)"
                                (breakpoint)="breakpoint($event)"
                                (afterChange)="afterChange($event)"
                                (beforeChange)="beforeChange($event)"
                            >
                                <div
                                    ngxSlickItem
                                    class="slide"
                                    *ngFor="let item of inverterDetail"
                                >
                                    <div class="card-content">
                                        <ul class="tab-links">
                                            <li>
                                                <a
                                                    (click)="
                                                        showData(
                                                            'data',
                                                            selectedNumber,
                                                            item.basicInfo.devId
                                                        )
                                                    "
                                                    [ngClass]="{
                                                        sideTab: vardata
                                                    }"
                                                    class="active"
                                                    >Data View</a
                                                >
                                            </li>
                                            <li>
                                                <a
                                                    (click)="
                                                        showData(
                                                            'graphicalView',
                                                            selectedNumber,
                                                            item.basicInfo.devId
                                                        )
                                                    "
                                                    [ngClass]="{
                                                        sideTab: graphicalView
                                                    }"
                                                    >Graphical View</a
                                                >
                                            </li>
                                        </ul>
                                        <div class="inner-tab-data">
                                            <div id="tab-1" class="tab-content">
                                                <div class="slides-data">
                                                    <div class="slide-data">
                                                        <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                                                                (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
                                                                (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)"> -->
                                                        <!-- <div ngxSlickItem class="slide" *ngFor="let item of inverterDetail"> -->
                                                        <div
                                                            class="inner-tab-data"
                                                            *ngIf="vardata"
                                                        >
                                                            <div
                                                                class="row align-items-center"
                                                            >
                                                                <div
                                                                    class="col-lg-4"
                                                                >
                                                                    <div
                                                                        class="table-wrap no-shadow"
                                                                    >
                                                                        <table
                                                                            class="table-main stripped"
                                                                        >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        Inverter
                                                                                        {{
                                                                                            item.serialNumber
                                                                                        }}
                                                                                        :
                                                                                        <strong
                                                                                            >{{
                                                                                                item
                                                                                                    .basicInfo
                                                                                                    .deviceName
                                                                                            }}</strong
                                                                                        >
                                                                                    </th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Serial
                                                                                        Number
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .serial_number
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <ng-container
                                                                                        *ngIf="
                                                                                            item
                                                                                                .basicInfo
                                                                                                .status;
                                                                                            else falseBlock
                                                                                        "
                                                                                    >
                                                                                        <td>
                                                                                            Status
                                                                                        </td>
                                                                                        <td>
                                                                                            <strong
                                                                                                >Connected</strong
                                                                                            >
                                                                                        </td>
                                                                                    </ng-container>
                                                                                    <ng-template
                                                                                        #falseBlock
                                                                                    >
                                                                                        <td>
                                                                                            Status
                                                                                        </td>
                                                                                        <td>
                                                                                            Disconnected
                                                                                        </td>
                                                                                    </ng-template>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Total
                                                                                        DC
                                                                                        Input
                                                                                        Power
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .total_input_power
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Today's
                                                                                        Generation
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .today_generation
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Generation
                                                                                        Yestarday
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .yesterday_yield
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Generation
                                                                                        of
                                                                                        Last
                                                                                        Month
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .last_month_yield
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Generation
                                                                                        of
                                                                                        Last
                                                                                        Year
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .last_year_yield
                                                                                        }}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td>
                                                                                        Inner
                                                                                        Temprature
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .inner_temperature
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Inverter
                                                                                        Model
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .invType
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-lg-4"
                                                                >
                                                                    <div
                                                                        class="box-power"
                                                                    >
                                                                        <div
                                                                            class="box-circle"
                                                                            [ngStyle]="{
                                                                                'background-color':
                                                                                    item
                                                                                        .basicInfo
                                                                                        .status
                                                                                        ? ''
                                                                                        : '#6D6D6D'
                                                                            }"
                                                                        >
                                                                            <div
                                                                                class="box-holder"
                                                                            >
                                                                                <h3>
                                                                                    Power
                                                                                </h3>
                                                                                <span
                                                                                    class="d-block text-16"
                                                                                    >{{
                                                                                        item
                                                                                            .basicInfo
                                                                                            .power_now
                                                                                    }}</span
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                        <ul
                                                                            class="list-texts"
                                                                        >
                                                                            <li>
                                                                                <span
                                                                                    >Total
                                                                                    Generation</span
                                                                                >
                                                                                <!-- <span>{{item.basicInfo.total_input_power}}  Kwh</span> -->
                                                                                <span
                                                                                    >{{
                                                                                        item
                                                                                            .basicInfo
                                                                                            .total_generation
                                                                                    }}
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                <span
                                                                                    >Start
                                                                                    Time</span
                                                                                >
                                                                                <!-- <span>{{item.basicInfo.start_time}}</span> -->
                                                                                <span
                                                                                    >{{
                                                                                        item
                                                                                            .basicInfo
                                                                                            .start_time
                                                                                            | date
                                                                                                : 'yyyy-MM-dd'
                                                                                    }}
                                                                                    {{
                                                                                        item
                                                                                            .basicInfo
                                                                                            .start_time
                                                                                            | date
                                                                                                : 'HH:mm:ss'
                                                                                    }}</span
                                                                                >
                                                                            </li>
                                                                            <li>
                                                                                <span
                                                                                    >Close
                                                                                    Time</span
                                                                                >
                                                                                <!-- <span>{{item.basicInfo.close_time}}</span> -->
                                                                                <span
                                                                                    >{{
                                                                                        item
                                                                                            .basicInfo
                                                                                            .close_time
                                                                                            | date
                                                                                                : 'yyyy-MM-dd'
                                                                                    }}
                                                                                    {{
                                                                                        item
                                                                                            .basicInfo
                                                                                            .close_time
                                                                                            | date
                                                                                                : 'HH:mm:ss'
                                                                                    }}</span
                                                                                >
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-lg-4"
                                                                >
                                                                    <div
                                                                        class="table-wrap no-shadow"
                                                                    >
                                                                        <table
                                                                            class="table-main stripped text-center ml-auto"
                                                                        >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        Phase
                                                                                    </th>
                                                                                    <th>
                                                                                        Voltage
                                                                                    </th>
                                                                                    <th>
                                                                                        Current
                                                                                    </th>
                                                                                    <th>
                                                                                        Φ
                                                                                        -
                                                                                        Φ
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        Φ<sub
                                                                                            >1</sub
                                                                                        >
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q1_voltage
                                                                                        }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q1_current
                                                                                        }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q_q1
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Φ<sub
                                                                                            >2</sub
                                                                                        >
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q2_voltage
                                                                                        }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q2_current
                                                                                        }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q_q2
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Φ<sub
                                                                                            >1</sub
                                                                                        >
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q3_voltage
                                                                                        }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q3_current
                                                                                        }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item
                                                                                                .basicInfo
                                                                                                .q_q3
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div
                                                                        class="text-base justify-content-end"
                                                                    >
                                                                        <span
                                                                            style="
                                                                                color: #f15f12;
                                                                            "
                                                                            >Electrical
                                                                            Frequency</span
                                                                        >
                                                                        <span
                                                                            >{{
                                                                                item
                                                                                    .basicInfo
                                                                                    .frequency
                                                                            }}
                                                                            HZ</span
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="arrow-bar"
                                                            >
                                                                <a
                                                                    (click)="
                                                                        slickModal.slickPrev();
                                                                        prev()
                                                                    "
                                                                    class="btn-arrow"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                    >
                                                                        <g
                                                                            id="Group_2570"
                                                                            data-name="Group 2570"
                                                                            transform="translate(0.056 0.056)"
                                                                        >
                                                                            <circle
                                                                                id="Ellipse_1434"
                                                                                data-name="Ellipse 1434"
                                                                                cx="10"
                                                                                cy="10"
                                                                                r="10"
                                                                                transform="translate(-0.056 -0.056)"
                                                                                fill="#d6ebff"
                                                                            />
                                                                            <g
                                                                                id="chevron-down-outline"
                                                                                transform="translate(7.814 14.206) rotate(-90)"
                                                                            >
                                                                                <path
                                                                                    id="Path_5366"
                                                                                    data-name="Path 5366"
                                                                                    d="M0,4.262,4.262,0,8.524,4.262"
                                                                                    fill="none"
                                                                                    stroke="#0083ff"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="2"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </a>

                                                                <span
                                                                    class="border"
                                                                ></span>
                                                                <a
                                                                    (click)="
                                                                        slickModal.slickNext();
                                                                        next()
                                                                    "
                                                                    class="btn-arrow"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                    >
                                                                        <g
                                                                            id="Group_2571"
                                                                            data-name="Group 2571"
                                                                            transform="translate(0)"
                                                                        >
                                                                            <circle
                                                                                id="Ellipse_1434"
                                                                                data-name="Ellipse 1434"
                                                                                cx="10"
                                                                                cy="10"
                                                                                r="10"
                                                                                transform="translate(0)"
                                                                                fill="#d6ebff"
                                                                            />
                                                                            <g
                                                                                id="chevron-down-outline"
                                                                                transform="translate(7.869 14.262) rotate(-90)"
                                                                            >
                                                                                <path
                                                                                    id="Path_5366"
                                                                                    data-name="Path 5366"
                                                                                    d="M0,0,4.262,4.262,8.524,0"
                                                                                    fill="none"
                                                                                    stroke="#0083ff"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-width="2"
                                                                                />
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div class="row">
                                                                <div
                                                                    class="col-12"
                                                                >
                                                                    <div
                                                                        class="table-wrap no-shadow"
                                                                    >
                                                                        <div
                                                                            class="table-main"
                                                                        >
                                                                            <table
                                                                                class="table-main stripped text-center"
                                                                            >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            Name
                                                                                        </th>
                                                                                        <th>
                                                                                            PV1
                                                                                        </th>
                                                                                        <th>
                                                                                            PV1
                                                                                        </th>
                                                                                        <th>
                                                                                            PV3
                                                                                        </th>
                                                                                        <th>
                                                                                            PV4
                                                                                        </th>
                                                                                        <th>
                                                                                            PV5
                                                                                        </th>
                                                                                        <th>
                                                                                            PV6
                                                                                        </th>
                                                                                        <th>
                                                                                            PV7
                                                                                        </th>
                                                                                        <th>
                                                                                            PV8
                                                                                        </th>
                                                                                        <th>
                                                                                            PV9
                                                                                        </th>
                                                                                        <th>
                                                                                            PV10
                                                                                        </th>
                                                                                        <th>
                                                                                            PV11
                                                                                        </th>
                                                                                        <th>
                                                                                            PV12
                                                                                        </th>
                                                                                        <th>
                                                                                            PV13
                                                                                        </th>
                                                                                        <th>
                                                                                            PV14
                                                                                        </th>
                                                                                        <th>
                                                                                            PV15
                                                                                        </th>
                                                                                        <th>
                                                                                            PV16
                                                                                        </th>
                                                                                        <th>
                                                                                            PV17
                                                                                        </th>
                                                                                        <th>
                                                                                            PV18
                                                                                        </th>
                                                                                        <th>
                                                                                            PV19
                                                                                        </th>
                                                                                        <th>
                                                                                            PV20
                                                                                        </th>
                                                                                        <th>
                                                                                            PV21
                                                                                        </th>
                                                                                        <th>
                                                                                            PV22
                                                                                        </th>
                                                                                        <th>
                                                                                            PV23
                                                                                        </th>
                                                                                        <th>
                                                                                            PV24
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <!-- <tbody>
                                                                                            <tr >
                                                                                                <td>Voltage</td>
                                                                                                <td>{{item.pvValues[0].voltage[0]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[1]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[2]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[3]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[4]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[5]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[6]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[7]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[8]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[9]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[10]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[11]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[12]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[13]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[14]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[15]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[16]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[17]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[18]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[19]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[20]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[21]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[22]}}</td>
                                                                                                <td>{{item.pvValues[0].voltage[23]}}</td>
                                                                                            </tr>
                                                                                            <tr> 
                                                                                                <td>Current</td>
                                                                                                <td>{{item.pvValues[0].current[0]}}</td>
                                                                                                <td>{{item.pvValues[0].current[1]}}</td>
                                                                                                <td>{{item.pvValues[0].current[2]}}</td>
                                                                                                <td>{{item.pvValues[0].current[3]}}</td>
                                                                                                <td>{{item.pvValues[0].current[4]}}</td>
                                                                                                <td>{{item.pvValues[0].current[5]}}</td>
                                                                                                <td>{{item.pvValues[0].current[6]}}</td>
                                                                                                <td>{{item.pvValues[0].current[7]}}</td>
                                                                                                <td>{{item.pvValues[0].current[8]}}</td>
                                                                                                <td>{{item.pvValues[0].current[9]}}</td>
                                                                                                <td>{{item.pvValues[0].current[10]}}</td>
                                                                                                <td>{{item.pvValues[0].current[11]}}</td>
                                                                                                <td>{{item.pvValues[0].current[12]}}</td>
                                                                                                <td>{{item.pvValues[0].current[13]}}</td>
                                                                                                <td>{{item.pvValues[0].current[14]}}</td>
                                                                                                <td>{{item.pvValues[0].current[15]}}</td>
                                                                                                <td>{{item.pvValues[0].current[16]}}</td>
                                                                                                <td>{{item.pvValues[0].current[17]}}</td>
                                                                                                <td>{{item.pvValues[0].current[18]}}</td>
                                                                                                <td>{{item.pvValues[0].current[19]}}</td>
                                                                                                <td>{{item.pvValues[0].current[20]}}</td>
                                                                                                <td>{{item.pvValues[0].current[21]}}</td>
                                                                                                <td>{{item.pvValues[0].current[22]}}</td>
                                                                                                <td>{{item.pvValues[0].current[23]}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>Power</td>
                                                                                                <td>{{item.pvValues[0].power[0]}}</td>
                                                                                                <td>{{item.pvValues[0].power[1]}}</td>
                                                                                                <td>{{item.pvValues[0].power[2]}}</td>
                                                                                                <td>{{item.pvValues[0].power[3]}}</td>
                                                                                                <td>{{item.pvValues[0].power[4]}}</td>
                                                                                                <td>{{item.pvValues[0].power[5]}}</td>
                                                                                                <td>{{item.pvValues[0].power[6]}}</td>
                                                                                                <td>{{item.pvValues[0].power[7]}}</td>
                                                                                                <td>{{item.pvValues[0].power[8]}}</td>
                                                                                                <td>{{item.pvValues[0].power[9]}}</td>
                                                                                                <td>{{item.pvValues[0].power[10]}}</td>
                                                                                                <td>{{item.pvValues[0].power[11]}}</td>
                                                                                                <td>{{item.pvValues[0].power[12]}}</td>
                                                                                                <td>{{item.pvValues[0].power[13]}}</td>
                                                                                                <td>{{item.pvValues[0].power[14]}}</td>
                                                                                                <td>{{item.pvValues[0].power[15]}}</td>
                                                                                                <td>{{item.pvValues[0].power[16]}}</td>
                                                                                                <td>{{item.pvValues[0].power[17]}}</td>
                                                                                                <td>{{item.pvValues[0].power[18]}}</td>
                                                                                                <td>{{item.pvValues[0].power[19]}}</td>
                                                                                                <td>{{item.pvValues[0].power[20]}}</td>
                                                                                                <td>{{item.pvValues[0].power[21]}}</td>
                                                                                                <td>{{item.pvValues[0].power[22]}}</td>
                                                                                                <td>{{item.pvValues[0].power[23]}}</td>
                                                                                            </tr>
                                                                                            
                                                                                        </tbody> -->
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Voltage
                                                                                        </td>
                                                                                        <ng-container
                                                                                            *ngFor="
                                                                                                let voltage of item
                                                                                                    .pvValues[
                                                                                                    currentIndex
                                                                                                ]
                                                                                                    .voltage
                                                                                            "
                                                                                        >
                                                                                            <td>
                                                                                                {{
                                                                                                    voltage
                                                                                                }}
                                                                                            </td>
                                                                                        </ng-container>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Current
                                                                                        </td>
                                                                                        <ng-container
                                                                                            *ngFor="
                                                                                                let current of item
                                                                                                    .pvValues[
                                                                                                    currentIndex
                                                                                                ]
                                                                                                    .current
                                                                                            "
                                                                                        >
                                                                                            <td>
                                                                                                {{
                                                                                                    current
                                                                                                }}
                                                                                            </td>
                                                                                        </ng-container>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            Power
                                                                                        </td>
                                                                                        <ng-container
                                                                                            *ngFor="
                                                                                                let power of item
                                                                                                    .pvValues[
                                                                                                    currentIndex
                                                                                                ]
                                                                                                    .power
                                                                                            "
                                                                                        >
                                                                                            <td>
                                                                                                {{
                                                                                                    power
                                                                                                }}
                                                                                            </td>
                                                                                        </ng-container>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="inner-tab-data"
                                                            *ngIf="
                                                                graphicalView
                                                            "
                                                        >
                                                            <div class="row">
                                                                <div
                                                                    class="col-md-12"
                                                                >
                                                                    <div
                                                                        class="form-group"
                                                                    >
                                                                        <div
                                                                            class="row"
                                                                        >
                                                                            <div
                                                                                class="d-flex justify-content-end w-100"
                                                                            >
                                                                                <input
                                                                                    type="date"
                                                                                    [ngModel]="
                                                                                        dtEnv
                                                                                    "
                                                                                    (ngModelChange)="
                                                                                        this.showDataWithDate(
                                                                                            'graphicalView',
                                                                                            selectedNumber,
                                                                                            item
                                                                                                .basicInfo
                                                                                                .devId,
                                                                                            $event
                                                                                        )
                                                                                    "
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                class="plant-inverter-title"
                                                                            >
                                                                                <span
                                                                                    >Inverter:
                                                                                    {{
                                                                                        item
                                                                                            .basicInfo
                                                                                            .serial_number
                                                                                    }}</span
                                                                                >
                                                                            </div>
                                                                            <div
                                                                                style="
                                                                                    display: block;
                                                                                "
                                                                                class="graph-block"
                                                                            >
                                                                                <canvas
                                                                                    baseChart
                                                                                    [datasets]="
                                                                                        this
                                                                                            .graphViewData
                                                                                    "
                                                                                    [labels]="
                                                                                        lineChartLabels
                                                                                    "
                                                                                    [options]="
                                                                                        lineChartOptions
                                                                                    "
                                                                                    [legend]="
                                                                                        lineChartLegend
                                                                                    "
                                                                                    [type]="
                                                                                        chartType
                                                                                    "
                                                                                >
                                                                                </canvas>
                                                                            </div>
                                                                            <div
                                                                                class="col-1"
                                                                                *ngFor="
                                                                                    let itemKey of this
                                                                                        .pvGraphKey
                                                                                "
                                                                            >
                                                                                <label
                                                                                    class="custom-checkbox"
                                                                                    title="{{
                                                                                        itemKey.value
                                                                                    }}"
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        [(ngModel)]="
                                                                                            itemKey.status
                                                                                        "
                                                                                        (change)="
                                                                                            onCheckboxChange()
                                                                                        "
                                                                                        [disabled]="
                                                                                            isCheckBoxDisabled(
                                                                                                itemKey
                                                                                            )
                                                                                        "
                                                                                    />
                                                                                    <span
                                                                                        class="checkbox-item"
                                                                                    ></span>
                                                                                    <span
                                                                                        class="lbl-text"
                                                                                        style="
                                                                                            font-size: 11px;
                                                                                        "
                                                                                        >&nbsp;
                                                                                        {{
                                                                                            itemKey.value
                                                                                        }}</span
                                                                                    >
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                id="tab-2"
                                                class="tab-content"
                                                style="display: none"
                                            ></div>
                                        </div>

                                        <!-- ilyas remove Graphical view  -->
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                        <div id="tab-3" *ngIf="pv">
                            <div class="d-flex justify-content-end m-3">
                                <input
                                    type="date"
                                    [ngModel]="dtEmiEnv"
                                    (ngModelChange)="this.EmiGraph($event)"
                                />
                            </div>
                            <div
                                class="card-body time-wise-graph"
                                *ngIf="!showhistory"
                            >
                                <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="EmiChartGraph"
                                    style="
                                        width: 100%;
                                        height: 620px;
                                        display: block;
                                        margin: 0px 0px 0px;
                                    "
                                >
                                </highcharts-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 mb-3">
                <div class="card-box card-power h-100 mb-0">
                    <div class="card-header">
                        <h3>Power & Generation</h3>
                    </div>
                    <div class="card-content">
                        <div class="card-plugins">
                            <div class="weather-plugin">
                                <div class="plugin-holder">
                                    <div class="plugin-holder-wrap">
                                        <ngx-charts-gauge
                                            [view]="[190, 200]"
                                            [scheme]="{domain: ['#FFBF00']}"
                                            [results]="powerGauge"
                                            [showAxis]="false"
                                            [max]="this.plantDetail.capacity"
                                            [showText]="false"
                                        >
                                        </ngx-charts-gauge>
                                        <div
                                            class="text-box"
                                            class="power-generation-gauge"
                                            *ngIf="ShowPowerGuage"
                                        >
                                            <div class="holder">
                                                <span style="font-size: smaller"
                                                    >Power</span
                                                ><br />
                                                <span class="size-text"
                                                    ><strong>{{
                                                        roundOffOneDec(
                                                            this.plantDetail
                                                                .powerNow
                                                        )
                                                    }}</strong
                                                    >kW</span
                                                ><br />
                                                <span style="font-size: smaller"
                                                    >{{
                                                        roundOffOneDec(
                                                            this.plantDetail
                                                                .capacity
                                                        )
                                                    }}kW</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="text-box" >
                                            <div class="holder"> 
                                                <strong style="font-size:x-large;">{{roundOffOneDec(this.plantDetail.todayGeneration)}}</strong>MWh<br>
                                                <span style="font-size:medium;">Today's Generation</span>
                                            </div>
                                        </div> -->

                                    <!-- <img src="assets/images/frame-1.png" alt=""> -->
                                </div>

                                <div class="plugin-holder right">
                                    <div class="weather-teller" *ngIf="weather && weather[0]">
                                        <div class="weather-holder">
                                            <img
                                                class="image-weather"
                                                [src]="
                                                    'assets/images/weather/' +
                                                    weather[0].image
                                                "
                                                alt=""
                                            />
                                            <div class="weather-text">
                                                <strong
                                                    >{{
                                                        (weather[0].max_temp +
                                                            weather[0]
                                                                .min_temp) /
                                                            2 | number: '1.0-0'
                                                    }}°C</strong
                                                >
                                                <span class="d-block text-12">{{
                                                    weather[0].weather
                                                }}</span>
                                            </div>
                                        </div>

                                        <div class="daytime">
                                            <div class="box">
                                                <i
                                                    ><img
                                                        src="assets/images/sunrise.png"
                                                        alt=""
                                                /></i>
                                                <span class="text"
                                                    >Sunrise</span
                                                >
                                                <span class="time"
                                                    >{{
                                                        weather[0].sunrise.slice(
                                                            0,
                                                            5
                                                        )
                                                    }}
                                                    am</span
                                                >
                                            </div>

                                            <div class="box">
                                                <i
                                                    ><img
                                                        src="assets/images/sundown.png"
                                                        alt=""
                                                /></i>
                                                <span class="text">Sunset</span>
                                                <span class="time"
                                                    >{{
                                                        weather[0].sunset.slice(
                                                            0,
                                                            5
                                                        )
                                                    }}
                                                    pm</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="generation-list">
                                <div class="list-holder">
                                    <div class="ico">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="41"
                                            viewBox="0 0 40 41"
                                        >
                                            <g
                                                id="ic_power_generat"
                                                transform="translate(-0.425 0.396)"
                                            >
                                                <ellipse
                                                    id="Ellipse_9"
                                                    data-name="Ellipse 9"
                                                    cx="20"
                                                    cy="20.5"
                                                    rx="20"
                                                    ry="20.5"
                                                    transform="translate(0.425 -0.396)"
                                                    fill="#ffdad7"
                                                />
                                                <circle
                                                    id="Ellipse_10"
                                                    data-name="Ellipse 10"
                                                    cx="14.292"
                                                    cy="14.292"
                                                    r="14.292"
                                                    transform="translate(6.152 6.152)"
                                                    fill="#ef6452"
                                                />
                                                <path
                                                    id="Path_13"
                                                    data-name="Path 13"
                                                    d="M42.393,30.969,37.232,41.688a.4.4,0,0,1-.755-.172V35.164H33.3a.4.4,0,0,1-.358-.569L38.1,23.875a.4.4,0,0,1,.755.172V30.4h3.176a.4.4,0,0,1,.358.569Z"
                                                    transform="translate(-16.91 -12.577)"
                                                    fill="#ffcc08"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <ul>
                                        <li>
                                            <span class="text"
                                                >Today Generation</span
                                            >
                                            <!-- <strong><span>{{roundOffOneDec(plantDetail?.todayGeneration)}}</span></strong> -->
                                            <strong
                                                ><span>{{
                                                    plantDetail?.todayGeneration
                                                }}</span></strong
                                            >
                                        </li>
                                        <li>
                                            <span class="text"
                                                >Month Generation</span
                                            >
                                            <!-- <strong><span>{{roundOffOneDec(plantDetail?.monthPower)}}</span></strong> -->
                                            <strong
                                                ><span>{{
                                                    plantDetail?.monthPower
                                                }}</span></strong
                                            >
                                        </li>
                                        <li>
                                            <span class="text"
                                                >Total Generation</span
                                            >
                                            <!-- <strong><span>{{roundOffOneDec(plantDetail?.lifeTimeEnergy)}}</span></strong> -->
                                            <strong
                                                ><span>{{
                                                    plantDetail?.lifeTimeEnergy
                                                }}</span></strong
                                            >
                                        </li>
                                    </ul>
                                </div>
                                <div class="list-holder">
                                    <div class="ico">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="41"
                                            height="41"
                                            viewBox="0 0 41 41"
                                        >
                                            <g
                                                id="ic_saving"
                                                transform="translate(0.437 0.396)"
                                            >
                                                <circle
                                                    id="Ellipse_11"
                                                    data-name="Ellipse 11"
                                                    cx="20.5"
                                                    cy="20.5"
                                                    r="20.5"
                                                    transform="translate(-0.437 -0.396)"
                                                    fill="#fff0c7"
                                                />
                                                <circle
                                                    id="Ellipse_12"
                                                    data-name="Ellipse 12"
                                                    cx="14.292"
                                                    cy="14.292"
                                                    r="14.292"
                                                    transform="translate(6.152 6.152)"
                                                    fill="#ffcc08"
                                                />
                                                <g
                                                    id="Group_17"
                                                    data-name="Group 17"
                                                    transform="translate(13.534 11.073)"
                                                >
                                                    <path
                                                        id="polygon2"
                                                        d="M34.639,23.508h7.889v.713l-1.951,2.3H36.59l-1.952-2.3Z"
                                                        transform="translate(-31.275 -23.508)"
                                                        fill="#ef6452"
                                                    />
                                                    <path
                                                        id="Path_14"
                                                        data-name="Path 14"
                                                        d="M40.336,35.039a35.24,35.24,0,0,0-3.524-3.691H32.836A35.385,35.385,0,0,0,29.3,35.039a6.9,6.9,0,0,0-1.795,4.748A6.15,6.15,0,0,0,31.143,45.2a8.391,8.391,0,0,0,7.352,0,6.152,6.152,0,0,0,3.635-5.409A6.9,6.9,0,0,0,40.336,35.039Zm-4.853,7.644v1.208H34.155V42.724a4.837,4.837,0,0,1-1.577-.514c.2-.384.4-.769.6-1.154a4.505,4.505,0,0,0,1.315.375,1.626,1.626,0,0,0,1.539-.572,1.01,1.01,0,0,0-.185-1.048,8.307,8.307,0,0,0-2.136-.712,2.164,2.164,0,0,1-1.491-1.928,2.467,2.467,0,0,1,1.934-2.439V33.5h1.328v1.174a3.463,3.463,0,0,1,1.738.855l-.873.966-.27-.181a1.744,1.744,0,0,0-2.3.127.841.841,0,0,0,.2,1.324c.629.28,1.347.292,1.975.581A2.344,2.344,0,0,1,37.42,40.6,2.28,2.28,0,0,1,35.483,42.684Z"
                                                        transform="translate(-27.51 -27.648)"
                                                        fill="#ef6452"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <ul>
                                        <li>
                                            <span class="text"
                                                >Today Savings</span
                                            >
                                            <!-- <strong><span>{{roundOffOneDec((plantDetail?.todayGeneration) * 40)}} K</span> PKR</strong> -->
                                            <strong
                                                ><span
                                                    >{{
                                                        plantDetail?.todaySaving
                                                    }}
                                                    </span
                                                >
                                                </strong
                                            >
                                        </li>
                                        <li>
                                            <span class="text"
                                                >Monthly Savings</span
                                            >
                                            <!-- <strong><span>{{roundOffOneDec((plantDetail?.monthPower) * 40)}} K</span> PKR</strong> -->
                                            <strong
                                                ><span
                                                    >{{
                                                        plantDetail?.monthlySavings
                                                    }}
                                                    </span
                                                >
                                                </strong
                                            >
                                        </li>
                                        <li>
                                            <span class="text"
                                                >Total Savings</span
                                            >
                                            <!-- <strong><span>{{((plantDetail?.lifeTimeEnergy) * 40).toFixed(2)}} K</span> PKR</strong> -->
                                            <strong
                                                ><span
                                                    >{{
                                                        plantDetail?.lifeTimeSaving
                                                    }}
                                                    </span
                                                >
                                                </strong
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 mb-3">
                <div class="card-box plant-details h-100 mb-0">
                    <div class="card-header">
                        <div class="row flex-grow-1">
                            <div class="col-md-8">
                                <h3>Plant Detail</h3>
                            </div>
                            <div class="col-md-4">
                                <h3>Heat Map</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-content" style="min-height: 410px">
                        <div class="row flex-grow-1">
                            <div class="col-md-8">
                                <div class="table-wrap no-shadow">
                                    <div class="table-main plant-details-table">
                                        <table class="table-data no-bg">
                                            <body>
                                                <tr>
                                                    <td>Plant Name</td>
                                                    <td>
                                                        {{
                                                            plantDetail?.plantName
                                                        }}
                                                    </td>
                                                    <!-- <td>{{plantDetail?.plantName}}</td> -->
                                                </tr>
                                                <tr>
                                                    <td>Inverter Type</td>
                                                    <td>
                                                        <b>{{
                                                            plantDetail?.inverter_type
                                                        }}</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Designed Capacity</td>
                                                    <td>
                                                        {{
                                                            plantDetail?.capacity
                                                        }}
                                                        KW
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Daily Expected
                                                        Generation
                                                    </td>
                                                    <td>
                                                        {{
                                                            plantDetail?.todayGeneration
                                                        }}
                                                        KWH
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Plant Address</td>
                                                    <td>
                                                        {{
                                                            plantDetail?.address
                                                        }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>Installation Cost</td>
                                                    <!-- <td>{{plantDetail?.cost}}</td> -->
                                                    <td>
                                                        {{
                                                            plantDetail?.cost
                                                        }}
                                                        Mi
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            class="btn btn-warning waves-effect waves-light"
                                                            (click)="
                                                                openUpdateCostModal(
                                                                    costUpdate
                                                                )
                                                            "
                                                        >
                                                            Update Cost
                                                        </button>
                                                    </td>
                                                </tr>
                                            </body>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="heatmap">
                                    <div class="heatmap-head">
                                        <span style="color: #aff205"
                                            >Connected</span
                                        >
                                        <!-- {{this.mapConnected}} -->
                                        <!-- <span style="color: #F15F12">Offline {{heatMapList?.}} </span> -->
                                        <span style="color: #ef4323"
                                            >Disconnected
                                        </span>
                                        <span style="color: #aff205"
                                            ><b>{{ heatMapList.Connected }}</b>
                                        </span>
                                        <span style="color: #ef4323"
                                            ><b
                                                >{{ heatMapList?.Disconnected }}
                                            </b></span
                                        >
                                        <!-- <span style="color: #E9093F">Faulty <b>{{heatMapList?.Faulty}}</b> </span>
                                            <span style="color: #786f6b">Offline {{heatMapList?.Others}} </span> -->
                                    </div>
                                    <div class="heatboxes">
                                        <div
                                            class="col-1 heatbox"
                                            *ngFor="
                                                let heatitem of this.heatMapList
                                                    .data
                                            "
                                            [style.background]="
                                                getBackgroundColor(
                                                    heatitem.color
                                                )
                                            "
                                            title="{{ heatitem.devName }}"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-4 mb-3">
                <div class="card-box card-benefits h-100 mb-0">
                    <div class="card-header">
                        <h3>Environmental Benefits</h3>
                    </div>
                    <div class="card-content">
                        <div class="benefits-list">
                            <ul>
                                <li>
                                    <div class="ico">
                                        <svg
                                            id="Vector_Smart_Object"
                                            data-name="Vector Smart Object"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="63.146"
                                            height="66.016"
                                            viewBox="0 0 63.146 66.016"
                                        >
                                            <g
                                                id="Group_209"
                                                data-name="Group 209"
                                                transform="translate(0 0)"
                                            >
                                                <g
                                                    id="Group_208"
                                                    data-name="Group 208"
                                                >
                                                    <path
                                                        id="Path_179"
                                                        data-name="Path 179"
                                                        d="M31.573,4.942A31.6,31.6,0,0,0,4.492,52.777l.564.129h16.8l1.728-1.41,0-.056A3.471,3.471,0,0,1,26.917,48.2l.085,0,.03-.079a3.5,3.5,0,0,1,3.251-2.242c.048,0,.1,0,.144,0l.049,0,1.089-.888a7.228,7.228,0,0,1,9.136,0l.831.677.057-.009a3.48,3.48,0,0,1,3.921,2.567l.025.1h.1A3.492,3.492,0,0,1,49.1,51.779v.061l1.309,1.067H58.12l.534-.129A31.6,31.6,0,0,0,31.573,4.942Z"
                                                        transform="translate(0 -2.105)"
                                                        fill="#ffe5c2"
                                                    />
                                                    <path
                                                        id="Path_180"
                                                        data-name="Path 180"
                                                        d="M33.212,33.163H30.668l-.166.129v15.6l.171.129h2.533l.173-.129v-15.6Z"
                                                        transform="translate(-12.992 -14.126)"
                                                        fill="#d88a55"
                                                    />
                                                    <g
                                                        id="Group_184"
                                                        data-name="Group 184"
                                                        transform="translate(10.447 9.799)"
                                                    >
                                                        <path
                                                            id="Path_181"
                                                            data-name="Path 181"
                                                            d="M31.34,78.38l0-.056a3.471,3.471,0,0,1,3.326-3.243l.085,0,.03-.079a3.441,3.441,0,0,1,.416-.772V70.635a2.578,2.578,0,0,0-2.577-2.579H20.776A2.578,2.578,0,0,0,18.2,70.635V79.79H29.611Z"
                                                            transform="translate(-18.199 -38.788)"
                                                            fill="#596c76"
                                                        />
                                                        <path
                                                            id="Path_182"
                                                            data-name="Path 182"
                                                            d="M24.118,68.057H20.777A2.578,2.578,0,0,0,18.2,70.635V79.79h3.342V70.635A2.578,2.578,0,0,1,24.118,68.057Z"
                                                            transform="translate(-18.199 -38.788)"
                                                            fill="#465a61"
                                                        />
                                                        <g
                                                            id="Group_183"
                                                            data-name="Group 183"
                                                            transform="translate(4.188)"
                                                        >
                                                            <path
                                                                id="Path_183"
                                                                data-name="Path 183"
                                                                d="M30.5,69.431a1.439,1.439,0,1,0,2.877,0V60.563H30.5v8.868Z"
                                                                transform="translate(-27.627 -35.596)"
                                                                fill="#8d9ca8"
                                                            />
                                                            <path
                                                                id="Path_184"
                                                                data-name="Path 184"
                                                                d="M30.5,33.388h1.305v15.6H30.5Z"
                                                                transform="translate(-27.627 -24.021)"
                                                                fill="#c57a44"
                                                            />
                                                            <path
                                                                id="Path_185"
                                                                data-name="Path 185"
                                                                d="M30.5,26.3h2.877v4.071H30.5Z"
                                                                transform="translate(-27.627 -21)"
                                                                fill="#fd8087"
                                                            />
                                                            <path
                                                                id="Path_186"
                                                                data-name="Path 186"
                                                                d="M30.5,26.3h1.305v4.071H30.5Z"
                                                                transform="translate(-27.627 -21)"
                                                                fill="#fe646f"
                                                            />
                                                            <g
                                                                id="Group_181"
                                                                data-name="Group 181"
                                                            >
                                                                <path
                                                                    id="Path_187"
                                                                    data-name="Path 187"
                                                                    d="M31.58,22.5H28.034a2.541,2.541,0,0,1-2.54-2.541V18.178A1.107,1.107,0,0,1,26.6,17.07h6.414a1.107,1.107,0,0,1,1.107,1.107v1.776A2.541,2.541,0,0,1,31.58,22.5Z"
                                                                    transform="translate(-25.494 -17.07)"
                                                                    fill="#fd4755"
                                                                />
                                                            </g>
                                                            <g
                                                                id="Group_182"
                                                                data-name="Group 182"
                                                                transform="translate(1.933 1.934)"
                                                            >
                                                                <path
                                                                    id="Path_188"
                                                                    data-name="Path 188"
                                                                    d="M29.468,22a.608.608,0,0,1-.607-.607V20.44h4.761v.949a.608.608,0,0,1-.607.607Z"
                                                                    transform="translate(-28.861 -20.44)"
                                                                    fill="#ffe5c2"
                                                                />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <path
                                                        id="Path_189"
                                                        data-name="Path 189"
                                                        d="M53.582,88.271l1.481,1.207a1.242,1.242,0,0,1-.784,2.2H24.654a1.241,1.241,0,0,1-.784-2.2l1.481-1.207H7.824a31.571,31.571,0,0,0,54.162,0Z"
                                                        transform="translate(-3.333 -37.599)"
                                                        fill="#ea9b58"
                                                    />
                                                    <g
                                                        id="Group_193"
                                                        data-name="Group 193"
                                                        transform="translate(23.134)"
                                                    >
                                                        <g
                                                            id="Group_185"
                                                            data-name="Group 185"
                                                            transform="translate(13.764 22.642)"
                                                        >
                                                            <path
                                                                id="Path_190"
                                                                data-name="Path 190"
                                                                d="M67.711,42.879a11.705,11.705,0,0,0-3.435,8.459.4.4,0,0,0,.4.4,11.733,11.733,0,0,0,11.89-11.9.4.4,0,0,0-.4-.4A11.693,11.693,0,0,0,67.711,42.879Z"
                                                                transform="translate(-64.275 -39.442)"
                                                                fill="#8bc727"
                                                            />
                                                        </g>
                                                        <path
                                                            id="Path_191"
                                                            data-name="Path 191"
                                                            d="M68.783,43.916a11.683,11.683,0,0,1,7.768-3.424c.009-.217.014-.435.011-.652a.4.4,0,0,0-.4-.4,11.733,11.733,0,0,0-11.89,11.9.4.4,0,0,0,.4.4c.229,0,.458,0,.686-.013A11.7,11.7,0,0,1,68.783,43.916Z"
                                                            transform="translate(-50.511 -16.8)"
                                                            fill="#81b823"
                                                        />
                                                        <g
                                                            id="Group_186"
                                                            data-name="Group 186"
                                                            transform="translate(0 22.642)"
                                                        >
                                                            <path
                                                                id="Path_192"
                                                                data-name="Path 192"
                                                                d="M43.734,48.3a11.693,11.693,0,0,0,8.455,3.436.4.4,0,0,0,.4-.4,11.733,11.733,0,0,0-11.89-11.9.4.4,0,0,0-.4.4A11.706,11.706,0,0,0,43.734,48.3Z"
                                                                transform="translate(-40.299 -39.442)"
                                                                fill="#8bc727"
                                                            />
                                                        </g>
                                                        <path
                                                            id="Path_193"
                                                            data-name="Path 193"
                                                            d="M44.782,47.191a11.694,11.694,0,0,1-3.42-7.736c-.222-.01-.443-.015-.665-.012a.4.4,0,0,0-.4.4,11.733,11.733,0,0,0,11.89,11.9.4.4,0,0,0,.4-.4c0-.241,0-.482-.015-.723a11.682,11.682,0,0,1-7.79-3.425Z"
                                                            transform="translate(-40.299 -16.8)"
                                                            fill="#81b823"
                                                        />
                                                        <g
                                                            id="Group_189"
                                                            data-name="Group 189"
                                                            transform="translate(1.619)"
                                                        >
                                                            <g
                                                                id="Group_187"
                                                                data-name="Group 187"
                                                                transform="translate(12.053 0)"
                                                            >
                                                                <path
                                                                    id="Path_194"
                                                                    data-name="Path 194"
                                                                    d="M67.124,3.011a10.251,10.251,0,0,0-3.008,7.408.352.352,0,0,0,.348.348,10.24,10.24,0,0,0,7.4-3.009A10.251,10.251,0,0,0,74.876.349.352.352,0,0,0,74.528,0,10.24,10.24,0,0,0,67.124,3.011Z"
                                                                    transform="translate(-64.115 0)"
                                                                    fill="#8bc727"
                                                                />
                                                            </g>
                                                            <path
                                                                id="Path_195"
                                                                data-name="Path 195"
                                                                d="M68.206,4.105a10.229,10.229,0,0,1,6.649-2.989c.015-.255.025-.511.021-.767A.352.352,0,0,0,74.528,0,10.275,10.275,0,0,0,64.116,10.419a.352.352,0,0,0,.348.348c.252,0,.5-.005.755-.021A10.238,10.238,0,0,1,68.206,4.105Z"
                                                                transform="translate(-52.062 0)"
                                                                fill="#81b823"
                                                            />
                                                            <g
                                                                id="Group_188"
                                                                data-name="Group 188"
                                                                transform="translate(0 0)"
                                                            >
                                                                <path
                                                                    id="Path_196"
                                                                    data-name="Path 196"
                                                                    d="M46.127,7.757a10.24,10.24,0,0,0,7.4,3.009.352.352,0,0,0,.348-.348A10.275,10.275,0,0,0,43.467,0a.352.352,0,0,0-.348.348,10.251,10.251,0,0,0,3.008,7.408Z"
                                                                    transform="translate(-43.119 0)"
                                                                    fill="#8bc727"
                                                                />
                                                            </g>
                                                            <path
                                                                id="Path_197"
                                                                data-name="Path 197"
                                                                d="M47.212,6.66A10.239,10.239,0,0,1,44.225.022C43.973.007,43.72,0,43.467,0a.352.352,0,0,0-.348.348,10.251,10.251,0,0,0,3.008,7.408,10.24,10.24,0,0,0,7.4,3.009.352.352,0,0,0,.348-.348c0-.257-.006-.514-.021-.77A10.228,10.228,0,0,1,47.212,6.66Z"
                                                                transform="translate(-43.118 0)"
                                                                fill="#81b823"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_192"
                                                            data-name="Group 192"
                                                            transform="translate(3.498 12.761)"
                                                        >
                                                            <g
                                                                id="Group_190"
                                                                data-name="Group 190"
                                                                transform="translate(10.067 0)"
                                                            >
                                                                <path
                                                                    id="Path_198"
                                                                    data-name="Path 198"
                                                                    d="M66.443,24.744a8.562,8.562,0,0,0-2.512,6.188.294.294,0,0,0,.29.291,8.582,8.582,0,0,0,8.7-8.7.294.294,0,0,0-.29-.291A8.553,8.553,0,0,0,66.443,24.744Z"
                                                                    transform="translate(-63.93 -22.229)"
                                                                    fill="#8bc727"
                                                                />
                                                            </g>
                                                            <path
                                                                id="Path_199"
                                                                data-name="Path 199"
                                                                d="M67.5,25.779a8.543,8.543,0,0,1,5.39-2.487c.019-.257.029-.514.025-.772a.294.294,0,0,0-.29-.291,8.582,8.582,0,0,0-8.7,8.7.294.294,0,0,0,.29.291c.265,0,.53-.006.794-.027A8.552,8.552,0,0,1,67.5,25.779Z"
                                                                transform="translate(-53.863 -22.229)"
                                                                fill="#81b823"
                                                            />
                                                            <g
                                                                id="Group_191"
                                                                data-name="Group 191"
                                                                transform="translate(0 0)"
                                                            >
                                                                <path
                                                                    id="Path_200"
                                                                    data-name="Path 200"
                                                                    d="M48.906,28.708a8.553,8.553,0,0,0,6.184,2.514.294.294,0,0,0,.29-.291,8.583,8.583,0,0,0-8.7-8.7.294.294,0,0,0-.29.291,8.562,8.562,0,0,0,2.512,6.188Z"
                                                                    transform="translate(-46.393 -22.229)"
                                                                    fill="#8bc727"
                                                                />
                                                            </g>
                                                            <path
                                                                id="Path_201"
                                                                data-name="Path 201"
                                                                d="M49.981,27.584a8.552,8.552,0,0,1-2.48-5.326q-.407-.033-.817-.028a.294.294,0,0,0-.29.291,8.583,8.583,0,0,0,8.7,8.7.294.294,0,0,0,.29-.291q.006-.431-.032-.862a8.544,8.544,0,0,1-5.368-2.486Z"
                                                                transform="translate(-46.393 -22.229)"
                                                                fill="#81b823"
                                                            />
                                                        </g>
                                                        <path
                                                            id="Path_202"
                                                            data-name="Path 202"
                                                            d="M55.116,38.4V43.65a7.1,7.1,0,0,1,1.933.008V38.4l8.06-8.064a.967.967,0,0,0-1.367-1.368l-6.693,6.7V25.022L62.869,19.2A.967.967,0,0,0,61.5,17.832L57.05,22.287V14.142l7.023-7.027a.967.967,0,0,0-1.367-1.368L57.05,11.407V7.835a.967.967,0,1,0-1.933,0v3.572L49.46,5.748a.967.967,0,0,0-1.367,1.368l7.023,7.027v8.145l-4.452-4.455A.967.967,0,1,0,49.3,19.2l5.819,5.822V35.667l-6.693-6.7a.967.967,0,0,0-1.367,1.368Z"
                                                            transform="translate(-43.057 -2.328)"
                                                            fill="#78a91f"
                                                        />
                                                    </g>
                                                    <g
                                                        id="Group_197"
                                                        data-name="Group 197"
                                                        transform="translate(19.948 41.131)"
                                                    >
                                                        <path
                                                            id="Path_203"
                                                            data-name="Path 203"
                                                            d="M66.613,82.3l-2.584-2.107a3.609,3.609,0,0,0-3.592-3.583,3.609,3.609,0,0,0-4.066-2.663l-.786-.641a7.357,7.357,0,0,0-9.3,0l-1.051.857c-.049,0-.1,0-.149,0a3.609,3.609,0,0,0-3.372,2.325,3.608,3.608,0,0,0-3.449,3.363L35.257,82.3a1.37,1.37,0,0,0,.865,2.433H65.747a1.371,1.371,0,0,0,.866-2.433Z"
                                                            transform="translate(-34.75 -71.65)"
                                                            fill="#d88a55"
                                                        />
                                                        <path
                                                            id="Path_204"
                                                            data-name="Path 204"
                                                            d="M38.892,82.3,41.9,79.847a3.608,3.608,0,0,1,3.449-3.363,3.609,3.609,0,0,1,3.372-2.325c.05,0,.1,0,.149,0l1.051-.857a7.338,7.338,0,0,1,2.832-1.428,7.355,7.355,0,0,0-6.467,1.428l-1.051.857c-.049,0-.1,0-.149,0a3.609,3.609,0,0,0-3.372,2.325,3.608,3.608,0,0,0-3.449,3.363L35.258,82.3a1.37,1.37,0,0,0,.865,2.433h3.634a1.37,1.37,0,0,1-.865-2.433Z"
                                                            transform="translate(-34.75 -71.65)"
                                                            fill="#c57a44"
                                                        />
                                                        <g
                                                            id="Group_194"
                                                            data-name="Group 194"
                                                            transform="translate(8.261 7.905)"
                                                        >
                                                            <path
                                                                id="Path_205"
                                                                data-name="Path 205"
                                                                d="M51.319,85.421a2.241,2.241,0,0,0-2.161,1.649.477.477,0,0,0,.467.592h3.389a.477.477,0,0,0,.467-.592,2.242,2.242,0,0,0-2.161-1.649Z"
                                                                transform="translate(-49.141 -85.421)"
                                                                fill="#ea9b58"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_195"
                                                            data-name="Group 195"
                                                            transform="translate(21.644 8.44)"
                                                        >
                                                            <path
                                                                id="Path_206"
                                                                data-name="Path 206"
                                                                d="M74.632,86.354A2.241,2.241,0,0,0,72.471,88a.477.477,0,0,0,.467.592h3.389A.477.477,0,0,0,76.793,88,2.242,2.242,0,0,0,74.632,86.354Z"
                                                                transform="translate(-72.455 -86.354)"
                                                                fill="#ea9b58"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_196"
                                                            data-name="Group 196"
                                                            transform="translate(14.579 3.798)"
                                                        >
                                                            <path
                                                                id="Path_207"
                                                                data-name="Path 207"
                                                                d="M62.325,78.267a2.241,2.241,0,0,0-2.161,1.649.477.477,0,0,0,.467.592h3.389a.477.477,0,0,0,.467-.592,2.242,2.242,0,0,0-2.161-1.649Z"
                                                                transform="translate(-60.147 -78.267)"
                                                                fill="#f6a96c"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="Group_198"
                                                        data-name="Group 198"
                                                        transform="translate(48.068 43.581)"
                                                    >
                                                        <ellipse
                                                            id="Ellipse_70"
                                                            data-name="Ellipse 70"
                                                            cx="1.289"
                                                            cy="1.289"
                                                            rx="1.289"
                                                            ry="1.289"
                                                            fill="#d88a55"
                                                        />
                                                    </g>
                                                    <g
                                                        id="Group_199"
                                                        data-name="Group 199"
                                                        transform="translate(47.681 36.102)"
                                                    >
                                                        <ellipse
                                                            id="Ellipse_71"
                                                            data-name="Ellipse 71"
                                                            cx="1.289"
                                                            cy="1.289"
                                                            rx="1.289"
                                                            ry="1.289"
                                                            fill="#d88a55"
                                                        />
                                                    </g>
                                                    <g
                                                        id="Group_200"
                                                        data-name="Group 200"
                                                        transform="translate(42.14 39.326)"
                                                    >
                                                        <ellipse
                                                            id="Ellipse_72"
                                                            data-name="Ellipse 72"
                                                            cx="1.289"
                                                            cy="1.289"
                                                            rx="1.289"
                                                            ry="1.289"
                                                            fill="#c57a44"
                                                        />
                                                    </g>
                                                    <g
                                                        id="Group_207"
                                                        data-name="Group 207"
                                                        transform="translate(10.309 52.22)"
                                                    >
                                                        <g
                                                            id="Group_201"
                                                            data-name="Group 201"
                                                            transform="translate(9.711 4.126)"
                                                        >
                                                            <path
                                                                id="Path_208"
                                                                data-name="Path 208"
                                                                d="M39.34,100.088h-3.5a.967.967,0,0,1,0-1.934h3.5a.967.967,0,0,1,0,1.934Z"
                                                                transform="translate(-34.876 -98.154)"
                                                                fill="#f6a96c"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_202"
                                                            data-name="Group 202"
                                                            transform="translate(18.345 8.123)"
                                                        >
                                                            <path
                                                                id="Path_209"
                                                                data-name="Path 209"
                                                                d="M54.381,107.051h-3.5a.967.967,0,0,1,0-1.934h3.5a.967.967,0,0,1,0,1.934Z"
                                                                transform="translate(-49.917 -105.117)"
                                                                fill="#f6a96c"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_203"
                                                            data-name="Group 203"
                                                        >
                                                            <path
                                                                id="Path_210"
                                                                data-name="Path 210"
                                                                d="M22.424,92.9h-3.5a.967.967,0,0,1,0-1.934h3.5a.967.967,0,0,1,0,1.934Z"
                                                                transform="translate(-17.959 -90.967)"
                                                                fill="#f6a96c"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_204"
                                                            data-name="Group 204"
                                                            transform="translate(32.539 4.126)"
                                                        >
                                                            <path
                                                                id="Path_211"
                                                                data-name="Path 211"
                                                                d="M79.107,100.088h-3.5a.967.967,0,0,1,0-1.934h3.5a.967.967,0,0,1,0,1.934Z"
                                                                transform="translate(-74.643 -98.154)"
                                                                fill="#f6a96c"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_205"
                                                            data-name="Group 205"
                                                            transform="translate(4.556 4.126)"
                                                        >
                                                            <ellipse
                                                                id="Ellipse_73"
                                                                data-name="Ellipse 73"
                                                                cx="0.967"
                                                                cy="0.967"
                                                                rx="0.967"
                                                                ry="0.967"
                                                                fill="#ffe5c2"
                                                            />
                                                        </g>
                                                        <g
                                                            id="Group_206"
                                                            data-name="Group 206"
                                                            transform="translate(26.354 4.126)"
                                                        >
                                                            <ellipse
                                                                id="Ellipse_74"
                                                                data-name="Ellipse 74"
                                                                cx="0.967"
                                                                cy="0.967"
                                                                rx="0.967"
                                                                ry="0.967"
                                                                fill="#ffe5c2"
                                                            />
                                                        </g>
                                                    </g>
                                                    <path
                                                        id="Path_212"
                                                        data-name="Path 212"
                                                        d="M31.807,69.431V60.563H30.5v8.868a1.437,1.437,0,0,0,2.091,1.282,1.438,1.438,0,0,1-.786-1.282Z"
                                                        transform="translate(-12.992 -25.797)"
                                                        fill="#7a8c98"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="text-box">
                                        <p>Trees Saved</p>
                                        <div class="number">
                                            <strong
                                                >{{
                                                    evnrBanifits?.reduction_tree.toFixed(
                                                        0
                                                    )
                                                }}
                                            </strong>
                                            <span>Trees</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="ico">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="63.146"
                                            height="63.146"
                                            viewBox="0 0 63.146 63.146"
                                        >
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect
                                                        id="crop"
                                                        width="63.146"
                                                        height="63.146"
                                                        transform="translate(0.253 0.006)"
                                                        fill="none"
                                                    />
                                                </clipPath>
                                                <clipPath id="clip-path-2">
                                                    <ellipse
                                                        id="Ellipse_77"
                                                        data-name="Ellipse 77"
                                                        cx="31.886"
                                                        cy="31.667"
                                                        rx="31.886"
                                                        ry="31.667"
                                                        fill="none"
                                                    />
                                                </clipPath>
                                            </defs>
                                            <g
                                                id="Vector_Smart_Object_copy_2"
                                                data-name="Vector Smart Object copy 2"
                                                transform="translate(-0.253 -0.006)"
                                                clip-path="url(#clip-path)"
                                            >
                                                <ellipse
                                                    id="Ellipse_75"
                                                    data-name="Ellipse 75"
                                                    cx="31.886"
                                                    cy="31.667"
                                                    rx="31.886"
                                                    ry="31.667"
                                                    transform="translate(-0.576 0.163)"
                                                    fill="#f1f4f8"
                                                />
                                                <g
                                                    id="Group_240"
                                                    data-name="Group 240"
                                                    transform="translate(-0.576 0.163)"
                                                >
                                                    <g
                                                        id="Group_239"
                                                        data-name="Group 239"
                                                        clip-path="url(#clip-path-2)"
                                                    >
                                                        <g
                                                            id="Group_238"
                                                            data-name="Group 238"
                                                            transform="translate(1.914 2.179)"
                                                        >
                                                            <g
                                                                id="Group_211"
                                                                data-name="Group 211"
                                                                transform="translate(41.506 30.875)"
                                                            >
                                                                <g
                                                                    id="Group_210"
                                                                    data-name="Group 210"
                                                                >
                                                                    <ellipse
                                                                        id="Ellipse_76"
                                                                        data-name="Ellipse 76"
                                                                        cx="6.643"
                                                                        cy="6.598"
                                                                        rx="6.643"
                                                                        ry="6.598"
                                                                        fill="#70d6f9"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_213"
                                                                data-name="Group 213"
                                                                transform="translate(45.424 33.819)"
                                                            >
                                                                <g
                                                                    id="Group_212"
                                                                    data-name="Group 212"
                                                                >
                                                                    <path
                                                                        id="Path_213"
                                                                        data-name="Path 213"
                                                                        d="M87.224,68.5a.814.814,0,0,0-1.08.075l-.386.384a.108.108,0,0,1-.185-.076V65.4a.806.806,0,0,0-.71-.811.785.785,0,0,0-.864.778v3.52a.108.108,0,0,1-.185.076l-.387-.384a.814.814,0,0,0-1.08-.075.778.778,0,0,0-.057,1.157l1.939,1.925a.791.791,0,0,0,1.113,0l1.939-1.925a.778.778,0,0,0-.057-1.157Z"
                                                                        transform="translate(-82.06 -64.582)"
                                                                        fill="#edf4fc"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_215"
                                                                data-name="Group 215"
                                                                transform="translate(19.681 2.296)"
                                                            >
                                                                <g
                                                                    id="Group_214"
                                                                    data-name="Group 214"
                                                                >
                                                                    <path
                                                                        id="Path_214"
                                                                        data-name="Path 214"
                                                                        d="M65.481,10.028l-.25-.09a12.915,12.915,0,0,0-8.942,10.429,2.327,2.327,0,0,1-2.827,1.948,7.563,7.563,0,0,0-9.252,7.361,2.3,2.3,0,0,1-2.047,2.274,5.331,5.331,0,0,0-2.585,9.57H46.11a5.364,5.364,0,0,0,1.846-2.575A2.85,2.85,0,0,1,51,37.088a7.633,7.633,0,0,0,6.995-3.218,3.027,3.027,0,0,1,4.111-.835A13.013,13.013,0,0,0,79.5,29.9,21.173,21.173,0,0,0,65.481,10.028Z"
                                                                        transform="translate(-37.434 -9.937)"
                                                                        fill="#f2d6ad"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_217"
                                                                data-name="Group 217"
                                                                transform="translate(4.193 4.786)"
                                                            >
                                                                <g
                                                                    id="Group_216"
                                                                    data-name="Group 216"
                                                                >
                                                                    <path
                                                                        id="Path_215"
                                                                        data-name="Path 215"
                                                                        d="M38.225,14.253H33.858a12.811,12.811,0,0,0-4.517,8.867,1.426,1.426,0,0,1-1.879,1.269A7.556,7.556,0,0,0,17.4,32.2,1.4,1.4,0,0,1,16,33.734a5.336,5.336,0,0,0-3.266,9.611h6.53a5.325,5.325,0,0,0,2.058-3.467,1.434,1.434,0,0,1,1.775-1.151,7.463,7.463,0,0,0,2.988.13,5.387,5.387,0,0,1,4.7-5.08A2.319,2.319,0,0,0,32.85,31.5a7.563,7.563,0,0,1,9.281-7.354,2.3,2.3,0,0,0,2.794-1.927c.006-.042.012-.083.019-.125a6.794,6.794,0,0,0-6.719-7.843Z"
                                                                        transform="translate(-10.587 -14.253)"
                                                                        fill="#fce4be"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_220"
                                                                data-name="Group 220"
                                                                transform="translate(19.422 33.878)"
                                                            >
                                                                <g
                                                                    id="Group_219"
                                                                    data-name="Group 219"
                                                                >
                                                                    <g
                                                                        id="Group_218"
                                                                        data-name="Group 218"
                                                                    >
                                                                        <path
                                                                            id="Path_216"
                                                                            data-name="Path 216"
                                                                            d="M45.737,64.684H39l-2.012,27.4H47.749c-.7-9.56-1.837-25.022-2.011-27.4Z"
                                                                            transform="translate(-36.985 -64.684)"
                                                                            fill="#939393"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_222"
                                                                data-name="Group 222"
                                                                transform="translate(3.959 33.878)"
                                                            >
                                                                <g
                                                                    id="Group_221"
                                                                    data-name="Group 221"
                                                                >
                                                                    <path
                                                                        id="Path_217"
                                                                        data-name="Path 217"
                                                                        d="M18.933,64.684h-6.74l-2.012,27.4H20.944c-.7-9.56-1.837-25.022-2.012-27.4Z"
                                                                        transform="translate(-10.181 -64.684)"
                                                                        fill="#939393"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_225"
                                                                data-name="Group 225"
                                                                transform="translate(20.552 40.375)"
                                                            >
                                                                <g
                                                                    id="Group_224"
                                                                    data-name="Group 224"
                                                                >
                                                                    <g
                                                                        id="Group_223"
                                                                        data-name="Group 223"
                                                                    >
                                                                        <path
                                                                            id="Path_218"
                                                                            data-name="Path 218"
                                                                            d="M47.043,75.947H39.349l-.4,5.513h8.5Z"
                                                                            transform="translate(-38.944 -75.947)"
                                                                            fill="#b9b9b9"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_227"
                                                                data-name="Group 227"
                                                                transform="translate(5.089 40.375)"
                                                            >
                                                                <g
                                                                    id="Group_226"
                                                                    data-name="Group 226"
                                                                >
                                                                    <path
                                                                        id="Path_219"
                                                                        data-name="Path 219"
                                                                        d="M20.238,75.947H12.544l-.4,5.513h8.5Z"
                                                                        transform="translate(-12.139 -75.947)"
                                                                        fill="#b9b9b9"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_229"
                                                                data-name="Group 229"
                                                                transform="translate(26.251 0.187)"
                                                            >
                                                                <g
                                                                    id="Group_228"
                                                                    data-name="Group 228"
                                                                >
                                                                    <path
                                                                        id="Path_220"
                                                                        data-name="Path 220"
                                                                        d="M49.684,6.28a.855.855,0,1,0,.861.855A.858.858,0,0,0,49.684,6.28Z"
                                                                        transform="translate(-48.823 -6.28)"
                                                                        fill="#fc657e"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_231"
                                                                data-name="Group 231"
                                                                transform="translate(33.848)"
                                                            >
                                                                <g
                                                                    id="Group_230"
                                                                    data-name="Group 230"
                                                                >
                                                                    <path
                                                                        id="Path_221"
                                                                        data-name="Path 221"
                                                                        d="M66.637,8.181a2.8,2.8,0,0,1-2.253-2.187c0-.05-.1-.05-.1,0a2.8,2.8,0,0,1-2.253,2.187c-.051,0-.051.075,0,.1a2.8,2.8,0,0,1,2.253,2.187c0,.05.1.05.1,0a2.8,2.8,0,0,1,2.253-2.187C66.688,8.257,66.688,8.181,66.637,8.181Z"
                                                                        transform="translate(-61.993 -5.957)"
                                                                        fill="#f5c84c"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_233"
                                                                data-name="Group 233"
                                                                transform="translate(39.194 51.086)"
                                                            >
                                                                <g
                                                                    id="Group_232"
                                                                    data-name="Group 232"
                                                                >
                                                                    <path
                                                                        id="Path_222"
                                                                        data-name="Path 222"
                                                                        d="M73.607,95.41h-.394v-.375a.516.516,0,0,0-.525-.521.524.524,0,0,0-.525.5v.391h-.377a.516.516,0,0,0-.525.521.506.506,0,0,0,.525.521h.377v.375a.516.516,0,0,0,.525.521.524.524,0,0,0,.525-.5v-.391h.377a.516.516,0,0,0,.525-.521A.523.523,0,0,0,73.607,95.41Z"
                                                                        transform="translate(-71.26 -94.514)"
                                                                        fill="#87d147"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_235"
                                                                data-name="Group 235"
                                                                transform="translate(36.754 51.211)"
                                                            >
                                                                <g
                                                                    id="Group_234"
                                                                    data-name="Group 234"
                                                                >
                                                                    <path
                                                                        id="Path_223"
                                                                        data-name="Path 223"
                                                                        d="M67.588,94.731a.554.554,0,1,0,.558.554A.556.556,0,0,0,67.588,94.731Z"
                                                                        transform="translate(-67.03 -94.731)"
                                                                        fill="#f5c84c"
                                                                    />
                                                                </g>
                                                            </g>
                                                            <g
                                                                id="Group_237"
                                                                data-name="Group 237"
                                                                transform="translate(0 22.868)"
                                                            >
                                                                <g
                                                                    id="Group_236"
                                                                    data-name="Group 236"
                                                                >
                                                                    <path
                                                                        id="Path_224"
                                                                        data-name="Path 224"
                                                                        d="M6.551,47.15a1.967,1.967,0,0,1-1.569-1.533c0-.025-.076-.025-.076,0A1.967,1.967,0,0,1,3.337,47.15c-.025,0-.025.05,0,.075a1.967,1.967,0,0,1,1.569,1.533c0,.025.076.025.076,0a1.967,1.967,0,0,1,1.569-1.533C6.577,47.2,6.577,47.15,6.551,47.15Z"
                                                                        transform="translate(-3.318 -45.598)"
                                                                        fill="#70d6f9"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="text-box">
                                        <p>CO2 Reduction</p>
                                        <div class="number">
                                            <strong
                                                >{{
                                                    evnrBanifits?.reduction_co2.toFixed(
                                                        2
                                                    )
                                                }}
                                            </strong>
                                            <span>Tons</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="ico">
                                        <img
                                            src="assets/images/icon.png"
                                            alt=""
                                        />
                                    </div>
                                    <div class="text-box">
                                        <p>Coal Saved</p>
                                        <div class="number">
                                            <strong
                                                >{{
                                                    evnrBanifits?.reduction_coal.toFixed(
                                                        2
                                                    )
                                                }}
                                            </strong>
                                            <span>Tons</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="benefit-spans">
                                <span
                                    ><i style="background: #78a91f"></i>Total
                                    Trees Planted: &nbsp;{{
                                        evnrBanifits?.reduction_tree.toFixed(0)
                                    }}</span
                                >
                                <span
                                    ><i style="background: #939393"></i>Total
                                    CO<sub>2</sub> &nbsp; ER: &nbsp;
                                    {{
                                        evnrBanifits?.reduction_co2.toFixed(2)
                                    }}</span
                                >
                                <span
                                    ><i style="background: #d7df27"></i>Total
                                    CO<sub>2</sub> &nbsp; R: &nbsp;
                                    {{
                                        evnrBanifits?.reduction_coal.toFixed(2)
                                    }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 mb-3">
                <!-- <div class="card-box">
                        <div class="card-header">
                            <h3>Day Wise</h3>
                        </div> -->
                <div class="card-content card-box h-100 mb-0">
                    <!-- <div class="col-md-12"> -->

                    <div class="" *ngIf="dayWisePG">
                        <div *ngIf="!loader">
                            <div class="card-header">
                                <h3>Day Wise</h3>
                            </div>
                            <div class="legend-holder">
                                <div
                                    class="legend-container"
                                    *ngFor="let legend of legends"
                                >
                                    <div
                                        class="legend-color"
                                        [style.backgroundColor]="legend.color"
                                    ></div>
                                    <div class="legend-text">
                                        {{ legend.name }}
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="d-flex justify-content-end m-3">
                                <input
                                    type="date"
                                    [ngModel]="dtEnv"
                                    (ngModelChange)="dateClearEnv($event)"
                                />
                            </div> -->
                            <div class="d-flex justify-content-end w-100 pr-5">
                                <div class="bs-datepicker datepicker-container">
                                    <div class="cal-icon">
                                        <input
                                            [(ngModel)]="selectedDay"
                                            autocomplete="off"
                                            class="form-control"
                                            name="date"
                                            bsDatepicker
                                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                                            (onShown)="onDayOpenCalendar($event)"
                                            (ngModelChange)="dayWiseCheck($event)"
                                        />
                                    </div>
                                </div>
                            </div>                            

                            <div
                                class="card-body time-wise-graph"
                                *ngIf="!showhistory"
                            >
                                <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="blockOption"
                                    style="
                                        width: 100%;
                                        height: 304px;
                                        display: block;
                                        margin: 0px 0px 0px;
                                    "
                                >
                                </highcharts-chart>
                            </div>
                            <div
                                class="card-body noRecordFound time-wise-graph"
                                *ngIf="showhistory"
                            >
                                No Record Found
                            </div>
                        </div>

                        <div
                            class="card-body"
                            style="height: 520px"
                            *ngIf="loader"
                        >
                            <div class="loader"></div>
                        </div>
                    </div>
                    <div class="" *ngIf="yearWisePG">
                        <div *ngIf="!loader">
                            <div class="card-header">
                                <h3>Year Wise</h3>
                            </div>
                            <div class="legend-holder">
                                <div
                                    class="legend-container"
                                    *ngFor="let legend of legends"
                                >
                                    <div
                                        class="legend-color"
                                        [style.backgroundColor]="legend.color"
                                    ></div>
                                    <div class="legend-text">
                                        {{ legend.name }}
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="d-flex justify-content-end w-100 pr-5">
                                <div class="bs-datepicker datepicker-container">
                                    <div class="cal-icon">
                                        <input
                                        [(ngModel)]="selectedYear"
                                        autocomplete="off"
                                        class="form-control"
                                        name="date"
                                        bsDatepicker
                                        [bsConfig]="{
                                            dateInputFormat: 'YYYY'
                                        }"
                                        (onShown)="onOpenCalendar($event)"
                                        (ngModelChange)="
                                            yearWiseCheck(selectedYear)
                                        "
                                    />
                                    </div>
                                </div>
                            </div> -->
                            <div class="d-flex justify-content-end w-100 pr-5">
                                <div class="bs-datepicker datepicker-container">
                                  <div class="cal-icon">
                                    <input
                                      [(ngModel)]="selectedYear"
                                      autocomplete="off"
                                      class="form-control"
                                      name="date"
                                      bsDatepicker
                                      [bsConfig]="{ dateInputFormat: 'YYYY' }"
                                      (onShown)="onOpenCalendar($event)"
                                      (ngModelChange)="yearWiseCheck(selectedYear)"
                                    />
                                  </div>
                                </div>
                              </div>                              
                            <div
                                class="card-body time-wise-graph"
                                style="margin-bottom: 3px !important"
                            >
                                <div class="actualGen-column">
                                    <highcharts-chart
                                        [Highcharts]="Highcharts"
                                        [options]="barChart"
                                        style="
                                            width: 100%;
                                            width: 100%;
                                            height: 304px;
                                            display: block;
                                            margin: 30px 0px 0px;
                                        "
                                    >
                                    </highcharts-chart>
                                </div>
                            </div>
                        </div>

                        <div
                            class="card-body"
                            style="height: 520px"
                            *ngIf="loader"
                        >
                            <div class="loader"></div>
                        </div>
                    </div>
                    <!-- Life Time chart -->
                    <!-- <div class="" *ngIf="lifetTimeWisePG">
                                    <div *ngIf="!loader">
                                        <div class=" card-header ">
                                            <h3>Life Time</h3>
                                        </div>
                                        <div class="card-body time-wise-graph" style="margin-bottom: 3px !important;">
                                            <div class="actualGen-column">
                                                <highcharts-chart [Highcharts]="Highcharts" [options]="barChart" style="
                                                        width: 100%;
                                                        width: 100%;
                                                    height: 304px;
                                                    display: block;
                                                    margin: 30px 0px 0px;
                                                    ">
                                                </highcharts-chart>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="card-body" style="height: 520px" *ngIf="loader">
                                        <div class="loader"></div>
                                    </div>
                                </div> -->
                    <!-- card card-animate dayWiseGraph -->
                    <div class="" *ngIf="monthWisePG">
                        <div *ngIf="!loader">
                            <div class="card-header">
                                <h3>Month Wise</h3>
                            </div>
                            <div class="legend-holder">
                                <div
                                    class="legend-container"
                                    *ngFor="let legend of legends"
                                >
                                    <div
                                        class="legend-color"
                                        [style.backgroundColor]="legend.color"
                                    ></div>
                                    <div class="legend-text">
                                        {{ legend.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="card-body time-wise-graph">
                                <!-- <div class="d-flex justify-content-end w-100">
                                    <input
                                        type="date"
                                        [ngModel]="dtEnv"
                                        (ngModelChange)="monthWIseCheck($event)"
                                    />
                                </div> -->
                                <div class="d-flex justify-content-end w-100 pr-5">
                                    <div class="bs-datepicker datepicker-container">
                                      <div class="cal-icon">
                                        <input
                                          [(ngModel)]="selectedMonth"
                                          autocomplete="off"
                                          class="form-control"
                                          name="date"
                                          bsDatepicker
                                          [bsConfig]="{ dateInputFormat: 'MM/YYYY' }"
                                          (onShown)="onMonthOpenCalendar($event)"
                                          (ngModelChange)="monthWiseCheck(selectedMonth)"
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div class="actualGen-column">
                                    <highcharts-chart
                                        [Highcharts]="Highcharts"
                                        [options]="barChart"
                                        style="
                                            width: 100%;
                                            width: 100%;
                                            height: 304px;
                                            display: block;
                                            margin: 30px 0px 0px;
                                        "
                                    >
                                    </highcharts-chart>
                                </div>
                            </div>
                        </div>
                        <div
                            class="card-body"
                            style="height: 520px"
                            *ngIf="loader"
                        >
                            <div class="loader"></div>
                        </div>
                    </div>

                    <!-- <div class="tabMonthYear d-flex justify-content-center">
                                    <button type="button" class="btn btn-dark mr-2" (click)="fiveMinData()">
                                        Day
                                    </button>
                                    <button type="button" class="btn btn-dark mr-2" (click)="monthChartInfo()">
                                        Month
                                    </button>
                                    <button type="button" class="btn btn-dark" (click)="yearChartInfo()">
                                        Year
                                    </button>
                                </div> -->
                    <div
                        class="tabMonthYear btn-group btn-group-sm mt-2 d-flex justify-content-center"
                        role="group"
                        aria-label="Basic example"
                    >
                        <button
                            type="button"
                            class="btn btn-warning waves-effect waves-light"
                            (click)="fiveMinData()"
                        >
                            Day
                        </button>
                        <button
                            type="button"
                            class="btn btn-warning waves-effect waves-light"
                            (click)="monthChartInfo()"
                        >
                            Month
                        </button>
                        <button
                            type="button"
                            class="btn btn-warning waves-effect waves-light"
                            (click)="yearChartInfo()"
                        >
                            Year
                        </button>
                        <!-- <button type="button" class="btn btn-warning   waves-effect waves-light" (click)="lifeTimeChartInfo()">Life Time</button> -->
                    </div>
                    <!-- </div> -->
                    <!-- <div class="plugin"><img src="assets/images/graph.png"></div> -->
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</section>

<ng-template #costUpdate let-c="close" let-d="dismiss" let-width="400">
    <form (ngSubmit)="updateInstallationCost()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Installation Cost Update
            </h4>
            <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="d('Cross click')"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.633"
                    height="14.25"
                    viewBox="1222.583 108.567 13.633 14.25"
                >
                    <g data-name="Group 2829">
                        <g data-name="chevron-back">
                            <path
                                d="m1224 110 5.393 5.393 5.393-5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                        <g data-name="chevron-back">
                            <path
                                d="m1224 121.393 5.393-5.393 5.392 5.393"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                stroke-width="2"
                                stroke="#fff"
                                fill="transparent"
                                data-name="Path 5498"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-styles">
                    <label for="typeahead-basic"
                        >Update Installation Cost:</label
                    >
                    <input
                        id="typeahead-basic"
                        type="text"
                        class="form-control"
                        placeholder="0.0"
                        [(ngModel)]="plantDetail.cost"
                        name="updateCost"
                        appNumberInput
                    />
                </div>
            </div>
        </div>

        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click')"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-orange"
                (click)="c('Save click')"
                type="submit"
            >
                Save
            </button>
        </div>
    </form>
</ng-template>
