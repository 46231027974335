import { NocUser } from "@/Interface/nocUser";

export class NocUserModel implements NocUser{
    user_id: number;
    username: string


    constructor(values:Object = {}){
      Object.assign(this,values);
    }
    getUserId(){
        return this.user_id;
    }
    getusername(){
        return this.username;
    }
}