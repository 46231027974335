
import {Category} from '../Interface/category'

export class CategoryModel implements Category{

    id?: number;
    category_name?: number;
    source_name?: string;
    source_id:number;


    constructor(values:object={}){
    Object.assign(this,values)
    }
    getId(){
        return this.id;
    }
    getName(){
        return this.category_name;
    }
    getSource(){
        return this.source_name;
    }
    getSourceId(){
        return this.source_id;
    }
}