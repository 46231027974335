import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { login, Plants } from '@/Interface/register';
import { map, catchError } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';



@Injectable({
    providedIn: 'root'
})

export class ApiService {
    getauth: string = '';
    authTokan: AuthToken | null = null; 
    decodeAuth() {
        //debugger;
        this.getauth = localStorage.getItem('token');
         if (this.getauth) {
            return this.authTokan = jwt_decode(this.getauth);
         }
    }

    decodeAuth2() {
        //debugger;
        this.getauth = localStorage.getItem('tokan');
         if (this.getauth) {
             this.authTokan = jwt_decode(this.getauth);
             this.authTokan.user_id;

             localStorage.setItem('userId', this.authTokan.user_id.toString());
            return this.authTokan;
         }
    }

    


}


interface AuthToken {
    exp: number;
    firstname: string;
    iat: number;
    jti: string;
    noc_user: boolean;
    role: string;
    stationCodes: string[];
    superuser: boolean;
    token_type: string;
    user_id: number;
    username: string;
  }
