<!-- <video autoplay muted loop id="myVideo">
  <source src="assets/img/blue.mp4" type="video/mp4">
</video>


  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card    bg-vicious-stance text-white" style="border-radius: 1rem;">
          <div class="card-body p-4 text-center ">
            <div class="mb-md-5 mt-md-4">
              <div class="login_area_vt">
                <div class="alert alert-danger" role="alert" *ngIf="submitted">
                  The username or password is incorrect!
                </div>
                <div class="text-center in-down w-25 mx-auto"><img class="w-100 " src="assets/img/zerocarbon-logo-new.png" alt=""></div>
                 <h3 class="fw-bold mb-2 ">Welcome, Let's get Started!</h3> 
                
                <form class=" gradient-custom" #applicationForm="ngForm"  (ngSubmit)="saveUser(applicationForm)" >
                 
                <div class="form-outline form-white mb-4"> 
                 
                  <input  type="text" class="form-control  " name="username"   
                    placeholder="Enter your username" [(ngModel)]="user.username" #username="ngModel" required/>
                  
                  <div>
                    <div class="has-danger text-left text-danger"
                        *ngIf="username.errors && (username.dirty || username.touched)">
                        Username is a required field
                         
                    </div>
                </div>

                </div>
                <div class="form-outline form-white mb-4"> 
                  <input name="password" type="password" autocomplete="on" class="form-control "    [(ngModel)]="user.password" #password="ngModel"
                    placeholder="Enter your password" required />
                    
                  <div>
                    <div class="has-danger text-left text-danger" *ngIf="password.errors && (password.dirty || password.touched  )">
                        Password is a required field
                        
                    </div>
                </div>

                </div>
                
                <button [disabled]="!applicationForm.valid" class="btn btn-outline-light  px-5"  type="submit" >Login</button>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   -->
  <div class="toplogo">
    <a   [routerLink]="['/']"><span><img src="assets/img/logo-zc.png" alt=""></span></a>
</div>
  <div class="auth-page-wrapper auth-bg-cover resetPasswordBlock py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
   
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card-box">
                        <div class="card-header text-center justify-content-center">
                            <h3>Reset Password</h3>
                        </div>
                        <div class="row g-0">
                         

                            <div class="col-lg-6 mx-auto">
                                <div class="p-lg-5 p-4">
                                     
                                    <div class="alert alert-danger" role="alert" *ngIf="submitted">
                                        The  password is incorrect!
                                    </div>
                                    <div class="mt-4">
                                        <div  >
                                            <form class="gradient-custom" #cpassword="ngForm" (ngSubmit)="
                                                    changePass(cpassword)
                                                ">
                                                <div class="mb-4">
                                                    <label for="username" class="form-label">New Password</label>
                                                    <input type="password" class="form-control" placeholder="Enter password"
                                                        name="password" [(ngModel)]="
                                                        ChangePassword.password
                                                        " #password="ngModel" required />
                                                    <div>
                                                        <div class="has-danger text-left text-danger" *ngIf="
                                                        password.errors &&
                                                                (password.dirty ||
                                                                password.touched)
                                                            ">
                                                            Password is a
                                                            required field
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-4">
                                                   
                                                    <label class="form-label">Confirm Password</label>
                                                    <div class="position-relative auth-pass-inputgroup mb-3">
                                                        <input type="password" name="cpassword" class="form-control pe-5"
                                                            [(ngModel)]="
                                                            ChangePassword.confirm_password
                                                            " #cpassword="ngModel" placeholder="Enter Confirm Password"
                                                            required />
                                                        <div class="has-danger text-left text-danger" *ngIf="
                                                        cpassword.errors &&
                                                                (cpassword.dirty ||
                                                                cpassword.touched)
                                                            ">
                                                            Confirm Password is a
                                                            required field
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                <div class="mt-4">
                                                    <button class="btn btn-success btnPrimary w-100" [disabled]="
                                                            !cpassword.valid
                                                        " type="submit">
                                                        Reset
                                                    </button>
                                                </div>

                                                 
                                            </form>
                                        </div>

                                         
                                    </div>
 
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!-- end row -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="mb-0">
                            &copy; 2023 Tower Technologies (Pvt) Ltd. All rights
                            reserved
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end Footer -->
</div>