import { Category } from '@/Interface/category';
import { SubCat } from '@/Interface/subcategory';
import { CategoryModel } from '@/model/catlist';
import { SubCategoryModel } from '@/model/subcategory';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '@services/utility.service';
import Swal from "sweetalert2";
@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {
  getcat: Category;
  getSubcat: SubCat;
  getSubcat2: SubCat;
  catid:number;
  getcatadd = new CategoryModel();
  getSubcatadd = new SubCategoryModel();
  getSubcatUpdate = new SubCategoryModel();
  addSubCategoryForm: FormGroup;
  updateSubCategoryForm: FormGroup;
  constructor(
    private modalService: NgbModal,
    private _utilityService: UtilityService,
    private formBuilders: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getSubCategory();
    this.getCategorylist();
    this.addSubCategoryForm = this.formBuilders.group({
      subCatName: new FormControl(null, Validators.required),
      catName: new FormControl(null, Validators.required)
    });
    this.updateSubCategoryForm = this.formBuilders.group({
      subCatName1: new FormControl(null, Validators.required),
      catName1: new FormControl(null, Validators.required)
    });
  }

  getSubCategory() {
    this._utilityService.sendSubCat().subscribe((res) => {
      if (res) {
        this.getSubcat = res;
       }
    });
  }
  getCategorylist(){
    this._utilityService.sendCat().subscribe((res) => {
      if (res) {
        this.getcat = res;
        }
    });
  }
  open(item: SubCat, contentupdate: any) {
    this.getSubcatadd = null;
    this.catid = item.id;
    this._utilityService.receiveSubCatDate(this.catid).subscribe((res) => {
      if (res) {
        this.getSubcat2 = res[0];
        this.updateSubCategoryForm.patchValue({
          subCatName1: this.getSubcat2.getSubCategoryName(),
          catName1: this.getSubcat2.getCategoryid()
        });
      }
    });
    this.modalService.open(contentupdate);
  }

  addSubCategory(content: any) {
    this.modalService.open(content);
  }
  submitSubCategory() {
 
    this.getSubcatadd.category_id = this.addSubCategoryForm.value.catName;
    this.getSubcatadd.sub_category_name =
      this.addSubCategoryForm.value.subCatName;
    this._utilityService.addSubCat(this.getSubcatadd).subscribe((res) => {
      if (res) {
        this.getSubCategory();
        
        this.addSubCategoryForm.reset();
      

       
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '`<h2>Sub Category Successfully Added</h2>`',
          showConfirmButton: false,
          timer: 1500
        })
      }
    });
  }

  updatesubmitSubCategory() {
   
    this.getSubcatUpdate.id = this.catid;
    this.getSubcatUpdate.category_id = this.updateSubCategoryForm.value.catName1;
    this.getSubcatUpdate.sub_category_name =
      this.updateSubCategoryForm.value.subCatName1;
    this._utilityService.addSubCat(this.getSubcatUpdate).subscribe((res) => {
      if (res) {
        this.getSubCategory();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '`<h2>Sub Category  Successfully Updated</h2>`',
          showConfirmButton: false,
          timer: 1500
        })
       }
    });
  }
  deleteSubCategory(item: SubCat) {
    var deleteSubCat = item.id;

    Swal.fire({
      title: 'Are you sure to delete this Sub-Category?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        this._utilityService
        .deleteSubCategory(deleteSubCat)
        .subscribe((res) => {
          if (res) {
            this.getSubCategory();
          }
        });
        Swal.fire(
          'Deleted!',
          'Sub-Category has been deleted.',
          'success'
        )
      }
    })











    
  




  }
}
