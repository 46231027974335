<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Email</h4>
    <button type="button" class="btn-close" aria-label="Close">
      <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
          <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
      </svg>
    </button>
</div>
<div class="modal-body">
    <!-- #e="ngForm" (ngSubmit)="changePasswordsubmit()" -->
    <form>
        <div class="userEditForm form-styles">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label>To</label>
                <ng-select>
                  <ng-option>Faisal Akbar</ng-option>
              </ng-select> 
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
                <label>From</label>
                <ng-select>
                  <ng-option>noc@zerocarbon.com.pk</ng-option>
              </ng-select> 
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <label for="address">Description</label>
                <textarea id="address" class="form-control" cols="30" rows="10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vulputate diam efficitur ornare maximus. Curabitur vestibulum, urna quis porta lacinia, ligula enim malesuada orci, vitae convallis nisl lectus vestibulum ipsum. Sed sed suscipit augue, quis vehicula eros. Donec odio est, lobortis quis tincidunt eget, consequat nec lorem. Mauris vel eros eu dui lacinia porttitor. Maecenas fermentum tincidunt turpis ac dictum. Maecenas vel gravida purus. Quisque rutrum porttitor turpis sed fringilla.</textarea>
            </div>
          </div>
        </div>
    </form>
</div>
<div class="modal-footer no-bg">
    <!--  -->
    <button class="btn btn-outline-dark" type="button">Cancel</button>
    <!-- [disabled]="loading" -->
    <button class="btn btn-orange " type="submit">Save</button>
    <!-- [disabled]="!e.valid" -->
</div>
