<div class="emiChart">
    <!-- <div class="row d-flex justify-content-between">
        <div class="col-md-4 d-flex justify-content-center">
            <div class="d-flex justify-content-between align-items-center">
                <div class="col-md-9">
                    <ng-select [multiple]="true" [(ngModel)]="selectedCars">
                        <ng-option *ngFor="let pvGlist of pvG" [value]="pvGlist.id" [disabled]="pvGlist.disabled">{{
                            pvGlist.name }}</ng-option>
                     </ng-select>
                </div>
                <div class="col-md-3">
                     <button type="button" class="btn btn-secondary btn-sm"(click)="toggleDisabled()" >
                        Search
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-4 d-flex justify-content-center">
            <input type="date" />
        </div>

   </div> -->



    <div class="row d-flex  ">
        <div class="col-md-7  py-3 pl-5">
            <div class="row d-flex  align-items-center ">
                <div class="col-md-6 pr-0">
                    <ng-select [multiple]="true" [(ngModel)]="selectedCars">
                        <ng-option *ngFor="let pvGlist of pvG" [value]="pvGlist.id" [disabled]="pvGlist.disabled">{{
                            pvGlist.name }}</ng-option>
                     </ng-select>
                </div>
                <!-- (change)="openDate(gDate)"  -->
                <!-- [(ngModel)]="gDate" -->
                <div class="col-md-3  pr-0">
                    <input type="date" class="CurrentDate" [(ngModel)]="gDate"  (change)="openDate(gDate)" />
                </div>
                <div class="col-md-1 pl-0">
                    <button type="button" class="btn btn-secondary btn-sm serchBtn" (click)="toggleDisabled()">
                        Search
                    </button>
                </div>
            </div>
        </div>
       
    </div>
    <div class="row">
        <div class="col-md-12">
            <!-- <highcharts-chart [Highcharts]="Highcharts" *ngIf="pvlenght" [options]="blockOption" style="
                    width: 100%;
                    height: 400px;
                    display: block;
                    margin: 30px 0px;
                    background-color: #fcffc5;
                ">
            </highcharts-chart>

 
<ng-template #name></ng-template>

            <highcharts-chart [Highcharts]="Highcharts" *ngIf="!pvlenght" [options]="blockOption2" style="
            width: 100%;
            height: 400px;
            display: block;
            margin: 30px 0px;
            background-color: #fcffc5;
        ">
    </highcharts-chart> -->



    <ng-template #chart1>
        <highcharts-chart [Highcharts]="Highcharts" [options]="blockOption" style="
        width: 100%;
        height: 400px;
        display: block;
        margin: 30px 0px;
        background-color: #fcffc5;"></highcharts-chart>
      </ng-template>
      
      <ng-template #chart2>
        <highcharts-chart [Highcharts]="Highcharts" [options]="blockOption2" style="
        width: 100%;
        height: 400px;
        display: block;
        margin: 30px 0px;
        background-color: #fcffc5;"></highcharts-chart>
      </ng-template>
      
      <ng-container *ngIf="pvlenght; else chart2">
        <ng-container *ngTemplateOutlet="chart1"></ng-container>
      </ng-container>
      <!-- *ngIf="loading"  -->
      <div class="loading-outer"*ngIf="loading"  >
        <div class="loading">
            <img class="w-100" src="../../../assets/images/loading.gif" />
        </div>
        <div class="overlayLoading">

        </div>
      </div>
      
        </div>
    </div>
</div>