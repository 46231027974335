
<!-- <div class="loaderwrapper" *ngIf="this.tableLoader">
    <span class="loader" ></span> 
</div> -->
<div *ngIf="!showPlantDetail">a

<section class="dashboard-main container-fluid">
    <div class="breadcrumb-main"> 
        <ul class="breadcrumb">
            <li><a href="./">Plants</a></li>
            <li class="skip-arrow">Plants List</li>
        </ul>
    </div>
    <div class="contents-main">
        <div class="row mb-3">               
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Search</label>
                    <input class="form-control searchTerm" type="text" name="plantName" (input)="searchData($event)" placeholder="Enter Plant Name to search" />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Plant Status</label>
                    <ng-select  (change)="selctPlantlistStatus($event)">
                        <ng-option *ngFor="let sourceItem of plantStatuslst" [value]="sourceItem.id">{{sourceItem.name}}</ng-option>
                    </ng-select>  

                <!-- <small class="text-danger" *ngIf="
                !addTocketForm?.get('sourceName').valid &&
                addTocketForm?.get('sourceName').touched
            ">Please Enter Source</small>   -->
                
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Plant Type</label>
                    <ng-select (change)="selctPlantlistType($event)">
                    <ng-option *ngFor="let sourceItem of plantTypelst" [value]="sourceItem.id">{{sourceItem.name}}</ng-option>
                </ng-select>   
                <!-- <small class="text-danger" *ngIf="
                !addTocketForm?.get('sourceName').valid &&
                addTocketForm?.get('sourceName').touched
            ">Please Enter Source</small>   -->
                
                </div>
            </div>
        </div>
        <div class="loaderwrapper" *ngIf="tableLoader">
          <span class="loader" ></span> 
      </div>

        <div class="row col-12">
          <label class="show-entries-list">
            Show
            <!-- (change)="changePageSize()" -->
            <!-- [formControl]="pageSizeControl" -->
            <select [formControl]="pageSizeControl" (change)="changePageSize()"
              class="form-control form-control-sm">
              <option *ngFor="let limit of pageRecordOptions" [value]="limit.value">{{limit.label}}</option>
            </select>
            Entries
          </label>
        </div>
        <div class="table-wrap">
        <div class="table-main">
            <ngx-datatable
            class="material"
            [rows]="detail"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="0"
            [rowHeight]="'auto'"
            (sort)="onSort($event)"
            >
            <!-- Status Column -->
            <ngx-datatable-column name="Status" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span *ngIf="row.status === 'Online'" class="status-circle-online" title="Online"></span>
                <span *ngIf="row.status === 'Offline'" class="status-circle-offline" title="Offline"></span>
                <span *ngIf="row.status === 'Faulty'" class="status-circle-faulty" title="Faulty"></span>
            </ng-template>
            </ngx-datatable-column>

            <!-- Plant Column -->
            <!-- <ngx-datatable-column name="Plant Name" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span class="cursor-pointer">
                <a [routerLink]="" (click)="plantDetail(row)">{{ row.plantName }}</a>
                </span>
            </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column name="Plant Name" [sortable]="false">
              <ng-template ngx-datatable-header-template>
                <div class="custom-sort-header d-flex">
                  <span class="cursor-pointer" (click)="sortPlants('plantName')">
                      Plant Name
                  </span>
                  <span class="sort-btn"
                            [ngClass]="{ 'datatable-icon-sort-unset': sortOrderPlantName === null, 'datatable-icon-up': sortOrderPlantName === 'asc', 'datatable-icon-down': sortOrderPlantName === 'desc' }"></span>
                </div>
                
              </ng-template>
              <ng-template ngx-datatable-cell-template let-row="row">
                  <span class="cursor-pointer">
                      <a [routerLink]="" (click)="plantDetail(row)">{{ row.plantName }}</a>
                  </span>
              </ng-template>
          </ngx-datatable-column>       
          

            <!-- <ngx-datatable-column name="Capacity" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.capacity }}
                </ng-template>
              </ngx-datatable-column> -->

              <ngx-datatable-column name="Capacity" [sortable]="false">
                <ng-template ngx-datatable-header-template>
                <div class="custom-sort-header d-flex">
                    <span class="cursor-pointer" (click)="sortPlants('capacity')">
                        Capacity (kWp)
                      </span>
                        <span class="sort-btn" style="padding-top: 8px;"
                              [ngClass]="{ 'datatable-icon-sort-unset': sortOrderCapacity === null, 'datatable-icon-up': sortOrderCapacity === 'asc', 'datatable-icon-down': sortOrderCapacity === 'desc' }"></span>
                  </div> 
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row">
                    <span>{{ row.capacity }}</span>
                </ng-template>
            </ngx-datatable-column>
              
              <!-- Current Power Column -->
              <ngx-datatable-column name="Current Power (kW)" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.currentPower }}
                </ng-template>
              </ngx-datatable-column>

              <!-- Specific Yield Column -->
                <ngx-datatable-column name="Specific Yield (kWh/kWp)" prop="specificYield" [sortable]="true">
                    <ng-template ngx-datatable-cell-template let-row="row">
                    {{ row.specificYield }}
                    </ng-template>
                </ngx-datatable-column>
                
                <!-- Energy Today Column -->
                <ngx-datatable-column name="Todays Generation (kWh)" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                    {{ row.energyToday }}
                    </ng-template>
                </ngx-datatable-column>
                
                <!-- Data Delay Column -->
                <ngx-datatable-column name="Data Delay" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                    {{ row.dataDelay }}
                    </ng-template>
                </ngx-datatable-column>
                
                <!-- Availability Chart Column -->
                <ngx-datatable-column name="Availability" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="AvailabilityPower[row.stationCode]" style="
                        width: 80px;
                        height: 60px;
                        display: block;
                        margin: 0px 0px 0px;
                    "></highcharts-chart>
                    </ng-template>
                </ngx-datatable-column>
                
                <!-- Normalized Power Chart Column -->
                <ngx-datatable-column name="Normalized Power" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="NormalizedPower[row.stationCode]" style="
                        width: 80px;
                        height: 60px;
                        display: block;
                        margin: 0px 0px 0px;
                    "></highcharts-chart>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Bell Icon 1" [sortable]="false">
                    <ng-template ngx-datatable-header-template>
                      <svg id="bell-solid-badged" xmlns="http://www.w3.org/2000/svg" width="22.434" height="23.369" viewBox="0 0 22.434 23.369">
                        <path id="Path_5473" data-name="Path 5473" d="M22.308,44.268a1.875,1.875,0,0,0,1.812-1.6H20.427a1.875,1.875,0,0,0,1.882,1.6Z" transform="translate(-11.811 -20.899)" fill="#fff"/>
                        <path id="Path_5474" data-name="Path 5474" d="M24.991,20.873l-.239-.211A10.09,10.09,0,0,1,22.99,18.6a8.868,8.868,0,0,1-.948-3.377V11.758A7.668,7.668,0,0,0,21.93,10.5a5.266,5.266,0,0,1-4.206-5.168V4.891a7.422,7.422,0,0,0-2.331-.7V3.3a.937.937,0,1,0-1.875,0v.92a7.591,7.591,0,0,0-6.53,7.534v3.469A8.868,8.868,0,0,1,6.04,18.6a10.111,10.111,0,0,1-1.734,2.057l-.239.211v1.98H24.991Z" transform="translate(-4.067 -1.822)" fill="#fff"/>
                        <ellipse id="Ellipse_1427" data-name="Ellipse 1427" cx="3.511" cy="3.511" rx="3.511" ry="3.511" transform="translate(15.413 0)" fill="#fff"/>
                      </svg>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row" >
                        <span class="text-notify">{{ row.majorAlarms }}</span>
                      </ng-template>
                </ngx-datatable-column>
                  
                <ngx-datatable-column name="Bell Icon 2" [sortable]="false">
                    <ng-template ngx-datatable-header-template>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.478" height="24.523" viewBox="0 0 22.478 24.523">
                        <g id="Group_2315" data-name="Group 2315" transform="translate(0 0)">
                          <path id="Path_5475" data-name="Path 5475" d="M27.9,23.035l-.256-.226A10.839,10.839,0,0,1,25.75,20.6a9.527,9.527,0,0,1-1.018-3.628V13.244a8.154,8.154,0,0,0-7.12-8.109V4.163a1.007,1.007,0,1,0-2.014,0v.988A8.154,8.154,0,0,0,8.56,13.244V16.97A9.527,9.527,0,0,1,7.542,20.6a10.862,10.862,0,0,1-1.863,2.21l-.256.226v2.127H27.9Z" transform="translate(-5.422 -3.156)" fill="#fff"/>
                          <path id="Path_5476" data-name="Path 5476" d="M27.235,56.889a2,2,0,0,0,3.96,0Z" transform="translate(-17.98 -34.09)" fill="#fff"/>
                        </g>
                      </svg>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <span class="text-notify2">{{ row.minorAlarms }}</span>
                      </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Inverter Status" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <span class="nowrap text-center status-list">
                        <span class="square-status" style="background: #00D54E">{{ row.inverterState.connected }}</span>
                        <span class="square-status" style="background: #E9093F">{{ row.inverterState.faulty }}</span>
                        <span class="square-status" style="background: #848177">{{ row.inverterState.disconnected }}</span>
                      </span>
                    </ng-template>
                  </ngx-datatable-column>                  
                  
                  <!-- Active Work Orders Column -->
                  <ngx-datatable-column name="Active Work Orders" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      {{ row.activeWorkOrders }}
                    </ng-template>
                  </ngx-datatable-column>
                  
                  <!-- Specific Yield Yesterday Column -->
                  <ngx-datatable-column name="Specific Yield Yesterday" [sortable]="true">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      {{ row.specificYieldYesterday.toFixed(2) }}
                    </ng-template>
                  </ngx-datatable-column>

            </ngx-datatable>
            <div class="table-meta">
                <div class="entries">
                    <!-- <span>Show</span>
                    <select>
                        <option>12</option>
                    </select> -->
                    <span>Total Records: {{this.total_items }}</span>               
                </div>
                <!-- <nav class="table-pagination">
                    <ul>
                        <li><a class="no-bg" id="prevPage" (click)="this.Pagination(this.previous_page)"><i class="fa fa-chevron-left"></i></a></li>
                        <li><a class="active" [routerLink]="" (click)="Pagination(this.current_page)">{{this.current_page }}</a></li>
                        <li><a (click)="this.Pagination((+this.current_page + 1))" *ngIf="(+this.current_page + 1) <= this.last_page">{{(+this.current_page) + 1 }}</a></li>
                        <li><a (click)="this.Pagination((+this.current_page + 2))"  *ngIf="(+this.current_page + 2) <= this.last_page">{{(+this.current_page) + 2}}</a></li>
                        <li class="no-bg" *ngIf="((+this.current_page + 3)) < this.last_page">...</li>
                        <li><a (click)="this.Pagination(this.last_page)" *ngIf="(+this.current_page + 2) < this.last_page">{{this.last_page }}</a></li>
                        <li><a  class="no-bg" id="nextPage"  (click)="this.Pagination(this.next_page)"><i class="fa fa-chevron-right"></i></a></li>
                    </ul>
                </nav> -->
                <nav class="table-pagination">
                    <ul>
                      <li>
                        <div class="go-to-page d-flex align-items-center">
                            <label>Go to Page:</label>
                            <input type="number" class="form-control form-control-sm" [(ngModel)]="pageToGo" min="1" [max]="total_pages" (keydown.enter)="goToPage(pageToGo); pageToGo = null">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" (click)="goToPage(pageToGo); pageToGo = null" [disabled]="pageToGo < 1 || pageToGo > total_pages">Go</button>
                            </div>
                        </div>
                    </li>        
                        <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                        <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                        <li><a class="active">{{ current_page }}</a></li>
                        <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                        <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        </div>
    </div>
</section>
</div>

<app-plant-detail *ngIf="showPlantDetail"></app-plant-detail>
<!-- <app-test *ngIf="showPlantDetail"></app-test> -->
