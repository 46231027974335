<div class="loaderwrapper" *ngIf="this.loader">
    <span class="loader" ></span> 
</div> 
 <section class="dashboard-main container-fluid">
    <div class="row mb-0 pt-4 block-topbar">
        <div class="col-12 d-sm-flex align-items-center justify-content-between mb-4">
            <h4 class="mb-sm-0">Ticket List</h4>
            
        </div>
            <div class="col-12 d-sm-flex justify-content-between align-items-center">
            <div class="dataTables_length p-0">
                <label class="show-entries-list">
                  Show
                  <!-- (change)="changePageSize()" -->
                  <!-- [formControl]="pageSizeControl" -->
                  <select [formControl]="pageSizeControl" (change)="changePageSize()"
                    class="form-control form-control-sm">
                    <option *ngFor="let limit of pageRecordOptions" [value]="limit.value">{{limit.label}}</option>
                  </select>
                  Entries
                </label>
            </div>
            <div class="d-flex justify-content-end pb-3">
              <div class="row userSearch d-flex justify-content-center pe-3 m-0">
                  <div class="userSearcForm form-styles p-0">
                      <input class="form-control searchTerm" type="search" name="ticketList" placeholder="Search" (input)="searchData($event)"  />
                      <span class="search-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.973" height="12.973" viewBox="0 0 12.973 12.973">
                          <g id="search-outline" transform="translate(0.5 0.5)">
                            <path id="Path_2581" data-name="Path 2581" d="M9.313,4.5a4.813,4.813,0,1,0,4.813,4.813A4.813,4.813,0,0,0,9.313,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
                            <path id="Path_2582" data-name="Path 2582" d="M23.786,23.786l3.362,3.362" transform="translate(-15.382 -15.381)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                          </g>
                        </svg>
                      </span>
                  </div>
              </div>
            </div>   
        </div>
      </div>
    <section class="contents-main">
    
        <div class="table-wrap">
            <div class="table-main">
                <ngx-datatable #myTable [style.width]="'100%'"  class="material expandable table-responsive" [rows]="ticketList" [headerHeight]="50"
                [footerHeight]="0" [columnMode]="'force'" [scrollbarH]="true" [rowHeight]="'auto'" [offset]="0"
                [externalSorting]="true" [messages]="{emptyMessage: 'No Ticket found.'}" (sort)="onSort($event)">
                <!-- <ngx-datatable-column  [sortable]="true" name="Sr No." prop="srNo" [width]="100">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row.srNo }}
                    </ng-template>
                  </ngx-datatable-column> -->
  
                <!-- <ngx-datatable-column  [sortable]="false" name="Ticket ID" prop="ticket_id" [width]="100">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a class="cursor-pointer" (click)="sortTickets('ticket_id')">
                            {{row.ticket_id}}
                          </a>
                      {{ row.getTicketID() }}
                    </ng-template>
                </ngx-datatable-column> -->
                <ngx-datatable-column [sortable]="false" name="Ticket ID" prop="ticket_id" [width]="100">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('ticket_id')">
                        Ticket ID
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'ticket_id',
                                          'datatable-icon-up': sortColumn === 'ticket_id' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'ticket_id' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <a class="cursor-pointer" (click)="showTicketList(row, ticketDetail)">
                        {{row.ticket_id}}
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  


                  <!-- <ngx-datatable-column [sortable]="false" name="Title" prop="title" [width]="180">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.title}}
                        <a class="cursor-pointer" (click)="sortTickets('title'); showTicketList(row, ticketDetail)">
                            {{row.title}}
                        </a>                
                      {{ row.getTitle() }}
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Title" prop="title" [width]="180">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('title')">
                        Title
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'title',
                                          'datatable-icon-up': sortColumn === 'title' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'title' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <a class="cursor-pointer" (click)="showTicketList(row, ticketDetail)">
                        {{row.title}}
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  


                  <!-- <ngx-datatable-column [sortable]="false" name="Plant Name" prop="plant_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.plant_name}}
                        <a class="cursor-pointer" (click)="sortTickets('plant_name'); showTicketList(row, ticketDetail) ">
                            {{row.plant_name}}
                        </a>  
                      {{ row.getPlantName() }}
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Plant Name" prop="plant_name">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('plant_name')">
                        Plant Name
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'plant_name',
                                          'datatable-icon-up': sortColumn === 'plant_name' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'plant_name' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <a class="cursor-pointer" (click)="showTicketList(row, ticketDetail)">
                        {{row.plant_name}}
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  

                  <!-- <ngx-datatable-column [sortable]="false" name="Source" prop="source">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.source}}
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Source" prop="source">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('source')">
                        Source
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'source',
                                          'datatable-icon-up': sortColumn === 'source' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'source' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.source}}
                    </ng-template>
                  </ngx-datatable-column>
                  

                  <ngx-datatable-column name="Status" prop="status" [width]="150" [sortable]="false">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div style="width: 180px;" class="priorityList" (click)="toggleDropdownClicked()">
                        <ng-select [(ngModel)]="row.status" [disabled]="row.status === 5" (change)="changeStatus(row, $event)">
                          <ng-option *ngFor="let option of statusDDL" [value]="option.id">{{ option.text }}</ng-option>
                        </ng-select>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <!-- <ngx-datatable-column  [sortable]="true" name="Created at" prop="created_at" [width]="180">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.created_at | date: 'medium' }}
                      {{ row.getCreatedAt() | date: 'medium' }}
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Created at" prop="created_at" [width]="180">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('created_at')">
                        Created at
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'created_at',
                                          'datatable-icon-up': sortColumn === 'created_at' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'created_at' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.created_at | date: 'medium'}}
                    </ng-template>
                  </ngx-datatable-column>
                  

                  <ngx-datatable-column [sortable]="false" name="Priority" prop="priority" [width]="150" *ngIf="isAdmin">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div style="width: 150px;" class="priorityList" *ngIf="isAdmin" (click)="toggleDropdownClicked()">
                        <ng-select [(ngModel)]="row.priority" [disabled]="row.status === 5" (change)="changePriority(row, $event)">
                          <ng-option *ngFor="let option of priority" [value]="option.id">{{ option.name }}</ng-option>
                        </ng-select>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                
                  <!-- Assign To (Admin) Column -->
                  <!-- <ngx-datatable-column [sortable]="false" name="Assign To" prop="assigned_to" [width]="180" *ngIf="isAdmin">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div style="width: 180px;" class="priorityList" *ngIf="isAdmin" (click)="toggleDropdownClicked()">
                        <ng-select [(ngModel)]="row.assigned_to" [disabled]="row.status === 5" (change)="changeAssignTo(row, $event)">
                          <ng-option *ngFor="let option of user" [value]="option.user_id">{{ option.username }}</ng-option>
                        </ng-select>
                      </div>
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column [sortable]="false" name="Assign To" prop="assigned_to" [width]="180" *ngIf="isAdmin">
                    <ng-template ngx-datatable-header-template let-column="column">
                      <a class="cursor-pointer" (click)="sortTickets('assigned_to')">
                        Assign To
                        <span class="sort-btn"
                              [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'assigned_to',
                                          'datatable-icon-up': sortColumn === 'assigned_to' && sortOrder === 'asc',
                                          'datatable-icon-down': sortColumn === 'assigned_to' && sortOrder === 'desc'}">
                        </span>
                      </a>
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div style="width: 180px;" class="priorityList" *ngIf="isAdmin" (click)="toggleDropdownClicked()">
                        <ng-select [(ngModel)]="row.assigned_to" [disabled]="row.status === 5" (change)="changeAssignTo(row, $event)">
                          <ng-option *ngFor="let option of user" [value]="option.user_id">{{ option.username }}</ng-option>
                        </ng-select>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>                  
                
                  <!-- Re-Open (Admin) Column -->
                  <!-- Re-Open Column -->
<ngx-datatable-column [sortable]="false" name="Re-Open" [width]="80" *ngIf="isAdmin">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="priorityList text-center">
        <ng-container *ngIf="row.status === 5; else notReopen">
          <a (click)="statuToReopen(row)">
            <!-- Re-Open Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" width="30.033" height="24.017" viewBox="1755.983 219.983 30.033 24.017">
              <g data-name="Group 2797">
                <g data-name="folder-open-sharp">
                  <path d="m1756.005 227.837 1.786 14.617a1.75 1.75 0 0 0 1.742 1.546h22.937a1.75 1.75 0 0 0 1.741-1.546l1.784-14.617a.75.75 0 0 0-.745-.837h-28.5a.75.75 0 0 0-.745.837Z" fill="#ED5E5E" fill-rule="evenodd" data-name="Path 5511"/>
                  <path d="M1784 223.75a1.75 1.75 0 0 0-1.75-1.75h-11.947l-3-2h-7.553a1.75 1.75 0 0 0-1.75 1.75V225h26v-1.25Z" fill="#ED5E5E" fill-rule="evenodd" data-name="Path 5512"/>
                </g>
                <g data-name="Group 2794">
                  <path d="M1766 234.436a3.528 3.528 0 0 0 6.75.87m.218 1.265v-1.264h-1.378" stroke-linejoin="round" stroke-linecap="round" stroke-width=".8" stroke="#fff" fill="transparent" data-name="Path 448"/>
                  <path d="M1774.968 235.478a3.528 3.528 0 0 0-6.75-.871m-.219-1.265v1.265h1.379" stroke-linejoin="round" stroke-linecap="round" stroke-width=".8" stroke="#fff" fill="transparent" data-name="Path 5513"/>
                </g>
              </g>
            </svg>
          </a>
        </ng-container>
        <ng-template #notReopen>
          <!-- Display some other content when not reopen -->
          <!-- For example, a different icon or text -->
          <svg xmlns="http://www.w3.org/2000/svg" width="30.033" height="24.017" viewBox="1755.983 219.983 30.033 24.017">
            <g data-name="Group 2797">
              <g data-name="folder-open-sharp">
                <path d="m1756.005 227.837 1.786 14.617a1.75 1.75 0 0 0 1.742 1.546h22.937a1.75 1.75 0 0 0 1.741-1.546l1.784-14.617a.75.75 0 0 0-.745-.837h-28.5a.75.75 0 0 0-.745.837Z" fill="#808080" fill-rule="evenodd" data-name="Path 5511"/>
                <path d="M1784 223.75a1.75 1.75 0 0 0-1.75-1.75h-11.947l-3-2h-7.553a1.75 1.75 0 0 0-1.75 1.75V225h26v-1.25Z" fill="#808080" fill-rule="evenodd" data-name="Path 5512"/>
              </g>
              <g data-name="Group 2794">
                <path d="M1766 234.436a3.528 3.528 0 0 0 6.75.87m.218 1.265v-1.264h-1.378" stroke-linejoin="round" stroke-linecap="round" stroke-width=".8" stroke="#fff" fill="transparent" data-name="Path 448"/>
                <path d="M1774.968 235.478a3.528 3.528 0 0 0-6.75-.871m-.219-1.265v1.265h1.379" stroke-linejoin="round" stroke-linecap="round" stroke-width=".8" stroke="#fff" fill="transparent" data-name="Path 5513"/>
              </g>
            </g>
          </svg>
        </ng-template>
      </div>
    </ng-template>
  </ngx-datatable-column>
  

</ngx-datatable>

                <div class="table-meta">
                    <div class="entries">
                        <span>Total Records: {{this.total_complaints }}</span>               
                    </div>
                    <!-- <nav class="table-pagination">
                        <ul>
                            <li><a class="no-bg" id="prevPage" (click)="this.Pagination(this.previous_page)"><i class="fa fa-chevron-left"></i></a></li>
                            <li><a class="active" [routerLink]="" (click)="Pagination(this.current_page)">{{this.current_page }}</a></li>
                            <li><a (click)="this.Pagination((this.current_page + 1))" *ngIf="(this.current_page + 1) <= this.total_pages">{{(this.current_page) + 1 }}</a></li>
                            <li><a (click)="this.Pagination((this.current_page + 2))"  *ngIf="(this.current_page + 2) <= this.total_pages">{{(this.current_page) + 2}}</a></li>
                            <li class="no-bg" *ngIf="((+this.current_page + 3)) < this.total_pages">...</li>
                            <li><a (click)="this.Pagination(this.total_pages)" *ngIf="(this.current_page + 2) < this.total_pages">{{this.total_pages }}</a></li>
                            <li><a  class="no-bg" id="nextPage"  (click)="this.Pagination(this.next_page)"><i class="fa fa-chevron-right"></i></a></li>
                        </ul>
                    </nav> -->
                    <!-- <nav class="table-pagination">
                        <ul>
                            <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                            <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                            <li><a class="active">{{ current_page }}</a></li>
                            <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                            <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
                        </ul>
                    </nav>         -->
                    <nav class="table-pagination">
                        <ul>
                            <li>
                                <div class="go-to-page d-flex align-items-center">
                                    <label>Go to Page:</label>
                                    <input type="number" class="form-control form-control-sm" [(ngModel)]="pageToGo" min="1" [max]="total_pages" (keydown.enter)="goToPage(pageToGo); pageToGo = null">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" (click)="goToPage(pageToGo); pageToGo = null" [disabled]="pageToGo < 1 || pageToGo > total_pages">Go</button>
                                    </div>
                                </div>
                            </li>                                                                                                
                            <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                            <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                            <li><a class="active">{{ current_page }}</a></li>
                            <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                            <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
                        </ul>
                    </nav>
                    
                </div>
               
            </div>
        </div>
    </section>
</section>

<ng-template #ticketDetail let-c="close" let-d="dismiss" let-width="400">
    <form>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Complaint</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
                    <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
                </svg>
            </button>
        </div>

        <div class="modal-body">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Ticket ID</th>
                        <th scope="col">Title</th>
                        <th scope="col">Plant Name</th>
                        <th scope="col">Assign TO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ ticketListbyid.ticket_id }}</td>
                        <td>{{ ticketListbyid.title }}</td>
                        <td>{{ ticketListbyid.plant_name }}</td>
                        <td>{{ ticketListbyid.assigned_to }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Source</th>
                        <th scope="col">Created at</th>
                        <th scope="col">Priority</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ ticketListbyid.source }}</td>
                        <td>{{ ticketListbyid.created_at }}</td>
                        <td>{{ ticketListbyid.priority }}</td>
                        <td>{{ statusType }}</td>
                    </tr>
                </tbody>
            </table>


            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Category</th>
                        <th scope="col">Sub Category</th>
                        <th scope="col">Contact</th>
                        <th scope="col">filed by</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ ticketListbyid.category }}</td>
                        <td>{{ ticketListbyid.sub_category }}</td>
                        <td>{{ ticketListbyid.contact }}</td>
                        <td>{{ ticketListbyid.filed_by }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col"> Description</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ ticketListbyid.description }}</td>

                    </tr>
                </tbody>
            </table>

            <div class="chat-block">
                <strong class="title">Comments</strong>
                <div class="messages-holder"  #scrollContainer [scrollTop]="scrollContainer.scrollHeight">
                    <div *ngFor="let item of ticketComments.slice().reverse();let i = index" >
                    <div class="message-box"
                    *ngIf="matchCommentUser(item.user_id) ===false"
                    
                    [attr.id]="'comment' + i">
                        <div class="avatar">
                            <img src="assets/img/default-profile.png" alt="Image Description">
                        </div>
                        <div class="message-item"  >
                            <strong class="name">{{item.username}}</strong>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">

                                        <div class="text" *ngIf="isImageUrl(item.comment) === true">
                                            <img class="chat-img" [src]="item.comment" alt="Image">
                                            </div>
                                          
                                            
                                            <div class="text" *ngIf="isMp3Url(item.comment) === false && isImageUrl(item.comment) === false && isVideoFilePath(item.comment) === false">
                                            <div *ngIf="isImageUrl(item.comment) === false && isVideoFilePath(item.comment) === false">
                                                {{item.comment}}
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="chat-img" *ngIf="isVideoFilePath(item.comment) === true" >
                                            <div class="video-option">
                                                <!-- <span class="video-icon">
                                                    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="currentColor"/>
                                                    </svg>                                                        
                                                </span> -->

                                                <video controls width="640" height="360">
                                                    <source [src]="item.comment" type="video/mp4">
                                                  </video>
                                            </div>
                                            
                                        </div>

                                        <div class="chat-img" *ngIf="isMp3Url(item.comment) === true" >
                                           
                                                <audio controls>
                                                    <ng-container *ngFor="let format of audioFormats">
                                                        <source [src]="item.comment" [type]="format">
                                                      </ng-container>
                                                  </audio>
                                            
                                            
                                        </div>

                                        
                                        
                                        
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="message-box reverse"
                    *ngIf="matchCommentUser(item.user_id) === true"
                   
                    
                    [attr.id]="'comment' + i">
                        <div class="avatar">
                            <img src="assets/img/default-profile.png" alt="Image Description">
                        </div>
                        <div class="message-item" >
                            <strong class="name">{{item.username}}</strong>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">

                                        <div class="text" *ngIf="isImageUrl(item.comment) === true">
                                            <img class="chat-img" [src]="item.comment" alt="Image">
                                            </div>
                                          
                                            
                                            <div class="text" *ngIf="isMp3Url(item.comment) === false && isImageUrl(item.comment) === false && isVideoFilePath(item.comment) === false">
                                            <div *ngIf="isImageUrl(item.comment) === false && isVideoFilePath(item.comment) === false">
                                                {{item.comment}}
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="chat-img" *ngIf="isVideoFilePath(item.comment) === true" >
                                            <div class="video-option">
                                                <!-- <span class="video-icon">
                                                    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="currentColor"/>
                                                    </svg>                                                        
                                                </span> -->

                                                <video controls width="640" height="360">
                                                    <source [src]="item.comment" type="video/mp4">
                                                  </video>
                                            </div>
                                            
                                        </div>

                                        <div class="chat-img" *ngIf="isMp3Url(item.comment) === true" >
                                           
                                                <audio controls>
                                                    <ng-container *ngFor="let format of audioFormats">
                                                        <source [src]="item.comment" [type]="format">
                                                      </ng-container>
                                                  </audio>
                                            
                                            
                                        </div>

                                        
                                        
                                        
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                    <!-- <div class="message-box">
                        <div class="avatar">
                            <img src="assets/img/default-profile.png" alt="Image Description">
                        </div>
                        <div class="message-item">
                            <strong class="name">Ana Smith</strong>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">
                                        <div class="text">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </div>
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time"></div>
                                </div>
                            </div>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">
                                        <div class="text">
                                            Lorem Ipsum is simply
                                        </div>
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time">4 days ago</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="message-box reverse">
                        <div class="avatar">
                            <img src="assets/img/default-profile.png" alt="Image Description">
                        </div>
                        <div class="message-item">
                            <strong class="name">Ana Smith</strong>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">
                                        <div class="text">
                                            Lorem Ipsum is simply
                                        </div>
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time">4 days ago</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="message-box">
                        <div class="avatar">
                            <img src="assets/img/default-profile.png" alt="Image Description">
                        </div>
                        <div class="message-item">
                            <strong class="name">Ana Smith</strong>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">
                                        <div class="text">
                                            <div class="file-option">
                                                <span class="file-icon">
                                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4442 18H2.55524C2.24924 18 2.00024 17.776 2.00024 17.5V2.5C2.00024 2.224 2.24924 2 2.55524 2H7.00024V6.15C7.00024 7.722 8.21724 9 9.71424 9H14.0002V17.5C14.0002 17.776 13.7502 18 13.4442 18ZM13.6492 7H9.71424C9.32024 7 9.00024 6.619 9.00024 6.15V2H9.11224L13.6492 7ZM15.7402 6.328L10.2962 0.328C10.1072 0.119 9.83824 0 9.55524 0H2.55524C1.14624 0 0.000244141 1.122 0.000244141 2.5V17.5C0.000244141 18.878 1.14624 20 2.55524 20H13.4442C14.8532 20 16.0002 18.878 16.0002 17.5V7C16.0002 6.751 15.9072 6.512 15.7402 6.328Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                                <div class="file-info">
                                                    <strong class="file-name">Style.zip</strong>
                                                    <span class="file-weight">41.36 mb</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time">4 days ago</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="day-separator">
                        <span>3 days ago</span>
                    </div>
                    <div class="message-box reverse">
                        <div class="avatar">
                            <img src="assets/img/default-profile.png" alt="Image Description">
                        </div>
                        <div class="message-item">
                            <strong class="name">Ana Smith</strong>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">
                                        <div class="text">
                                            <div class="image-option">
                                                <span class="image-icon">
                                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22.5 15C23.39 15 24.26 14.7361 25.0001 14.2416C25.7401 13.7471 26.3169 13.0443 26.6575 12.2221C26.9981 11.3998 27.0872 10.495 26.9135 9.6221C26.7399 8.74918 26.3113 7.94736 25.682 7.31802C25.0526 6.68868 24.2508 6.2601 23.3779 6.08647C22.505 5.91283 21.6002 6.00195 20.7779 6.34254C19.9557 6.68314 19.2529 7.25991 18.7584 7.99994C18.2639 8.73996 18 9.60999 18 10.5C18 11.6935 18.4741 12.8381 19.318 13.682C20.1619 14.5259 21.3065 15 22.5 15ZM22.5 9C22.7967 9 23.0867 9.08798 23.3334 9.2528C23.58 9.41762 23.7723 9.65189 23.8858 9.92598C23.9994 10.2001 24.0291 10.5017 23.9712 10.7926C23.9133 11.0836 23.7704 11.3509 23.5607 11.5607C23.3509 11.7704 23.0836 11.9133 22.7926 11.9712C22.5017 12.0291 22.2001 11.9994 21.926 11.8858C21.6519 11.7723 21.4176 11.58 21.2528 11.3334C21.088 11.0867 21 10.7967 21 10.5C21 10.1022 21.158 9.72065 21.4393 9.43934C21.7206 9.15804 22.1022 9 22.5 9Z" fill="currentColor"/>
                                                        <path d="M33 0H3C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3V33C0 33.7957 0.31607 34.5587 0.87868 35.1213C1.44129 35.6839 2.20435 36 3 36H33C33.7957 36 34.5587 35.6839 35.1213 35.1213C35.6839 34.5587 36 33.7957 36 33V3C36 2.20435 35.6839 1.44129 35.1213 0.87868C34.5587 0.31607 33.7957 0 33 0ZM33 33H3V24L10.5 16.5L18.885 24.885C19.4471 25.4438 20.2074 25.7574 21 25.7574C21.7926 25.7574 22.5529 25.4438 23.115 24.885L25.5 22.5L33 30V33ZM33 25.755L27.615 20.37C27.0529 19.8112 26.2926 19.4976 25.5 19.4976C24.7074 19.4976 23.9471 19.8112 23.385 20.37L21 22.755L12.615 14.37C12.0529 13.8112 11.2926 13.4976 10.5 13.4976C9.70744 13.4976 8.94709 13.8112 8.385 14.37L3 19.755V3H33V25.755Z" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time"></div>
                                </div>
                            </div>
                            <div class="message-wrap">
                                <div class="text-message">
                                    <div class="text-options-wrap">
                                        <div class="text">
                                            <div class="video-option">
                                                <span class="video-icon">
                                                    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 7L0.5 13.0622L0.500001 0.937822L11 7Z" fill="currentColor"/>
                                                    </svg>                                                        
                                                </span>
                                            </div>
                                        </div>
                                        <div class="option-dots">
                                            <button class="dots"><span></span></button>
                                        </div>
                                    </div>
                                    <div class="message-time">3 days ago</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="messages-form">
                    <div class="fileUpload-box">
                        <button class="add-button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 7H9V1C9 0.447 8.552 0 8 0C7.448 0 7 0.447 7 1V7H1C0.448 7 0 7.447 0 8C0 8.553 0.448 9 1 9H7V15C7 15.553 7.448 16 8 16C8.552 16 9 15.553 9 15V9H15C15.552 9 16 8.553 16 8C16 7.447 15.552 7 15 7" fill="currentColor"/>
                            </svg>
                        </button>
                        <ul class="filelist-option">

                            <!-- <li>
                                
                                <button class="option-button" >
                                    <input type="file" #fileInput accept="image/*,video/*" (change)="handleFileInput($event)">
                                    <svg
                                     width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 15.2559C16 15.6659 15.666 15.9999 15.256 15.9999H14V13.9999H16V15.2559ZM2 15.2559V13.9999H4V15.9999H2.744C2.334 15.9999 2 15.6659 2 15.2559V15.2559ZM2.744 1.99988H4V3.99988H2V2.74388C2 2.33388 2.334 1.99988 2.744 1.99988V1.99988ZM16 2.74388V3.99988H14V1.99988H15.256C15.666 1.99988 16 2.33388 16 2.74388V2.74388ZM14 11.9999H16V9.99988H14V11.9999ZM14 7.99988H16V5.99988H14V7.99988ZM6 15.9999H12V1.99988H6V15.9999ZM2 11.9999H4V9.99988H2V11.9999ZM2 7.99988H4V5.99988H2V7.99988ZM15.256 -0.00012207H2.744C1.231 -0.00012207 0 1.23188 0 2.74388V15.2559C0 16.7689 1.231 17.9999 2.744 17.9999H15.256C16.769 17.9999 18 16.7689 18 15.2559V2.74388C18 1.23188 16.769 -0.00012207 15.256 -0.00012207V-0.00012207Z" fill="currentColor"/>
                                    </svg>
                                </button>
                            </li> -->

                            <li>
                                <label for="fileInput" class="option-button">
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7C5.828 7 6.5 6.328 6.5 5.5C6.5 4.672 5.828 4 5 4C4.172 4 3.5 4.672 3.5 5.5C3.5 6.328 4.172 7 5 7M15 16H3.561L10.566 10.155C10.812 9.946 11.258 9.947 11.499 10.154L16 13.994V15C16 15.552 15.552 16 15 16M3 2H15C15.552 2 16 2.448 16 3V11.364L12.797 8.632C11.807 7.79 10.258 7.79 9.277 8.626L2 14.698V3C2 2.448 2.448 2 3 2M15 0H3C1.346 0 0 1.346 0 3V15C0 16.654 1.346 18 3 18H15C16.654 18 18 16.654 18 15V3C18 1.346 16.654 0 15 0" fill="currentColor"/>
                                  </svg>
                                </label>
                                <input id="fileInput" type="file" style="display: none;" (change)="handleFileInput($event)">
                              </li>
                            <!-- <li>
                                <input type="file" #fileInput (change)="handleFileInput($event)" />
                            </li> -->
                            <!-- <li>
                                <input type="file" #fileInput accept="image/*,video/*" (change)="handleFileInput($event)">
                                <button class="option-button" >
                                    <svg (click)="uploadFile()" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 15.2559C16 15.6659 15.666 15.9999 15.256 15.9999H14V13.9999H16V15.2559ZM2 15.2559V13.9999H4V15.9999H2.744C2.334 15.9999 2 15.6659 2 15.2559V15.2559ZM2.744 1.99988H4V3.99988H2V2.74388C2 2.33388 2.334 1.99988 2.744 1.99988V1.99988ZM16 2.74388V3.99988H14V1.99988H15.256C15.666 1.99988 16 2.33388 16 2.74388V2.74388ZM14 11.9999H16V9.99988H14V11.9999ZM14 7.99988H16V5.99988H14V7.99988ZM6 15.9999H12V1.99988H6V15.9999ZM2 11.9999H4V9.99988H2V11.9999ZM2 7.99988H4V5.99988H2V7.99988ZM15.256 -0.00012207H2.744C1.231 -0.00012207 0 1.23188 0 2.74388V15.2559C0 16.7689 1.231 17.9999 2.744 17.9999H15.256C16.769 17.9999 18 16.7689 18 15.2559V2.74388C18 1.23188 16.769 -0.00012207 15.256 -0.00012207V-0.00012207Z" fill="currentColor"/>
                                    </svg>
                                </button>
                            </li>
                            <li>
                                <button class="option-button">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7C5.828 7 6.5 6.328 6.5 5.5C6.5 4.672 5.828 4 5 4C4.172 4 3.5 4.672 3.5 5.5C3.5 6.328 4.172 7 5 7M15 16H3.561L10.566 10.155C10.812 9.946 11.258 9.947 11.499 10.154L16 13.994V15C16 15.552 15.552 16 15 16M3 2H15C15.552 2 16 2.448 16 3V11.364L12.797 8.632C11.807 7.79 10.258 7.79 9.277 8.626L2 14.698V3C2 2.448 2.448 2 3 2M15 0H3C1.346 0 0 1.346 0 3V15C0 16.654 1.346 18 3 18H15C16.654 18 18 16.654 18 15V3C18 1.346 16.654 0 15 0" fill="currentColor"/>
                                    </svg>
                                </button>
                            </li> -->
                            <!-- <li>
                                <button class="option-button">
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4442 18H2.55524C2.24924 18 2.00024 17.776 2.00024 17.5V2.5C2.00024 2.224 2.24924 2 2.55524 2H7.00024V6.15C7.00024 7.722 8.21724 9 9.71424 9H14.0002V17.5C14.0002 17.776 13.7502 18 13.4442 18ZM13.6492 7H9.71424C9.32024 7 9.00024 6.619 9.00024 6.15V2H9.11224L13.6492 7ZM15.7402 6.328L10.2962 0.328C10.1072 0.119 9.83824 0 9.55524 0H2.55524C1.14624 0 0.000244141 1.122 0.000244141 2.5V17.5C0.000244141 18.878 1.14624 20 2.55524 20H13.4442C14.8532 20 16.0002 18.878 16.0002 17.5V7C16.0002 6.751 15.9072 6.512 15.7402 6.328Z" fill="currentColor"/>
                                    </svg>
                                </button>
                            </li> -->
                        </ul>
                    </div>
                    <div class="message-input">
                        <textarea cols="10" rows="5" id="newMessage" name="newMessage" [(ngModel)]="newMessage" placeholder="Type a message here"></textarea>
                    </div>
                    <div class="message-form-options">
                        <div class="emojies-box">
                            <button class="emoji-button"> 
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="10.75" stroke="currentColor" stroke-opacity="1" stroke-width="2.5"/>
                                    <path d="M7.63635 13.6364C8.45647 15.257 10.1028 16.3636 12 16.3636C13.8972 16.3636 15.5435 15.257 16.3636 13.6364" stroke="currentColor" stroke-opacity="1" stroke-width="2.5" stroke-linecap="round"/>
                                    <circle cx="8.72726" cy="8.72726" r="1.09091" fill="currentColor" fill-opacity="1"/>
                                    <circle cx="15.2727" cy="8.72726" r="1.09091" fill="currentColor" fill-opacity="1"/>
                                </svg>
                            </button> 
                        </div>
                        <!-- <div class="mic-box">
                            <audio  controls >
                                <source [src]="audioURL" type="audio/webm">
                            </audio>
                            <button class="mic-button" (click)="toggleRecording()">
                                
                                <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.70625 9.18127C0.893559 9.18127 1.0732 9.25568 1.20564 9.38813C1.33809 9.52058 1.4125 9.70022 1.4125 9.88752V11.3C1.4125 12.7985 2.00777 14.2356 3.06735 15.2952C4.12693 16.3548 5.56403 16.95 7.0625 16.95C8.56097 16.95 9.99807 16.3548 11.0577 15.2952C12.1172 14.2356 12.7125 12.7985 12.7125 11.3V9.88752C12.7125 9.70022 12.7869 9.52058 12.9194 9.38813C13.0518 9.25568 13.2314 9.18127 13.4187 9.18127C13.6061 9.18127 13.7857 9.25568 13.9181 9.38813C14.0506 9.52058 14.125 9.70022 14.125 9.88752V11.3C14.125 13.0509 13.4747 14.7393 12.3002 16.0377C11.1257 17.3362 9.51081 18.1521 7.76875 18.3272V21.1875H12.0063C12.1936 21.1875 12.3732 21.2619 12.5056 21.3944C12.6381 21.5268 12.7125 21.7065 12.7125 21.8938C12.7125 22.0811 12.6381 22.2607 12.5056 22.3932C12.3732 22.5256 12.1936 22.6 12.0063 22.6H2.11875C1.93144 22.6 1.7518 22.5256 1.61936 22.3932C1.48691 22.2607 1.4125 22.0811 1.4125 21.8938C1.4125 21.7065 1.48691 21.5268 1.61936 21.3944C1.7518 21.2619 1.93144 21.1875 2.11875 21.1875H6.35625V18.3272C4.61419 18.1521 2.99927 17.3362 1.82478 16.0377C0.650293 14.7393 -2.1995e-05 13.0509 5.57946e-10 11.3V9.88752C5.57946e-10 9.70022 0.0744084 9.52058 0.206856 9.38813C0.339304 9.25568 0.518941 9.18127 0.70625 9.18127Z" fill="currentColor"/>
                                    <path d="M9.88745 11.3C9.88745 12.0492 9.58982 12.7678 9.06003 13.2976C8.53024 13.8274 7.81169 14.125 7.06245 14.125C6.31321 14.125 5.59466 13.8274 5.06487 13.2976C4.53508 12.7678 4.23745 12.0492 4.23745 11.3V4.2375C4.23745 3.48826 4.53508 2.76971 5.06487 2.23992C5.59466 1.71013 6.31321 1.4125 7.06245 1.4125C7.81169 1.4125 8.53024 1.71013 9.06003 2.23992C9.58982 2.76971 9.88745 3.48826 9.88745 4.2375V11.3ZM7.06245 0C5.9386 0 4.86077 0.44645 4.06609 1.24114C3.2714 2.03582 2.82495 3.11365 2.82495 4.2375V11.3C2.82495 12.4239 3.2714 13.5017 4.06609 14.2964C4.86077 15.0911 5.9386 15.5375 7.06245 15.5375C8.18631 15.5375 9.26413 15.0911 10.0588 14.2964C10.8535 13.5017 11.3 12.4239 11.3 11.3V4.2375C11.3 3.11365 10.8535 2.03582 10.0588 1.24114C9.26413 0.44645 8.18631 0 7.06245 0Z" fill="currentColor"/>
                                </svg>
                            </button>
                        </div> -->

                        <!-- <div>
                            <button (click)="toggleRecording()">
                              {{ recording ? 'Stop Recording' : 'Start Recording' }}
                            </button>

                            <audio  controls >
                                <source [src]="audioURL" type="audio/webm">
                            </audio>
                          </div>
                           -->
                        <div class="send-box">
                            <button class="send-button" (click)="sendMessage();">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3892 15.3333C10.3717 15.3333 10.355 15.3325 10.3384 15.3316C9.9617 15.3091 9.64754 15.0358 9.57254 14.6658L8.29087 8.35997C8.2242 8.03163 7.96837 7.7758 7.64004 7.70913L1.3342 6.42663C0.964204 6.35247 0.69087 6.0383 0.66837 5.66163C0.64587 5.28413 0.87837 4.93913 1.2367 4.8208L14.57 0.376633C14.8692 0.274966 15.1992 0.3533 15.4225 0.577466C15.6459 0.8008 15.7234 1.1308 15.6242 1.42997L11.1792 14.7633C11.0659 15.1058 10.7459 15.3333 10.3892 15.3333" fill="currentColor"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </form>
</ng-template>