
<h6 class="dropdown-header">Welcome !</h6>
<a class="dropdown-item" (click)="showChnagePassword(cp)"  ><i
        class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span
        class="align-middle">Change Password</span></a>
<a class="dropdown-item" (click)="userProfile(up)"  ><i
        class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span
        class="align-middle">Edit Profile</span></a>
<div class="dropdown-divider"></div>
<a class="dropdown-item"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
        class="align-middle" data-key="t-logout" (click)="logout()">Logout</span></a>

<ng-template #cp let-c="close" let-d="dismiss">
  
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
          <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
            <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
          </svg>
        </button>
    </div>
    <div class="modal-body">
        <!-- #e="ngForm" (ngSubmit)="changePasswordsubmit()" -->
        <form #e="ngForm" (ngSubmit)="changePasswordsubmit()" >
            <div class="auditbody changePasswordForm form-styles">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row mb-2 align-items-center">
                      <div class="col-md-4"><label>Old Password</label></div>
                      <div class="col-md-8 d-flex">                   
                        <input type="password" class="form-control" id="oldpassword" name="oldPass" [(ngModel)]="changePass.current_password" > 
                        <div class="fieldTypeIcon" >
                            <!-- (change)="fieldsClick()" -->
                          <input class="passField" type="checkbox"  (change)="fieldsClick()" > 
                          <span class="visible" *ngIf="visible"><i class="fa fa-eye  mr-1 "></i></span>
                           <span class="hideView" *ngIf="!visible"><i class="fa fa-eye-slash" aria-hidden="true"></i>
                           </span>                      
                        </div>                         
                      </div>
                    </div>
                    <div class="row mb-2 align-items-center">
                      <div class="col-md-4"><label>New Password</label></div>
                      <div class="col-md-8 d-flex"> 
                        <!--  -->
                        <input type="password" class="form-control " id="newPasswordid" name="complainerMobileNo" [(ngModel)]="changePass.new_password" > 
                        <div class="fieldTypeIcon" >
                            <!-- (change)="newPasswordClick()" -->
                          <input class="passField" type="checkbox"(change)="newPasswordClick()" > 
                          <span class="visible" *ngIf="newPasswordVisible"><i class="fa fa-eye  mr-1 "></i></span>
                           <span class="hideView" *ngIf="!newPasswordVisible"><i class="fa fa-eye-slash" aria-hidden="true"></i>
                           </span>
                      
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2 align-items-center">
                      <div class="col-md-4"><label>Confirm Password</label></div>
                      <div class="col-md-8 d-flex"> 
                        <!-- [(ngModel)]="confPass" -->
                        <input type="password" class="form-control "  id="confirmPasswordid" name="complainerMobileNo" [(ngModel)]="confPass" >
                        <div class="fieldTypeIcon" >
                            <!-- (change)="confirmPasswordClick()" -->
                          <input class="passField" type="checkbox" (change)="confirmPasswordClick()"> 
                          <!-- *ngIf="confirmPasswordVisible" -->
                          <span class="visible" *ngIf="confirmPasswordVisible"  ><i class="fa fa-eye  mr-1 "></i></span>
                           <span class="hideView"  *ngIf="!confirmPasswordVisible"  ><i class="fa fa-eye-slash" aria-hidden="true"></i>
                           </span>
                      
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </form>
    </div>

    <div class="modal-footer no-bg">
        <!--  -->
        <button class="btn btn-outline-dark " (click)="d('Cross click')" type="button">Cancel</button>
        <!-- [disabled]="loading" -->
        <button class="btn btn-orange" type="submit">Save</button>
        <!-- [disabled]="!e.valid" -->
    </div>
</ng-template> 
<ng-template #up let-c="close" let-d="dismiss">
  
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Profile</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
          <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
              <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
          </svg>
        </button>
    </div>
    <div class="modal-body">
        <!-- #e="ngForm" (ngSubmit)="changePasswordsubmit()" -->
        <form #e="ngForm" (ngSubmit)="UpdateUserProfile()" >
            <div class="userEditForm form-styles">
              <div class="user-image">
                <!-- <img src="assets/images/img-user.png" alt="Image Description"> -->
                <input type="file" (change)="handleFileInput($event)" accept="image/*">
                <img *ngIf="!this.imageUploadedLocaly" [src]="this.dataUriToImage()" alt="Image Description">
                <img *ngIf="this.imageUploadedLocaly" [src]="this.dataUriToImage()"alt="Uploaded Image">
              </div>
                
              <div class="row">
                <div class="col-md-12 mb-3">
                    <label for="name">Name:</label>
                    <!-- <input id="name" type="text" class="form-control" value="Umar Hayat" /> -->
                    <input type="text" class="form-control " id="name" name="name" [(ngModel)]="this.userProfileObj.name" >
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3">
                    <label for="email">Email:</label>
                    <!-- <input id="email" type="text" class="form-control" value="Umar.hayat@thetowertech.com" disabled/> -->
                    <input type="text" class="form-control " id="email" name="email" [(ngModel)]="this.userProfileObj.email" disabled>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3">
                    <label for="contact">Contact:</label>
                    <input id="contact" type="tel" name="contact" class="form-control" [(ngModel)]="this.userProfileObj.contact" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                    <label for="address">Address:</label>
                    <textarea id="address" name="address" class="form-control" cols="30" rows="10" [(ngModel)]="this.userProfileObj.address">this is address.</textarea>
                </div>
              </div>
            </div>     
            <div class="modal-footer no-bg">
              <!--  -->
              <button class="btn btn-outline-dark" (click)="d('Cross click')" type="button">Cancel</button>
              <!-- [disabled]="loading" -->
              <button class="btn btn-orange " type="submit">Save</button>
              <!-- [disabled]="!e.valid" -->
            </div>       
        </form>
    </div>
    

</ng-template> 





