import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UtilityService} from '@services/utility.service';
import * as Highcharts from 'highcharts';
@Component({
    selector: 'app-inverter-input-dc-graph',
    templateUrl: './inverter-input-dc-graph.component.html',
    styleUrls: ['./inverter-input-dc-graph.component.scss']
})
export class InverterInputDcGraphComponent implements OnInit,OnChanges{
    loading:boolean =false;
    @Input() receivedValue: string;
    Highcharts: typeof Highcharts = Highcharts;
    dtEnv;
    lineChartcollectTime: any[] = [];
    blockinfo: any[] = [];
    lineChartcollectcollect: any[] = [];
    lineChartcollectcollect1: any[] = [];
    outputPower: any[] = [];
    generation: any[] = [];
    selectedCars = [1];
    stData: number;
    setDate:string;
    gDate:string;
    currentDate = new Date().toISOString().slice(0, 10);
    receivestationResult: number;
    inverterGV = [
        {id: 1, name: 'Input'},
        {id: 2, name: 'Output'},
        {id: 3, name: 'Normalized'}
    ];
    cars = [
        {id: 1, name: 'Volvo'},
        {id: 2, name: 'Saab', disabled: true},
        {id: 3, name: 'Opel'},
        {id: 4, name: 'Audi'}
    ];

    blockOption: Highcharts.Options = {
        title: {
            text: 'Input DC Graph',

            style: {
                color: '#d38527',
                align: 'left'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true
                },
                showInLegend: true
            }
        },
        chart: {
            backgroundColor: '#FA6264'
        },
        series: [
            {
                type: 'area'
            },
            {
                type: 'area'
            },
            {
                type: 'area'
            }
        ]
    };

    constructor(private _utilityService: UtilityService) {}

    ngOnInit(): void {
        this.gDate = this.currentDate;
        this._utilityService.receiveDevid.subscribe((res) => {
            if (res) {
                this.stData = res;
            }
        });
        this._utilityService.receivestationid.subscribe((res) => {
            if (res) {
                this.receivestationResult = res;
            }
        });
        this.getDC();
    }

    ngOnChanges(){

        this._utilityService.receiveDevid.subscribe((res) => {
            if (res) {
                this.stData = res;
                // this.getVoltage();
            }
        });
    }
    toggleDisabled() {
        const car: any = this.cars[1];
        car.disabled = !car.disabled;
        if (
            this.selectedCars.includes(1) &&
            this.selectedCars.includes(2) &&
            this.selectedCars.includes(3)
        ) {
             return this.getInputOutputNormal();
        }
        if (this.selectedCars.includes(1) && this.selectedCars.includes(2)) {
             return this.getOutPutDCGraph();
        }
        if (this.selectedCars.includes(1) && this.selectedCars.includes(3)) {
             return this.getDcNormalize();
        }
        if (this.selectedCars.includes(2) && this.selectedCars.includes(3)) {
             return this.getSecondThird();
        }

        if (this.selectedCars.includes(1)) {
 
            return this.getDC();
        }
        if (this.selectedCars.includes(2)) {
 
            return this.getInverterOutPutGraph();
        }
        if (this.selectedCars.includes(3)) {
 
            return this.getNormalizedGraph();
        }
        // this.getInverterNormalGraph();
    }

    openDate(e){
     this.gDate=e;
    }

    getDC() {
        this.loading = true;
        this.lineChartcollectTime = [];
        this.lineChartcollectcollect1 = [];
        this.blockinfo = [];
        this.outputPower = [];
        this.generation = [];
        if(this.gDate){
            this.setDate = this.gDate;
        }else{
            this.setDate = this.currentDate 
        }
        this._utilityService.setInverterDcGr(this.stData,this.setDate).subscribe((res) => {
            this.loading = false;
            var blocklist = [];
            blocklist = res.data;
            blocklist.forEach((e) => {
                this.lineChartcollectTime.push(
                    e.collectTime.toString().split(' ')[1]
                );
                this.lineChartcollectcollect1.push(e.input_power);
                this.blockOption = {
                    title: {
                        text: '',
                        style: {
                            color: 'black',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.lineChartcollectTime
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },

                    series: [
                        {
                            name: 'Input Power',
                            data: this.lineChartcollectcollect1,
                            type: 'line',
                            color: '#000'
                        },
                        {
                            name: 'OutPut Power',
                            data: this.outputPower,
                            type: 'line',
                            color: 'green'
                        },
                        {
                            name: 'Normalize',
                            data: this.generation,
                            type: 'line',
                            color: 'pink'
                        }
                    ]
                };
            });
        });
    }

    getInverterOutPutGraph() {
        this.loading = true;

        this.lineChartcollectTime = [];
        this.lineChartcollectcollect = [];
        this.blockinfo = [];
        this.generation = [];
        this.outputPower = [];
        if(this.gDate){
            this.setDate = this.gDate;
        }else{
            this.setDate = this.currentDate 
        }
        this._utilityService.setInverterOPGr(this.stData,this.setDate).subscribe((res) => {
            this.loading = false;

            var blocklist = [];
            blocklist = res.data;
            blocklist.forEach((e) => {
                this.lineChartcollectTime.push(
                    e.collectTime.toString().split(' ')[1]
                );
                this.outputPower.push(e.output_power);
            });
            this.blockOption = {
                title: {
                    text: '',
                    style: {
                        color: 'black',
                        align: 'left'
                    }
                },
                xAxis: {
                    categories: this.lineChartcollectTime
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true
                        },
                        showInLegend: true
                    }
                },

                series: [
                    {
                        name: 'Output Power',
                        data: this.outputPower,
                        // data: this.lineChartcollectTime,
                        type: 'line',
                        color: 'green'
                    },
                    {
                        name: 'Input Power',
                        data: [],
                        type: 'line',
                        color: '#000'
                    },
                    {
                        name: 'Normalize',
                        data: this.generation,
                        type: 'line',
                        color: 'pink'
                    }
                ]
            };
        });
    }

    getNormalizedGraph() {
        this.loading = true;

        this.lineChartcollectTime = [];
        this.lineChartcollectcollect1 = [];
        this.blockinfo = [];
        this.outputPower = [];
        this.generation = [];
        this._utilityService
            .setNormalizdGraph(this.receivestationResult,this.setDate )
            .subscribe((res) => {
                this.loading = false;

                var blocklist = [];
                blocklist = res.data;
                blocklist.forEach((e) => {
                    this.lineChartcollectTime.push(
                        e.collectTime.toString().split(' ')[1]
                    );
                    this.lineChartcollectcollect1.push(e.generation);
                    this.blockOption = {
                        title: {
                            text: '',

                            style: {
                                color: 'black',
                                align: 'left'
                            }
                        },
                        xAxis: {
                            categories: this.lineChartcollectTime
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true
                                },
                                showInLegend: true
                            }
                        },

                        series: [
                            {
                                name: 'Normalize',
                                data: this.lineChartcollectcollect1,
                                type: 'line',
                                color: 'pink'
                            },
                            {
                                name: 'Output Power',
                                data: this.outputPower,
                                type: 'line',
                                color: 'green'
                            },
                            {
                                name: 'Normalize',
                                data: this.generation,
                                type: 'line',
                                color: 'pink'
                            }
                        ]
                    };
                });
            });
    }

    getOutPutDCGraph() {
        this.loading = true;

        this.lineChartcollectTime = [];
        this.lineChartcollectcollect = [];
        this.blockinfo = [];
        this.outputPower = [];
        this.generation = [];
        if(this.gDate){
            this.setDate = this.gDate;
        }else{
            this.setDate = this.currentDate; 
        }
        this._utilityService.setInverterDcGr(this.stData,this.setDate ).subscribe((res) => {
            this.loading = false;

            var blocklist = [];
            blocklist = res.data;
            blocklist.forEach((e) => {
                this.lineChartcollectTime.push(
                    e.collectTime.toString().split(' ')[1]
                );
                this.lineChartcollectcollect.push(e.input_power);
            });
            this._utilityService
                .setInverterOPGr(this.stData,this.setDate)
                .subscribe((res1) => {
                    var blocklistEng = [];
                    blocklistEng = res1.data;
                    blocklistEng.forEach((i) => {
                        this.outputPower.push(i.output_power);
                    });
                    this.blockOption = {
                        title: {
                            text: '',

                            style: {
                                color: 'black',
                                align: 'left'
                            }
                        },
                        xAxis: {
                            categories: this.lineChartcollectTime
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true
                                },
                                showInLegend: true
                            }
                        },

                        series: [
                            {
                                name: 'Input Power',
                                data: this.lineChartcollectcollect,
                                type: 'line',
                                color: '#000'
                            },
                            {
                                name: 'OutPut Power',
                                data: this.outputPower,
                                type: 'line',
                                color: 'green'
                            },
                            {
                                name: 'Normalize',
                                data: this.generation,
                                type: 'line',
                                color: 'pink'
                            }
                        ]
                    };
                });
        });
    }
    getDcNormalize() {
        this.loading = true;

        this.lineChartcollectTime = [];
        this.lineChartcollectcollect = [];
        this.blockinfo = [];
        this.outputPower = [];
        this.generation = [];
        if(this.gDate){
            this.setDate = this.gDate;
        }else{
            this.setDate = this.currentDate 
        }
        this._utilityService.setInverterDcGr(this.stData, this.setDate).subscribe((res) => {
            this.loading = false;

            var blocklist = [];
            blocklist = res.data;
            blocklist.forEach((e) => {
                this.lineChartcollectTime.push(
                    e.collectTime.toString().split(' ')[1]
                );
                this.lineChartcollectcollect.push(e.input_power);
            });
            this._utilityService
                .setNormalizdGraph(this.receivestationResult,this.setDate )
                .subscribe((res1) => {
                    var blocklistEng = [];
                    blocklistEng = res1.data;
                    blocklistEng.forEach((i) => {
                        this.generation.push(i.generation);
                    });
                    this.blockOption = {
                        title: {
                            text: '',

                            style: {
                                color: 'black',
                                align: 'left'
                            }
                        },
                        xAxis: {
                            categories: this.lineChartcollectTime
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true
                                },
                                showInLegend: true
                            }
                        },

                        series: [
                            {
                                name: 'Normalize',
                                data: this.generation,
                                type: 'line',
                                color: 'pink'
                            },
                            {
                                name: 'Input Power',
                                data: this.lineChartcollectcollect,
                                type: 'line',
                                color: '#000'
                            },
                            {
                                name: 'Output Power',
                                data: this.outputPower,
                                type: 'line',
                                color: 'green'
                            }
                        ]
                    };
                });
        });
    }

    getInputOutputNormal() {
        this.loading = true;

        this.lineChartcollectTime = [];
        this.lineChartcollectcollect = [];
        this.blockinfo = [];
        this.outputPower = [];
        this.generation = [];
        if(this.gDate){
            this.setDate = this.gDate;
        }else{
            this.setDate = this.currentDate 
        }
        this._utilityService.setInverterDcGr(this.stData,this.setDate ).subscribe((res) => {
            this.loading = false;

            var blocklist = [];
            blocklist = res.data;
            blocklist.forEach((e) => {
                this.lineChartcollectTime.push(
                    e.collectTime.toString().split(' ')[1]
                );
                this.lineChartcollectcollect.push(e.input_power);
            });
            this._utilityService
                .setInverterOPGr(this.stData,this.setDate )
                .subscribe((res1) => {
                    var blocklistEng = [];
                    blocklistEng = res1.data;
                    blocklistEng.forEach((i) => {
                        this.outputPower.push(i.output_power);
                    });
                });
            this._utilityService
                .setNormalizdGraph(this.receivestationResult,this.setDate )
                .subscribe((res2) => {
                    var blocklistEng1 = [];
                    blocklistEng1 = res2.data;
                    blocklistEng1.forEach((o) => {
                        this.generation.push(o.generation);
                    });
                    this.blockOption = {
                        title: {
                            text: '',

                            style: {
                                color: 'black',
                                align: 'left'
                            }
                        },
                        xAxis: {
                            categories: this.lineChartcollectTime
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true
                                },
                                showInLegend: true
                            }
                        },

                        series: [
                            {
                                name: 'Input Power',
                                data: this.lineChartcollectcollect,
                                type: 'line',
                                color: '#000'
                            },
                            {
                                name: 'Output Power',
                                data: this.outputPower,
                                type: 'line',
                                color: 'green'
                            },
                            {
                                name: 'Normalize',
                                data: this.generation,
                                type: 'line',
                                color: 'pink'
                            }
                        ]
                    };
                });
        });
    }
    getSecondThird() {
        this.loading = true;

      this.lineChartcollectTime = [];
      this.lineChartcollectcollect = [];
      this.blockinfo = [];
      this.outputPower = [];
      this.generation = [];
      if(this.gDate){
        this.setDate = this.gDate;
    }else{
        this.setDate = this.currentDate 
    }
      this._utilityService.setInverterOPGr(this.stData,this.setDate ).subscribe((res) => {
        this.loading = false;

          var blocklist = [];
          blocklist = res.data;
          blocklist.forEach((e) => {
              this.lineChartcollectTime.push(
                  e.collectTime.toString().split(' ')[1]
              );
              this.outputPower.push(e.output_power);
          });
          this._utilityService
              .setNormalizdGraph(this.receivestationResult,this.setDate)
              .subscribe((res1) => {
                  var blocklistEng = [];
                  blocklistEng = res1.data;
                  blocklistEng.forEach((i) => {
                      this.generation.push(i.generation);
                  });
                  this.blockOption = {
                      title: {
                          text: '',

                          style: {
                              color: 'black',
                              align: 'left'
                          }
                      },
                      xAxis: {
                          categories: this.lineChartcollectTime
                      },
                      plotOptions: {
                          pie: {
                              allowPointSelect: true,
                              cursor: 'pointer',
                              dataLabels: {
                                  enabled: true
                              },
                              showInLegend: true
                          }
                      },

                      series: [
                          {
                              name: 'Normalize',
                              data: this.generation,
                              type: 'line',
                              color: 'pink'
                          },
                          {
                              name: 'Input Power',
                              data: this.lineChartcollectcollect,
                              type: 'line',
                              color: '#000'
                          },
                          {
                              name: 'Output Power',
                              data: this.outputPower,
                              type: 'line',
                              color: 'green'
                          }
                      ]
                  };
              });
      });
  }
}
