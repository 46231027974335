import { SelectUser } from "@/Interface/selectUser";

export class SelectUserModel implements SelectUser{
    ticket_id:number;
    assigned_to:string




    constructor(values:Object={}){
        Object.assign(this,values)
    }
    getTicektId(){
        return this.ticket_id;
    }
    getAssignedTO(){
        return this.assigned_to;
    }
}