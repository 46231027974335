import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  selector: '[appNumberInput]'
})
export class NumberInputDirective {

  
  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const inputValue = this.el.nativeElement.value;
    const sanitizedValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric and non-decimal characters
    this.control.control.setValue(sanitizedValue);
  }
}
