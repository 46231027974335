import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilityService} from '@services/utility.service';
import {Priority} from '@/Interface/priority';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PriorityModel} from '@/model/priority';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-preority',
    templateUrl: './preority.component.html',
    styleUrls: ['./preority.component.scss']
})
export class PreorityComponent implements OnInit {
    @Input() name;
    priority: Priority;
    priUpdate: Priority;
    priAdd = new PriorityModel();
    priUpdateModel = new PriorityModel();
    reactiveFormPriority: FormGroup;
    reactiveFormPriorityAdd: FormGroup;
    loader :boolean = false;
    constructor(
        private modalService: NgbModal,
        private _utilityService: UtilityService,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        this.loader = true;
        this.getPriority();
        this.reactiveFormPriority = this.formBuilder.group({
            priorityName: new FormControl(null, Validators.required)
        });
        this.reactiveFormPriorityAdd = this.formBuilder.group({
            priorityNameadd: new FormControl(null, Validators.required)
        });
    }
    modalRef?: BsModalRef;

    open(item: Priority, content: any) {
        var getPriorityId = item.id;
        this._utilityService
            .getPriorityUpdate(getPriorityId)
            .subscribe((res) => {
                if (res) {
                    this.priUpdate = res[0];
                    this.reactiveFormPriority.patchValue({
                        priorityName: this.priUpdate.getName()
                    });
                }
            });
        this.modalService.open(content);
    }

    updatePriority() {
        this.priUpdateModel.name = this.reactiveFormPriority.value.priorityName;
        this.priUpdateModel.id = this.priUpdate.id;
        this._utilityService
            .updatePriorityList(this.priUpdateModel)
            .subscribe((res) => {
                if (res) {
                    this.getPriority();
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: '`<h2>Priority  Successfully Updated</h2>`',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            });
    }
    getPriority() {
        this._utilityService.receivePreority().subscribe((res) => {
            if (res) {
                this.priority = res;
            }

            this.loader = false;
        });
    }

    openAddModel(addModel: any) {
        this.modalService.open(addModel);
    }

    addPriorityAdd() {
        this.priAdd.name = this.reactiveFormPriorityAdd.value.priorityNameadd;
        this.loader = true;
        this._utilityService.addPriority(this.priAdd).subscribe((res) => {
            if (res) {
                this.getPriority();
                this.loader = false;
                this.reactiveFormPriorityAdd.reset();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: '`<h2>Priority Successfully Added</h2>`',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        });
    }

    deletePriority(item: Priority) {
        var deletePri = item.id;
        Swal.fire({
            title: 'Are you sure to delete this Priority?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this._utilityService
                    .deletePriortyList(deletePri)
                    .subscribe((res) => {
                        if (res) {
                            this.getPriority();
                        }
                    });
                Swal.fire('Deleted!', 'Priority has been deleted.', 'success');
            }
        });
    }
}
