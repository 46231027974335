import {Component, OnChanges, OnInit} from '@angular/core';
import {UtilityService} from '@services/utility.service';
import * as Highcharts from 'highcharts';
import {from} from 'rxjs';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-pv-chart',
    templateUrl: './pv-chart.component.html',
    styleUrls: ['./pv-chart.component.scss']
})
export class PvChartComponent implements OnInit,OnChanges {
    Highcharts: typeof Highcharts = Highcharts;

    selectedCars = [1];
    pvG = [
        {id: 1, name: 'Voltage'},
        {id: 2, name: 'Current'},
        {id: 3, name: 'Power'}
    ];
    cars = [
        {id: 1, name: 'Volvo'},
        {id: 2, name: 'Saab', disabled: true},
        {id: 3, name: 'Opel'},
        {id: 4, name: 'Audi'}
    ];
pvlenght:boolean = false;
    lineChartcollectTime: any[] = [];
    pv1: any[] = [];
    pv2: any[] = [];
    pv3: any[] = [];
    pv4: any[] = [];
    pv5: any[] = [];
    pv6: any[] = [];
    pv7: any[] = [];
    pv8: any[] = [];
    pv9: any[] = [];
    pv10: any[] = [];
    pv11: any[] = [];
    pv12: any[] = [];
    pv13: any[] = [];
    pv14: any[] = [];
    pv15: any[] = [];
    pv16: any[] = [];
    pv17: any[] = [];
    pv18: any[] = [];
    pv19: any[] = [];
    pv20: any[] = [];
    pv21: any[] = [];
    pv22: any[] = [];
    pv23: any[] = [];
    pv24: any[] = [];
    pv25: any[] = [];
    pv26: any[] = [];
    pv27: any[] = [];
    pv28: any[] = [];
    pv29: any[] = [];
    pv30: any[] = [];
    pv31: any[] = [];
    pv32: any[] = [];
    pv33: any[] = [];
    pv34: any[] = [];
    pv35: any[] = [];
    pv36: any[] = [];
    pv37: any[] = [];
    pv38: any[] = [];
    pv39: any[] = [];
    pv40: any[] = [];
    pv41: any[] = [];
    pv42: any[] = [];
    pv43: any[] = [];
    pv44: any[] = [];
    pv45: any[] = [];
    pv46: any[] = [];
    pv47: any[] = [];
    pv48: any[] = [];

    pvArrays: any[] = [];

    blockinfo: any[] = [];
    outputPower: any[] = [];
    generation: any[] = [];
    gDate: string;
    setDate: string;
    currentDate = new Date().toISOString().slice(0, 10);
    stData: number;
    loading:boolean =false;

    blockOption: Highcharts.Options = {
        title: {
            text: 'PV Graph',

            style: {
                color: '#d38527',
                align: 'left'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true
                },
                showInLegend: true
            }
        },
        chart: {
            backgroundColor: '#FA6264'
        },
        series: [
            {
                name: 'PV1',
                data: this.pv1,
                type: 'line',
                color: '#FFF8DC'
            },
            {
                name: 'PV1',
                data: this.pv2,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV3',
                data: this.pv3,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv4,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv5,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv6,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv7,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv8,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv9,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv10,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv11,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv12,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv13,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv14,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv15,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv16,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv17,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv18,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv19,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv20,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv21,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv22,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv23,
                type: 'line',
                color: 'red'
            },
            {
                name: 'PV4',
                data: this.pv24,
                type: 'line',
                color: 'red'
            },
           
        ]
    };
    blockOption2: Highcharts.Options = {
      title: {
          text: 'PV Graph',

          style: {
              color: '#d38527',
              align: 'left'
          }
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true
              },
              showInLegend: true
          }
      },
      chart: {
          backgroundColor: '#FA6264'
      },
      series: [
          {
              name: 'PV1',
              data: this.pv1,
              type: 'line',
              color: '#FFF8DC'
          },
          {
              name: 'PV1',
              data: this.pv2,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV3',
              data: this.pv3,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv4,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv5,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv6,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv7,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv8,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv9,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv10,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv11,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv12,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv13,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv14,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv15,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv16,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv17,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv18,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv19,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv20,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv21,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv22,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv23,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv24,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv25,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv26,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv27,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv28,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv29,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv30,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv31,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv32,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv33,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv34,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv35,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv36,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv37,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv38,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv39,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv40,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv41,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv42,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv43,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv44,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv45,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv46,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv47,
              type: 'line',
              color: 'red'
          },
          {
              name: 'PV4',
              data: this.pv48,
              type: 'line',
              color: 'red'
          }
      ]
  };
    constructor(private _utilityService: UtilityService) {}
    ngOnInit(): void {
        

        this._utilityService.receiveDevid.subscribe((res) => {
            if (res) {
                this.stData = res;
                 this.getVoltage();
            }
        });
        this.gDate = this.currentDate;
    }
    ngOnChanges(){

        this._utilityService.receiveDevid.subscribe((res) => {
            if (res) {
                this.stData = res;
                // this.getVoltage();
            }
        });
    }
    toggleDisabled() {
        const car: any = this.cars[1];
        car.disabled = !car.disabled;
        if (
            this.selectedCars.includes(1) &&
            this.selectedCars.includes(2) &&
            this.selectedCars.includes(3)
        ) {
            alert('You cannot select more than 2 parameters.');
             return;
         }
        if (this.selectedCars.includes(1) && this.selectedCars.includes(2)) {
            this.getBothVoltageCurrent();
            return;
        }
        if (this.selectedCars.includes(1) && this.selectedCars.includes(3)) {
            return this.getBothVoltagePower();
        }
        if (this.selectedCars.includes(2) && this.selectedCars.includes(3)) {
           return this.getBothCurrentPower();
        }
        if (this.selectedCars.includes(1)) {
            return this.getVoltage();
        }
        if (this.selectedCars.includes(2)) {
            return this.getCurrentValues();
        }
        if (this.selectedCars.includes(3)) {
            return this.getPVPowerGraph();
        }
        // this.getInverterNormalGraph();
    }
    openDate(e){
        this.gDate=e;
       }
    getVoltage() {
        this.loading = true;

      this.pvlenght =true;
        this.lineChartcollectTime = [];
        this.pvArrays = [];
        this.blockinfo = [];
        if (this.gDate) {
            this.setDate = this.gDate;
        } else {
            this.setDate = this.currentDate;
        }
        this._utilityService
            .getPvVoltageGraph(this.stData, this.setDate)
            .subscribe((res) => {
                this.loading = false;

                for (let i = 1; i <= 24; i++) {
                    var pvArray = res.data[`pv${i}`];
                    this.pvArrays[i] = [];
                    for (let j = 0; j < pvArray.length; j++) {
                        this.lineChartcollectTime.push(
                            pvArray[j].collectTime.toString().split(' ')[1]
                        );
                        this.pvArrays[i].push(pvArray[j].voltage);
                    }
                }
                this.blockOption = {
                    title: {
                        text: '',
                        style: {
                            color: '#ffffff',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.lineChartcollectTime
                    },
                    plotOptions: {
                        line: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },
                    series: []
                };
                for (let i = 1; i <= 24; i++) {
                    this.blockOption.series.push({
                        name: `Voltage PV${i}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 16777215).toString(16)
                    });
                }
            });
    }

    getCurrentValues() {
        this.loading = true;

      this.pvlenght =true;
        this.lineChartcollectTime = [];
        this.pvArrays = [];
        this.blockinfo = [];
        if (this.gDate) {
            this.setDate = this.gDate;
        } else {
            this.setDate = this.currentDate;
        }
        this._utilityService
            .getPVcurrentValues(this.stData, this.setDate)
            .subscribe((res) => {
                this.loading = false;

                for (let i = 1; i <= 24; i++) {
                    var pvArray = res.data[`pv${i}`];
                    this.pvArrays[i] = [];
                    for (let j = 0; j < pvArray.length; j++) {
                        this.lineChartcollectTime.push(
                            pvArray[j].collectTime.toString().split(' ')[1]
                        );
                        this.pvArrays[i].push(pvArray[j].current);
                    }
                }
                this.blockOption = {
                    title: {
                        text: '',
                        style: {
                            color: '#ffffff',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.lineChartcollectTime
                    },
                    plotOptions: {
                        line: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },
                    series: []
                };
                for (let i = 1; i <= 24; i++) {
                    this.blockOption.series.push({
                        name: `Current PV${i}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 16777215).toString(16)
                    });
                }
            });
    }

    getPVPowerGraph() {
        this.loading = true;

      this.pvlenght =true;
        this.lineChartcollectTime = [];
        this.pvArrays = [];
        this.blockinfo = [];
        if (this.gDate) {
            this.setDate = this.gDate;
        } else {
            this.setDate = this.currentDate;
        }
        this._utilityService
            .getPVPowerValues(this.stData, this.setDate)
            .subscribe((res) => {
                this.loading = false;

                for (let i = 1; i <= 24; i++) {
                    var pvArray = res.data[`pv${i}`];
                    this.pvArrays[i] = [];
                    for (let j = 0; j < pvArray.length; j++) {
                        this.lineChartcollectTime.push(
                            pvArray[j].collectTime.toString().split(' ')[1]
                        );
                        this.pvArrays[i].push(pvArray[j].power);
                    }
                }
                this.blockOption = {
                    title: {
                        text: '',
                        style: {
                            color: '#ffffff',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.lineChartcollectTime
                    },
                    plotOptions: {
                        line: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },
                    series: []
                };
                for (let i = 1; i <= 24; i++) {
                    this.blockOption.series.push({
                        name: `Power PV${i}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 16777215).toString(16)
                    });
                }
            });
    }

    getBothVoltageCurrent() {
        this.loading = true;

      this.pvlenght =false;
        this.lineChartcollectTime = [];
        this.pvArrays = [];
        this.blockinfo = [];
        if (this.gDate) {
            this.setDate = this.gDate;
        } else {
            this.setDate = this.currentDate;
        }
        this._utilityService
            .getBothVoltageCurrentsend(this.stData, this.setDate)
            .subscribe((res) => {
                this.loading = false;

                for (let i = 1; i <= 48; i++) {
                    var pvArray = res.data[`pv${i}`];
                    this.pvArrays[i] = [];
                    for (let j = 0; j < pvArray.length; j++) {
                        this.lineChartcollectTime.push(
                            pvArray[j].collectTime.toString().split(' ')[1]
                        );
                        this.pvArrays[i].push(pvArray[j].voltage);
                    }
                }
                this.blockOption2 = {
                    title: {
                        text: '',
                        style: {
                            color: '#ffffff',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.lineChartcollectTime
                    },
                    plotOptions: {
                        line: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },
                    series: []
                };
                for (let i = 1; i <= 24; i++) {
                    this.blockOption2.series.push({
                        name: `Voltage PV${i}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 16777215).toString(16)
                    });
                }
                for (let i = 25; i <= 48; i++) {
                    this.blockOption2.series.push({
                        name: `Current PV${i - 24}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 11177215).toString(16)
                    });
                }
            });
    }

    getBothVoltagePower() {
        this.loading = true;

      this.pvlenght =false;

        this.lineChartcollectTime = [];
        this.pvArrays = [];
        this.blockinfo = [];
        if (this.gDate) {
            this.setDate = this.gDate;
        } else {
            this.setDate = this.currentDate;
        }
        this._utilityService
            .getBothVoltagePowersend(this.stData, this.setDate)
            .subscribe((res) => {
                this.loading = false;

                for (let i = 1; i <= 48; i++) {
                    var pvArray = res.data[`pv${i}`];
                    this.pvArrays[i] = [];
                    for (let j = 0; j < pvArray.length; j++) {
                        this.lineChartcollectTime.push(
                            pvArray[j].collectTime.toString().split(' ')[1]
                        );
                        this.pvArrays[i].push(pvArray[j].voltage);
                    }
                }
                this.blockOption2 = {
                    title: {
                        text: '',
                        style: {
                            color: '#ffffff',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.lineChartcollectTime
                    },
                    plotOptions: {
                        line: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },
                    series: []
                };
                for (let i = 1; i <= 24; i++) {
                    this.blockOption2.series.push({
                        name: `Voltage PV${i}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 16777215).toString(16)
                    });
                }
                for (let i = 25; i <= 48; i++) {
                    this.blockOption2.series.push({
                        name: `Power PV${i - 24}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 11177215).toString(16)
                    });
                }
            });
    }
    getBothCurrentPower() {
        this.loading = true;

      this.pvlenght =false;

        this.lineChartcollectTime = [];
        this.pvArrays = [];
        this.blockinfo = [];
        if (this.gDate) {
            this.setDate = this.gDate;
        } else {
            this.setDate = this.currentDate;
        }
        this._utilityService
            .getBothCurrentPowersend(this.stData, this.setDate)
            .subscribe((res) => {
                this.loading = false;

                for (let i = 1; i <= 48; i++) {
                    var pvArray = res.data[`pv${i}`];
                    this.pvArrays[i] = [];
                    for (let j = 0; j < pvArray.length; j++) {
                        this.lineChartcollectTime.push(
                            pvArray[j].collectTime.toString().split(' ')[1]
                        );
                        this.pvArrays[i].push(pvArray[j].current);
                    }
                }
                this.blockOption2 = {
                    title: {
                        text: '',
                        style: {
                            color: '#ffffff',
                            align: 'left'
                        }
                    },
                    xAxis: {
                        categories: this.lineChartcollectTime
                    },
                    plotOptions: {
                        line: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true
                            },
                            showInLegend: true
                        }
                    },
                    series: []
                };
                for (let i = 1; i <= 24; i++) {
                    this.blockOption2.series.push({
                        name: `Current PV${i}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 16777215).toString(16)
                    });
                }
                for (let i = 25; i <= 48; i++) {
                    this.blockOption2.series.push({
                        name: `Power PV${i - 24}`,
                        data: this.pvArrays[i],
                        type: 'line',
                        // color: `#0f${i}f${i}f`,
                        color:
                            `#` +
                            Math.floor(Math.random() * 11177215).toString(16)
                    });
                }
            });
    }
}
