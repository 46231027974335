import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { PlantsComponent } from '@pages/plants/plants.component';
import { PlantDetailComponent } from '@pages/plant-detail/plant-detail.component';
import { HelpDeskDashboardComponent } from '@pages/help-desk-dashboard/help-desk-dashboard.component';
import { CommingSoonComponent } from '@pages/comming-soon/comming-soon.component';
import { TicketListComponent } from '@pages/ticket-list/ticket-list.component';
import { AddTicketComponent } from '@pages/add-ticket/add-ticket.component';
import { PreorityComponent } from '@pages/preority/preority.component';
import { SourceComponent } from '@pages/source/source.component';
import { CategoryComponent } from '@pages/category/category.component';
import { SubCategoryComponent } from '@pages/sub-category/sub-category.component';
import { UserListComponent } from '@pages/user-list/user-list.component';
import { AlarmComponent } from '@pages/alarm/alarm.component';
import { PenalComponent } from '@modules/penal/penal.component';
import { ResetpasswordComponent } from '@modules/resetpassword/resetpassword.component';
import { PrivacyPolicyComponent } from '@pages/privacy-policy/privacy-policy.component';
import { EmailComponent } from '@pages/email/email.component';
import { HistoricalAlarmsComponent } from '@pages/historical-alarms/historical-alarms.component';
import { InactiveUserListComponent } from '@pages/inactive-user-list/inactive-user-list.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'plants',
                component: BlankComponent
            },
            {
                path: 'plant-detail',
                component: PlantDetailComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: '',
                component: DashboardComponent
            },{
    
                path: 'help-desk-dashboard',
                component: HelpDeskDashboardComponent,
                
            },
            {
    
                path: 'ticket-list',
                component: TicketListComponent,
                
            },
            {
    
                path: 'add-ticket',
                component: AddTicketComponent,
                
            },
            {
    
                path: 'priority',
                component: PreorityComponent,
                
            },
            {
    
                path: 'source',
                component: SourceComponent,
                
            },
            {
    
                path: 'category',
                component: CategoryComponent,
                
            },
            {
    
                path: 'sub-category',
                component: SubCategoryComponent,
                
            },
             
            {
    
                path: 'user',
                component: UserListComponent,
                
            },
            {
                path: 'inactive-user',
                component: InactiveUserListComponent,  
            },
            {
    
                path: 'alarm',
                component: AlarmComponent,
                
            },
            {
    
                path: 'email',
                component: EmailComponent,
                
            }, 
            {
    
                path: 'historical-alarms',
                component: HistoricalAlarmsComponent,
                
            },          
        
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'plants',
        component: PlantsComponent,
        canActivate: [NonAuthGuard]
    },
     
    {
    
        path: 'PrivacyPolicy',
        component: PrivacyPolicyComponent,
        
    },
    // {
    //     path: 'dashboard',
    //     component: MainComponent,
    //     canActivate: [NonAuthGuard]
    // },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'panel',
        component: PenalComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'login/resetPassword',
        component: ResetpasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];
@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
export const routingComponent = [ LoginComponent, DashboardComponent, PlantsComponent,CommingSoonComponent]