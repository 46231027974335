<style>
.canvasjs-chart-credit
{
    display: none !important;
}

</style>

<section class="container-fluid" style="display: none">    
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between" >
                <h3 class="mb-sm-0">Dashboard</h3>

                <!-- <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="javascript: void(0);">Layouts</a></li>
                        <li class="breadcrumb-item active">Vertical</li>
                    </ol>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-md-6 col-xl-4">
                <div class="card mb-3 widget-content bg-vicious-stance">
                    <div class="widget-content-wrapper text-white">
                         
                            <div class="widget-heading">  Total Plants </div>
                        
                       
                            <div class="widget-numbers text-white"><span *ngFor="let item of pdetail"> 
                                {{ item.total_plants }} 
                             </span></div>
                        
                    </div>
                </div>
            </div> -->
        <div class="col-md-6 col-xl-4">
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="fw-medium text-muted mb-0">
                                Total Plants
                            </p>
                            <h2 class="mt-4 ff-secondary fw-semibold">
                                <span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.total_plants }} </span>#
                            </h2>
                            <p class="mb-0 text-muted">
                                <span class="badge bg-light text-success mb-0">
                                    <i class="ri-arrow-up-line align-middle"></i>
                                    16.24 %
                                </span>
                                vs. previous month
                            </p>
                        </div>
                        <div>
                            <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-home text-info">
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end card body -->
            </div>
        </div>
        <div class="col-md-6 col-xl-4">
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="fw-medium text-muted mb-0">
                                Today Generation
                            </p>
                            <h2 class="mt-4 ff-secondary fw-semibold">
                                <span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.today_generation | number: '1.0-0'
                                    }} </span>MWh
                            </h2>
                            <p class="mb-0 text-muted">
                                <span class="badge bg-light text-success mb-0">
                                    <i class="ri-arrow-up-line align-middle"></i>
                                    16.24 %
                                </span>
                                vs. previous month
                            </p>
                        </div>
                        <div>
                            <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-zap text-info">
                                        <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end card body -->
            </div>
        </div>

        <div class="col-md-6 col-xl-4">
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="fw-medium text-muted mb-0">
                                Total Generation
                            </p>
                            <h2 class="mt-4 ff-secondary fw-semibold">
                                <span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.total_generation | number: '1.0-0'
                                    }} </span>GWh
                            </h2>
                            <p class="mb-0 text-muted">
                                <span class="badge bg-light text-success mb-0">
                                    <i class="ri-arrow-up-line align-middle"></i>
                                    16.24 %
                                </span>
                                vs. previous month
                            </p>
                        </div>
                        <div>
                            <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-zap text-info">
                                        <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-4">
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="fw-medium text-muted mb-0">
                                Total Capacity
                            </p>
                            <h2 class="mt-4 ff-secondary fw-semibold">
                                <span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.total_capacity | number }} </span>MWp
                            </h2>
                            <p class="mb-0 text-muted">
                                <span class="badge bg-light text-success mb-0">
                                    <i class="ri-arrow-up-line align-middle"></i>
                                    16.24 %
                                </span>
                                vs. previous month
                            </p>
                        </div>
                        <div>
                            <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-battery-charging text-info">
                                        <path
                                            d="M5 18H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3.19M15 6h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3.19">
                                        </path>
                                        <line x1="23" y1="13" x2="23" y2="11"></line>
                                        <polyline points="11 6 7 12 13 12 9 18"></polyline>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xl-4">
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="fw-medium text-muted mb-0">
                                Monthly Power
                            </p>
                            <h2 class="mt-4 ff-secondary fw-semibold">
                                <span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.month_power | number }} </span>W
                            </h2>
                            <p class="mb-0 text-muted">
                                <span class="badge bg-light text-success mb-0">
                                    <i class="ri-arrow-up-line align-middle"></i>
                                    16.24 %
                                </span>
                                vs. previous month
                            </p>
                        </div>
                        <div>
                            <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-battery-charging text-info">
                                        <path
                                            d="M5 18H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3.19M15 6h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3.19">
                                        </path>
                                        <line x1="23" y1="13" x2="23" y2="11"></line>
                                        <polyline points="11 6 7 12 13 12 9 18"></polyline>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xl-4">
            <div class="card card-animate">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="fw-medium text-muted mb-0">Users</p>
                            <h2 class="mt-4 ff-secondary fw-semibold">
                                <span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.total_users }}
                                </span>
                            </h2>
                            <p class="mb-0 text-muted">
                                <span class="badge bg-light text-success mb-0">
                                    <i class="ri-arrow-up-line align-middle"></i>
                                    16.24 %
                                </span>
                                vs. previous month
                            </p>
                        </div>
                        <div>
                            <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-user text-info">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-between">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title mb-0">City Wise</h3>
                </div>
                <div class="card-body p-4 text-center">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="blockOption" style="
                            width: 450px;
                            height: 400px;
                            display: block;
                            margin: 0px 0px;
                        "></highcharts-chart>
                </div>
                <div class="card-footer text-center">
                    <div class="w-100 text-center card-title ">
                       <h3 class="mb-0"> Total Cities: {{ cityLength }}</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title mb-0">Provinces Wise</h3>
                </div>
                <div class="card-body p-4 text-center">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="blockOption2" style="
                            width: 450px;
                            height: 400px;
                            display: block;
                            margin: 0px 0px;
                        "></highcharts-chart>
                </div>
                <div class="card-footer text-center">
                    <div class="w-100 text-center card-title ">
                        <h3 class="mb-0">Total Province: {{ this.proLength }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="dashboard-main container-fluid">
    <div class="breadcrumb-main"> 
        <ul class="breadcrumb">
            <li><a href="./">Dashboard</a></li>
        </ul>
    </div>
    <div class="contents-main">
        <div class="row">
            <div class="col-xxl-5">
                <div class="row">
                    <div class="col-lg-6 mb-3">
                        <div class="card-box h-100 add-styles m-0">
                            <div class="card-header">
                                <h3>Total Plants</h3>
                            </div>
                            <div class="card-content">
                                <!-- 350 #  -->
                                <h3>
                                    <span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                        item.total_plants }} 
                                </span>
                                </h3>
                                <i class="ico-bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.809" height="23.122" viewBox="0 0 20.809 23.122">
                                        <path id="Path_5371" data-name="Path 5371" d="M8.312,27.121H24.5a2.312,2.312,0,0,0,2.312-2.312V14.4a1.156,1.156,0,0,0-.335-.821L17.225,4.335a1.156,1.156,0,0,0-1.63,0L6.347,13.583A1.156,1.156,0,0,0,6,14.4v10.4A2.312,2.312,0,0,0,8.312,27.121Zm5.78-2.312v-5.78h4.624v5.78Zm-5.78-9.931L16.4,6.785,24.5,14.878v9.931H21.029v-5.78a2.312,2.312,0,0,0-2.312-2.312H14.092a2.312,2.312,0,0,0-2.312,2.312v5.78H8.312Z" transform="translate(-6 -3.998)" fill="#f15f12"/>
                                    </svg>                              
                                </i>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-6 mb-3">
                        <div class="card-box h-100 add-styles m-0">
                            <div class="card-header">
                                <h3>Total Capacity</h3>
                            </div>
                            <div class="card-content">
                                <h3><span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.total_capacity.toFixed(2) }}
                            </span> MWp</h3>
                                <i class="ico-bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.574" height="14.447" viewBox="0 0 22.574 14.447">
                                    <g id="battery-charging" transform="translate(-6 -12)">
                                        <path id="Path_5424" data-name="Path 5424" d="M35.724,14.709h-.9v-.9A1.806,1.806,0,0,0,33.015,12H29.4v1.806h3.612v2.709h2.709v5.418H33.015v2.709H28.5v1.806h4.515a1.806,1.806,0,0,0,1.806-1.806v-.9h.9a1.806,1.806,0,0,0,1.806-1.806V16.515A1.806,1.806,0,0,0,35.724,14.709Z" transform="translate(-8.956 0)" fill="#f15f12"/>
                                        <path id="Path_5425" data-name="Path 5425" d="M12.321,24.641H7.806V13.806h5.418V12H7.806A1.806,1.806,0,0,0,6,13.806V24.641a1.806,1.806,0,0,0,1.806,1.806h4.515Z" transform="translate(0 0)" fill="#f15f12"/>
                                        <path id="Path_5426" data-name="Path 5426" d="M18.967,26.295,17.5,25.247,21,20.353H14.055L20.24,12.57l1.409,1.12-3.856,4.858H24.5Z" transform="translate(-3.206 -0.227)" fill="#f15f12"/>
                                    </g>
                                    </svg>                                                          
                                </i>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                        <div class="card-box h-100 add-styles m-0">
                            <div class="card-header">
                                <h3>Today Genration</h3>
                            </div>
                            <div class="card-content">
                                <h3><span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.today_generation }}
                            </span></h3>
                                <i class="ico-bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.574" height="14.447" viewBox="0 0 22.574 14.447">
                                    <g id="battery-charging" transform="translate(-6 -12)">
                                        <path id="Path_5424" data-name="Path 5424" d="M35.724,14.709h-.9v-.9A1.806,1.806,0,0,0,33.015,12H29.4v1.806h3.612v2.709h2.709v5.418H33.015v2.709H28.5v1.806h4.515a1.806,1.806,0,0,0,1.806-1.806v-.9h.9a1.806,1.806,0,0,0,1.806-1.806V16.515A1.806,1.806,0,0,0,35.724,14.709Z" transform="translate(-8.956 0)" fill="#f15f12"/>
                                        <path id="Path_5425" data-name="Path 5425" d="M12.321,24.641H7.806V13.806h5.418V12H7.806A1.806,1.806,0,0,0,6,13.806V24.641a1.806,1.806,0,0,0,1.806,1.806h4.515Z" transform="translate(0 0)" fill="#f15f12"/>
                                        <path id="Path_5426" data-name="Path 5426" d="M18.967,26.295,17.5,25.247,21,20.353H14.055L20.24,12.57l1.409,1.12-3.856,4.858H24.5Z" transform="translate(-3.206 -0.227)" fill="#f15f12"/>
                                    </g>
                                    </svg>                                                          
                                </i>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                        <div class="card-box h-100 add-styles m-0">
                            <div class="card-header">
                                <h3>Total Generation</h3>
                            </div>
                            <div class="card-content">
                                <h3><span class="counter-value" data-target="28.05" *ngFor="let item of pdetail">{{
                                    item.total_generation }}
                            </span> </h3>
                                <i class="ico-bottom">
                                    <svg id="Group_2241" data-name="Group 2241" xmlns="http://www.w3.org/2000/svg" width="20.583" height="29.94" viewBox="0 0 20.583 29.94">
                                    <path id="Path_5419" data-name="Path 5419" d="M23.874.127A.936.936,0,0,1,24.3,1.212l-3.37,10.95h6.218a.936.936,0,0,1,.681,1.577l-14.969,15.9a.936.936,0,0,1-1.576-.917l3.37-10.952H8.435A.936.936,0,0,1,7.754,16.2L22.723.293A.936.936,0,0,1,23.874.125ZM10.6,15.9h5.32a.936.936,0,0,1,.894,1.211l-2.56,8.314,10.725-11.4H19.662a.936.936,0,0,1-.894-1.211l2.56-8.314Z" transform="translate(-7.499 0.001)" fill="#f15f12"/>
                                    </svg>
                                </i>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
            <div class="col-xxl-7">
                <div class="row">
                    <div class="col-lg-6 mb-3">
                        <div class="card-box h-100 m-0 add-styles">
                            
                            <div class="card-content">
                                <div class="status-box">
                                    <!-- <table style="font-size: large; padding-left: 50px; padding-right: 100px; ">
                                        <thead>
                                            <th style=" padding-right: 10px;">
                                                Plant Status
                                            </th>
                                            
                                        
                                            <th style=" padding-right: 100px;">
                                                Active Alarms
                                            </th>
                                        </thead>
                                        <tbody>
                                        <td style="padding-right: 150px; ">
                                            <div class="status-holder">
                                                <div class="box-content">
                                                    <ul class="bullet-list">
                                                        <li>
                                                            <i class="bullet"></i>
                                                            <div class="text-box" >
                                                            
                                                                <span class="text-16 d-block">{{
                                                                    this.PlantSts.normal }}</span>
                                                                <span class="text-12 d-block">Normal</span>
                                                            </div>
                                                        </li>
                                                        <li style="padding-top: 20px;">
                                                            <i class="bullet" style="background: #FA2A2D; border-color: #FFBEBF;"></i>
                                                            <div class="text-box">
                                                                <span class="text-16 d-block">{{
                                                                    this.PlantSts.faulty }}</span>
                                                                <span class="text-12 d-block">faulty</span>
                                                            </div>
                                                        </li>
                                                        <li style="padding-top: 20px;">
                                                            <i class="bullet" style="background: #6D6D6D; border-color: #D5D5D5;"></i>
                                                            <div class="text-box">
                                                                
                                                                    <span class="text-16 d-block" >{{
                                                                        this.PlantSts.offline }}</span>
                                                                    <span class="text-12 d-block">offline</span>
                                                                
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            
                                            </div>
                                        </td>
                                        
                                        <td style=" padding-right: 100px;">
                                            <div class="status-holder">
                                                <div class="box-content">
                                                    <ul class="bullet-list">
                                                        <li style="padding-top: 20px;">
                                                            <i class="ico">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.736" height="17.099" viewBox="0 0 17.736 17.099">
                                                                    <path id="Subtraction_10" data-name="Subtraction 10" d="M-97.266,17.1H-115V17.07c0-.488,0-.95.023-1.436,0-.066.084-.131.157-.18a6.7,6.7,0,0,0,1.916-1.826,4.756,4.756,0,0,0,.857-2.839,23.109,23.109,0,0,1,.248-3.741,8.174,8.174,0,0,1,.856-2.726,4.718,4.718,0,0,1,3.177-2.547.084.084,0,0,1,.068-.022.936.936,0,0,0,.113-.36.723.723,0,0,1,.067-.316A1.477,1.477,0,0,1-106.133,0a1.348,1.348,0,0,1,.214.017,1.573,1.573,0,0,1,1.262,1.532.3.3,0,0,0,.113.2,5.152,5.152,0,0,1,3.786,3.921,12.706,12.706,0,0,1,.473,3.065c.022.426.034.86.045,1.279s.023.86.045,1.291a4.792,4.792,0,0,0,1.51,3.132,11.354,11.354,0,0,0,.92.756c.12.092.244.186.364.281a10.186,10.186,0,0,1,.135,1.622Zm-7.38-11.929a.251.251,0,0,0-.182.078l-4,4.245a.251.251,0,0,0-.047.27.248.248,0,0,0,.228.151h1.661l-.9,2.923a.249.249,0,0,0,.113.289.251.251,0,0,0,.126.034.25.25,0,0,0,.181-.078l4-4.245a.25.25,0,0,0,.047-.27.249.249,0,0,0-.229-.151h-1.659l.9-2.922a.249.249,0,0,0-.113-.29A.246.246,0,0,0-104.646,5.17Z" transform="translate(115.001 0)" fill="#ee2a2a"/>
                                                                </svg>                                                  
                                                            </i>
                                                            <div class="text-box">
                                                                <span class="text-16 d-block">{{
                                                                    this.alarmSts.Critical }}</span>
                                                                <span class="text-12 d-block">Critical</span>
                                                            </div>
                                                        </li>
                                                        <li style="padding-top: 20px;"> 
                                                            <i class="ico" style="background: #FFDDB4;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.33" height="19.389" viewBox="0 0 13.33 19.389">
                                                                    <path id="Path_5439" data-name="Path 5439" d="M33.1.079a.606.606,0,0,1,.275.7L31.2,7.873h4.027a.606.606,0,0,1,.441,1.022l-9.694,10.3a.606.606,0,0,1-1.02-.594l2.182-7.092H23.1a.606.606,0,0,1-.441-1.022L32.357.187A.606.606,0,0,1,33.1.078Z" transform="translate(-22.498 0.004)" fill="#fb8c04"/>
                                                                </svg>                                                 
                                                                
                                                            </i>
                                                            <div class="text-box">
                                                                <span class="text-16 d-block" >{{
                                                                    this.alarmSts.Major }}</span>
                                                                <span class="text-12 d-block">Major</span>
                                                            </div>
                                                        </li>
                                                        <li style="padding-top: 20px;">
                                                            <i class="ico" class="ico" style="background: #FFF1C8;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="3.864" height="21.254" viewBox="0 0 3.864 21.254">
                                                                    <path id="Path_5440" data-name="Path 5440" d="M21.01,8.126a1.932,1.932,0,1,1,3.845,0L24.1,20.395a1.179,1.179,0,0,1-2.346,0ZM21,25.322a1.932,1.932,0,1,1,1.932,1.932A1.932,1.932,0,0,1,21,25.322Z" transform="translate(-21 -6)" fill="#ffbf00"/>
                                                                </svg>                                                
                                
                                                            </i>
                                                            <div class="text-box">
                                                                
                                                                <span class="text-16 d-block">{{
                                                                    this.alarmSts.Minor }}</span>
                                                                    <span class="text-12 d-block">Minor</span>
                                                                
                                                            </div>
                                                        </li>
                                                        <li style="padding-top: 20px;">
                                                            <i class="ico" class="ico" style="background: #AADBFF;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="3.864" height="21.254" viewBox="0 0 3.864 21.254">
                                                                    <path id="Path_5440" data-name="Path 5440" d="M21.01,8.126a1.932,1.932,0,1,1,3.845,0L24.1,20.395a1.179,1.179,0,0,1-2.346,0ZM21,25.322a1.932,1.932,0,1,1,1.932,1.932A1.932,1.932,0,0,1,21,25.322Z" transform="translate(-21 -6)" fill="#32a8fe"/>
                                                                </svg>
                                                                
                                                                
                                                            </i>
                                                            <div class="text-box">
                                                                
                                                                <span class="text-16 d-block">{{
                                                                    this.alarmSts.Warning }}</span>
                                                                    <span class="text-12 d-block">Warning</span>
                                                                
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            
                                            
                                                
                                            
                
                                            </div>
                                        </td>
                                        </tbody>
                                    
                                    </table> -->
                                    <h4>Plant Status</h4>
                                    <div class="status-holder p-0">
                                        <div class="box-content">
                                            <ul class="bullet-list">
                                                <li>
                                                    <i class="bullet"></i>
                                                    <div class="text-box">
                                                    
                                                        <span class="text-16 d-block">{{
                                                            this.PlantSts.normal }}</span>
                                                        <span class="text-12 d-block">Normal</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i class="bullet" style="background: #FA2A2D; border-color: #FFBEBF;"></i>
                                                    <div class="text-box">
                                                        <span class="text-16 d-block">{{
                                                            this.PlantSts.faulty }}</span>
                                                        <span class="text-12 d-block">faulty</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i class="bullet" style="background: #6D6D6D; border-color: #D5D5D5;"></i>
                                                    <div class="text-box">
                                                        
                                                            <span class="text-16 d-block" >{{
                                                                this.PlantSts.offline }}</span>
                                                            <span class="text-12 d-block">offline</span>
                                                        
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="graph-holder">
                                            <ngx-charts-pie-chart
                                                [view]="[200, 200]"
                                                [scheme]="{ domain: ['#6D6D6D', '#FA2A2D', '#41BA41'] }"
                                                [results]="seriesPlant"
                                                [doughnut]="true"
                                                [tooltipText]="labelFormatting">                                                                            
                                            </ngx-charts-pie-chart>   
                                            <div class="text-box donut-chart-label addition">
                                                <div class="holder"> 
                                                    <strong>{{
                                                        this.PlantSts.total_plants }}</strong><br>
                                                    <span>Total Plants</span>
                                                </div>
                                            </div>
                                        </div>                
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-6 mb-3">
                        <div class="card-box h-100 m-0 add-styles">
                            <div class="card-content">
                                <div class="status-box mb-0">
                                    <h4>Active Alarms</h4>
                                    <div class="status-holder p-0">
                                        <div class="box-content">
                                            <ul class="bullet-list">
                                                <li>
                                                    <i class="ico">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.736" height="17.099" viewBox="0 0 17.736 17.099">
                                                            <path id="Subtraction_10" data-name="Subtraction 10" d="M-97.266,17.1H-115V17.07c0-.488,0-.95.023-1.436,0-.066.084-.131.157-.18a6.7,6.7,0,0,0,1.916-1.826,4.756,4.756,0,0,0,.857-2.839,23.109,23.109,0,0,1,.248-3.741,8.174,8.174,0,0,1,.856-2.726,4.718,4.718,0,0,1,3.177-2.547.084.084,0,0,1,.068-.022.936.936,0,0,0,.113-.36.723.723,0,0,1,.067-.316A1.477,1.477,0,0,1-106.133,0a1.348,1.348,0,0,1,.214.017,1.573,1.573,0,0,1,1.262,1.532.3.3,0,0,0,.113.2,5.152,5.152,0,0,1,3.786,3.921,12.706,12.706,0,0,1,.473,3.065c.022.426.034.86.045,1.279s.023.86.045,1.291a4.792,4.792,0,0,0,1.51,3.132,11.354,11.354,0,0,0,.92.756c.12.092.244.186.364.281a10.186,10.186,0,0,1,.135,1.622Zm-7.38-11.929a.251.251,0,0,0-.182.078l-4,4.245a.251.251,0,0,0-.047.27.248.248,0,0,0,.228.151h1.661l-.9,2.923a.249.249,0,0,0,.113.289.251.251,0,0,0,.126.034.25.25,0,0,0,.181-.078l4-4.245a.25.25,0,0,0,.047-.27.249.249,0,0,0-.229-.151h-1.659l.9-2.922a.249.249,0,0,0-.113-.29A.246.246,0,0,0-104.646,5.17Z" transform="translate(115.001 0)" fill="#ee2a2a"/>
                                                        </svg>                                                  
                                                    </i>
                                                    <div class="text-box">
                                                        <span class="text-16 d-block">{{
                                                            this.alarmSts.Critical }}</span>
                                                        <span class="text-12 d-block">Critical</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i class="ico" style="background: #FFDDB4;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.33" height="19.389" viewBox="0 0 13.33 19.389">
                                                            <path id="Path_5439" data-name="Path 5439" d="M33.1.079a.606.606,0,0,1,.275.7L31.2,7.873h4.027a.606.606,0,0,1,.441,1.022l-9.694,10.3a.606.606,0,0,1-1.02-.594l2.182-7.092H23.1a.606.606,0,0,1-.441-1.022L32.357.187A.606.606,0,0,1,33.1.078Z" transform="translate(-22.498 0.004)" fill="#fb8c04"/>
                                                        </svg>                                                 
                                                        
                                                    </i>
                                                    <div class="text-box">
                                                        <span class="text-16 d-block" >{{
                                                            this.alarmSts.Major }}</span>
                                                        <span class="text-12 d-block">Major</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <i class="ico" class="ico" style="background: #FFF1C8;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="3.864" height="21.254" viewBox="0 0 3.864 21.254">
                                                            <path id="Path_5440" data-name="Path 5440" d="M21.01,8.126a1.932,1.932,0,1,1,3.845,0L24.1,20.395a1.179,1.179,0,0,1-2.346,0ZM21,25.322a1.932,1.932,0,1,1,1.932,1.932A1.932,1.932,0,0,1,21,25.322Z" transform="translate(-21 -6)" fill="#ffbf00"/>
                                                        </svg>                                                
                        
                                                    </i>
                                                    <div class="text-box">
                                                        
                                                        <span class="text-16 d-block">{{
                                                            this.alarmSts.Minor }}</span>
                                                            <span class="text-12 d-block">Minor</span>
                                                        
                                                    </div>
                                                </li>
                                                <li>
                                                    <i class="ico" class="ico" style="background: #AADBFF;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="3.864" height="21.254" viewBox="0 0 3.864 21.254">
                                                            <path id="Path_5440" data-name="Path 5440" d="M21.01,8.126a1.932,1.932,0,1,1,3.845,0L24.1,20.395a1.179,1.179,0,0,1-2.346,0ZM21,25.322a1.932,1.932,0,1,1,1.932,1.932A1.932,1.932,0,0,1,21,25.322Z" transform="translate(-21 -6)" fill="#32a8fe"/>
                                                        </svg>
                                                        
                                                        
                                                    </i>
                                                    <div class="text-box">
                                                        
                                                        <span class="text-16 d-block">{{
                                                            this.alarmSts.Warning }}</span>
                                                            <span class="text-12 d-block">Warning</span>                                                
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    
                                        <div class="graph-holder">
                                            <ngx-charts-pie-chart
                                                [view]="[200, 200]"
                                                [scheme]="{ domain: ['#EE2A2A', '#FB8C04', '#FFCE3C', '#32A8FE'] }"
                                                [results]="seriesAlarm"
                                                [doughnut]="true"
                                                [tooltipText]="labelFormatting">                                                                            
                                            </ngx-charts-pie-chart>   
                                            <div class="text-box donut-chart-label addition">
                                                <div class="holder"> 
                                                    <strong>{{
                                                        this.alarmSts.TotalAlarms }}</strong><br>
                                                    <span>Total Alarms</span>
                                                </div>
                                            </div>
                                        </div>                              
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 map-holder">
                <!-- <img src="assets/images/map-dashboard.jpg" alt=""> -->
                <google-map height="740px"
                            width="auto"
                            [center]="center"
                            [zoom]="zoom"
                            >
                            <map-marker #marker="mapMarker"    *ngFor="let markerPosition of markerPositions"
                                        [position]="markerPosition"
                                        (mapMouseover) ="openInfoWindow(marker, markerPosition.stationName, markerPosition.stationCode)">
                                
                                        <map-info-window>{{this.infoWindowText}}</map-info-window>
                            </map-marker>
                </google-map>
            </div>
        </div>
    </div>
</section>