import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {AppRoutingModule, routingComponent} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {DatePipe, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PlantsComponent } from './pages/plants/plants.component';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import{NgChartsModule} from 'ng2-charts';
import { DummyComponent } from './dummy/dummy.component';
import { PlantDetailComponent } from './pages/plant-detail/plant-detail.component'
// import { NgxGaugeModule } from 'ngx-gauge/ngx-gauge.module';
import { NgxGaugeModule } from 'ngx-gauge';
import { HighchartsChartModule } from 'highcharts-angular';
import { TokanInterceptor } from '@services/tokan.interceptor';
import { HelpDeskDashboardComponent } from './pages/help-desk-dashboard/help-desk-dashboard.component';
import { CommingSoonComponent } from './pages/comming-soon/comming-soon.component';
import { TicketListComponent } from './pages/ticket-list/ticket-list.component';
import { AddTicketComponent } from './pages/add-ticket/add-ticket.component';
import { PreorityComponent } from './pages/preority/preority.component';
import { SourceComponent } from './pages/source/source.component';
import { CategoryComponent } from './pages/category/category.component';
import { SubCategoryComponent } from './pages/sub-category/sub-category.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTableModule } from '@angular/material/table';
import { AlarmComponent } from './pages/alarm/alarm.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { InverterInputDcGraphComponent } from './pages/inverter-input-dc-graph/inverter-input-dc-graph.component';
import { PvChartComponent } from './pages/pv-chart/pv-chart.component';
import { PenalComponent } from './modules/penal/penal.component';
import { ResetpasswordComponent } from './modules/resetpassword/resetpassword.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { GoogleMapsModule } from '@angular/google-maps';
import { EmailComponent } from './pages/email/email.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NumberInputDirective } from './directives/number-input.directive';
import { HistoricalAlarmsComponent } from './pages/historical-alarms/historical-alarms.component';
import { InactiveUserListComponent } from '@pages/inactive-user-list/inactive-user-list.component';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';



defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,

        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,

        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        CommingSoonComponent,
        
        
        
        routingComponent,
                 DummyComponent,
                 PlantDetailComponent,
                 HelpDeskDashboardComponent,
                 CommingSoonComponent,
                 TicketListComponent,
                 AddTicketComponent,
                 PreorityComponent,
                 SourceComponent,
                 CategoryComponent,
                 SubCategoryComponent,
                 UserListComponent,
                 AlarmComponent,
                 InverterInputDcGraphComponent,
                 PvChartComponent,
                 PenalComponent,
                 ResetpasswordComponent,
                 PrivacyPolicyComponent,
                 EmailComponent,
                 NumberInputDirective,
                 HistoricalAlarmsComponent,
                 InactiveUserListComponent
    ],
    imports: [
        GoogleMapsModule,
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        HighchartsChartModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        NgxDatatableModule,
        Ng2SearchPipeModule,
        Ng2OrderModule,
        FormsModule,
        DataTablesModule,
        NgChartsModule,
        NgxGaugeModule,
        NgSelectModule,
        MatTableModule,
        NgbDatepickerModule,
        NgbModule,
        SlickCarouselModule,
        CanvasJSAngularChartsModule,
        MatSnackBarModule,
        MatSelectModule,
        MatFormFieldModule,
        // Ng4LoadingSpinnerModule.forRoot() ,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        NgxChartsModule,
        StoreModule.forRoot({}, {}),
        Ng2GoogleChartsModule,
        BsDatepickerModule.forRoot()

    ],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokanInterceptor,
            multi: true
           },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
