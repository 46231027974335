import { complainList } from "@/Interface/complainlist";

export class complainListModel implements complainList{
    name: string;
    plant_id: number;
    title: string;
    contact: string;
    description: string;
    source: number;
    category: number;
    sub_category: number;
    priority: number;
    assigned_to: number;
    constructor(values:Object = {}){
      Object.assign(this,values);
    }
    getName(){
        return this.name;
    }
    getPlantId(){
        return this.plant_id;
    }
    getTitle(){
        return this.title;
    }
    getContact(){
        return this.contact;
    }
    getDescription(){
        return this.description;
    }
    getSource(){
        return this.source;
    }
    getCategory(){
        return this.category;
    }
    getSubCategory(){
        return this.sub_category;
    }
    getPriority(){
        return this.priority;
    }
    getassignedTo(){
        return this.assigned_to;
    }
}