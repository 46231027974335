import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from './utility.service';
import { Router } from '@angular/router';

@Injectable()
export class TokanInterceptor implements HttpInterceptor {
  getList: any;
  constructor(private _utiltiySevice:UtilityService,private router:Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {Authorization: `Bearer ${localStorage.getItem('tokan')}`}
   });



   this.getList = this._utiltiySevice.decodeUserCode();
   if(this.getList && this.getList.exp){
   var theDate = new Date(this.getList.exp * 1000);
   var dateString = theDate.getTime();
   var currentDate = new Date().getTime();
   if (currentDate  > dateString) {
    this.router.navigate(['/login']);
    localStorage.clear();

   }  
  }


    return next.handle(request);

    
  }
}
