import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-desk-dashboard',
  templateUrl: './help-desk-dashboard.component.html',
  styleUrls: ['./help-desk-dashboard.component.scss']
})
export class HelpDeskDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
