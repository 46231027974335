import { LinkPlantUser } from '@/Interface/linkPlantUser';
import { PlantByUser } from '@/Interface/plantByUser';
import { PlantList } from '@/Interface/plantList';
import { RemoveLinkUser } from '@/Interface/removePlantUser';
import { Users } from '@/Interface/user';
import { UserProfile } from '@/Interface/userProfile';
import { AccountType } from '@/Interface/userType';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '@services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inactive-user-list',
  templateUrl: './inactive-user-list.component.html',
  styleUrls: ['./inactive-user-list.component.scss']
})
export class InactiveUserListComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private _utiltiySevice: UtilityService,
    private formBuilders: FormBuilder,
    private toaster:ToastrService

) {}
@ViewChild('search', {static: false}) search: any;
userTypelist:AccountType;
pageSize: number;
pageToGo: number = 1;
searchElement = '';
serialNumberArray: Array<number> = [];
next_page: number;
current_page: number;
previous_page: number;
total_pages: number;
total_items: number;
private latestQueryParams: any = {};
sortColumn: string = null; 
sortOrder: 'asc' | 'desc' = null; 
searchSubject: Subject<string> = new Subject();

public pageSizeControl = new FormControl(10);
public pageRecordOptions = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' },
  ];

// userList  = new UserAdd();
modalRef: NgbModalRef | null=null;
userList:FormGroup;
emailAlreadyExists:boolean = false;

user: Users[];
@ViewChild('myTable') table: any;
selectedCars: number[] = [];
dataSource: Users;
rows: any[] = [];
row: Users[] = [];

USerID: number;
// row: Users;
expandedElement: Users;
allPlantList: PlantList;
linkPlant: FormGroup;
list: any;

PlantByUserlist: PlantByUser;
message: any;
loader : boolean = false
ngOnInit(): void {
    // this.loader = true;
    // this.getUser();
    this.getTableData();
    this.getUserType();

    this.searchSubject.pipe(debounceTime(300)).subscribe((searchTerm: string) => {
      this.searchElement = searchTerm;
      this.updateQueryParams({ search: this.searchElement });
    });
    // this.loader = false;
}
showSuccess(message:string) {
    this.toaster.success(message);
  }
  showError(message:string) {
    this.toaster.error(message);
  }
  sortUsers(column: string): void {
    if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }
  
    let sortValue: string | null = null;
    let columnName: string;
  
    if (this.sortOrder === 'asc') {
      sortValue = '0';
    } else if (this.sortOrder === 'desc') {
      sortValue = '1';
    }
  
    // Mapping column names used in the template to the corresponding column names expected by the API
    switch (column) {
      case 'username':
        columnName = 'username';
        break;
      default:
        columnName = column;
        break;
    }
  
    this.updateQueryParams({ sort: sortValue, column: columnName });
  }
  

private updateQueryParams(paramsToUpdate: any): void {
    this.latestQueryParams = {
        ...this.latestQueryParams,
        ...paramsToUpdate
    };
    console.log('Updated queryParams:', this.latestQueryParams);

    if (this.latestQueryParams.search === '') {
        this.latestQueryParams.search = null;
    }
    if (this.latestQueryParams.page === 1) {
        this.latestQueryParams.page = null;
    }
    
    if (this.latestQueryParams.size === 10) {
        this.latestQueryParams.size = null;
    }
    if (this.latestQueryParams.sort === null) {
        delete this.latestQueryParams.sort;
    }
    
    if (this.latestQueryParams.column === null) {
        delete this.latestQueryParams.column;
    }
    if (this.latestQueryParams.user_type === null) {
        delete this.latestQueryParams.user_type;
    }
    // New condition integration
    if (this.latestQueryParams.page === 1 || (this.latestQueryParams.page && (this.latestQueryParams.search || this.latestQueryParams.user_type))) {
      if (this.latestQueryParams.page > 1) {
          this.latestQueryParams.page = paramsToUpdate.page;
          if (this.latestQueryParams.size > 10) {
              this.latestQueryParams.size = paramsToUpdate.size;
          }
      } else {
          this.latestQueryParams.page = null;
      }
  } 
    
    if (!this.next_page && (paramsToUpdate.size || paramsToUpdate.search)) {
        // Only include size or search in the query params
        this.latestQueryParams = {
            size: paramsToUpdate.size || null,
            search: paramsToUpdate.search || null
        };
    }

    this.getTableData(); 
}

private getTableData(): void {
    const name = this.latestQueryParams['search'] || this.searchElement;
    console.log('name search', name);
    const page = this.latestQueryParams['page'] ? +this.latestQueryParams['page'] : 1;
    console.log('current Page', page);
    const size = this.latestQueryParams['size'] ? +this.latestQueryParams['size'] : 10;
    const sort = this.latestQueryParams['sort'] || null;
    const column = this.latestQueryParams['column'] || null;
    const user_type = this.latestQueryParams['user_type'] || null;
    this.getAllUsers(name, page, size,sort,column,user_type);
}


getAllUsers(name: string, page: number, size: number,sort:string | null ,column :string | null,user_type:number): void {
    this.loader = true;
    this.row = [];
    this.serialNumberArray = [];
    this._utiltiySevice
        .receiveInactiveUsers(name, page, size,sort,column,user_type)
        .subscribe((res: any) => {
            if (res && res['status'] === 200) {
                this.row = res.data;
                this.row = res.data.map((user: any, index: number) => ({
                    ...user,
                    srNo: (res.current_page - 1) * size + index + 1
                }));
                console.log(this.row);
                this.current_page = res.current_page;
                this.next_page = res.next_page;
                this.previous_page = res.previous_page;
                this.total_pages = res.total_pages;
                this.total_items = res.total_users;
                this.enablePagination();
            }
            this.loader = false;
        });
}
public searchData(event: Event): void {
    this.searchElement = (event.target as HTMLInputElement)?.value || '';
    console.log('search Value', this.searchElement);
    // this.updateQueryParams({search: this.searchElement});
    this.searchSubject.next(this.searchElement);
}

goToPage(page: number): void {
    console.log('move to page', page);
    if (page >= 1 && page <= this.total_pages) {
        this.updateQueryParams({page:page});
    }
}

navigatePage(direction: string): void {
    if (direction === 'prev' && this.current_page > 1) {
        this.goToPage(this.current_page - 1);
    } else if (direction === 'next' && this.next_page !== null) {
        this.goToPage(this.current_page + 1);
    }
}

// Add a method to navigate to the entered page
navigateToPage(): void {
    if (this.pageToGo >= 1 && this.pageToGo <= this.total_pages) {
        this.goToPage(this.pageToGo);
    }
}

public changePageSize(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.pageSize = this.pageSizeControl.value!;
    this.updateQueryParams({size:this.pageSize})
    }

    public selectRoleslistStatus(selectedRoleId){
        this.updateQueryParams({ user_type: selectedRoleId });
    }

closeModal() {
    if (this.modalRef) {
      this.modalRef.close('Closed');
    }
  }

  getUserType(){
    this._utiltiySevice.selectUserType().subscribe((res)=>{
      this.userTypelist = res.data;
    })
  }

open(content: any, row: Users) {
    this.selectedCars = [];
    this.modalService.open(content);
    this.USerID = row.user_id;
}

toggleExpandRow(row) {}
listInfo(row) {
    this.toggleExpandRow(row);
}
onDetailToggle(event) {}
onMaterialGroupChange() {
    this.list = this.selectedCars;
}



   enableUser(userId) {
    Swal.fire({
      title: 'Are you sure you want to activate this User?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, activate it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._utiltiySevice.enableUserApi(userId).subscribe((res)=>{
            if(res && res.status === 200){
             console.log("Success");
             this.row.filter(item => item.user_id === userId)[0].is_active = true;
            }
           })
        Swal.fire(
          'Activated!',
          'user has been activated.',
          'success'
        )
        this.getTableData();
      }
    })
 
  }

  enablePagination() {
    const linkElement = document.getElementById('prevPage');

    if (this.previous_page === null) {
        linkElement.setAttribute('disabled', 'true');
        linkElement.style.pointerEvents = 'none';
        linkElement.style.color = 'gray'; // You can set the color to indicate it's disabled
        linkElement.addEventListener('click', (event) => {
            event.preventDefault(); // Prevent the default behavior (navigation)
        });
    } else {
        linkElement.removeAttribute('disabled');
        linkElement.style.pointerEvents = 'auto'; // Enable clicking
        linkElement.style.color = ''; // Reset the color to its default
        linkElement.removeEventListener('click', (event) => {
            event.preventDefault();
        });
    }
}


}
