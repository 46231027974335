
import {SubCat} from '../Interface/subcategory'

export class SubCategoryModel implements SubCat{

    id: number;
    sub_category_name: string;
    category_id?: number;
    category_name?:string;


    constructor(values:object={}){
    Object.assign(this,values)
    }
  
  


    getId(){
        return this.id;
    }
    getSubCategoryName(){
        return this.sub_category_name;
    }
    getCategoryid(){
        return this.category_id;
    }
    getCategoryName(){
        return this.category_name;
    }
}

