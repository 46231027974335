export interface Pdetails {
    total_plants: number,
    total_users: number,
    total_income: string,
    total_generation:string,
    total_capacity:string,
    today_generation:string,
    month_power:string,
    day_income:string
}

export class AlarmStatusInterface {
    Critical: number = 0;
    Major: number = 0;
    Minor: number = 0;
    Warning: number = 0;
    TotalAlarms : number = 0;
}

export class PlantStatus {
    offline: number = 0;
    faulty: number = 0;
    normal: number = 0;
    total_plants: number = 0;
}


export interface PlantList{
    plantName: string,
    stationCode: string,
    address: string,
    capacity: number
    currentPower: number,
    todayGeneration: number
    updatedAT: Date,
    status: string,
    city: string,
    inverter_type: string,
}

export class PlantStatusDDL {
    constructor(id: number, nme)
    {
        this.id = id;
        this.name = nme;
    }
    
    id: number;
    name: string
}