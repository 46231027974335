export var single = [
  {
    "name": "Karachi",
    "value": 8
  },
  {
    "name": "Lahore",
    "value": 130
  },
  {
    "name": "Faisalabad",
    "value": 12
  },
  {
    "name": "Rawalpindi",
    "value": 3
  },
  {
    "name": "Gujranwala",
    "value": 4
  },
  {
    "name": "Multan",
    "value": 3
  },
  {
    "name": "Islamabad",
    "value": 8
  },
  {
    "name": "Gujrat",
    "value": 7
  },
  {
    "name": "Okara",
    "value": 7
  },
  {
    "name": "Jaranwala",
    "value": 1
  },
  {
    "name": "Kasur",
    "value": 4
  },
  {
    "name": "Khushab",
    "value": 3
  },
  {
    "name": "Dipalpur",
    "value": 1
  },
  {
    "name": "Samundri",
    "value": 2
  },
  {
    "name": "Chunian",
    "value": 2
  },
  {
    "name": "Chakwal",
    "value": 1
  },
  {
    "name": "Pattoki",
    "value": 1
  },


];
export var city = [
  {
    "name": "Islamabad",
    "value": 6
  },
  {
    "name": "Punjab",
    "value":
      197
  },
  {
    "name": "Sindh",
    "value": 8
  }
]