<!-- <div class="app-sidebar sidebar-shadow bg-vicious-stance header-text-light">
    <div class="app-header__logo">
        <div class="logo-src"></div>
        <div class="header__pane ml-auto">
            <div>
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                    data-class="closed-sidebar">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="app-header__mobile-menu">
        <div>
            <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="app-header__menu">
        <span>
            <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                <span class="btn-icon-wrapper">
                    <i class="fa fa-ellipsis-v fa-w-6"></i>
                </span>
            </button>
        </span>
    </div>
    <div class="scrollbar-sidebar">
        <div class="app-sidebar__inner">
            <ul class="vertical-nav-menu">
                <li class="app-sidebar__heading header-text-light text-white">
                    Dashboards
                </li>
                <li>
                    <a [routerLink]="['/']" routerLinkActive="mm-active" class="text-white"
                        [routerLinkActiveOptions]="{exact: true}">
                        <i><img src="../../../../assets/images/dboard.png" alt="" /></i>
                        Dashboard
                    </a>
                </li>
                <li class="app-sidebar__heading text-white">Plants</li>
                <li>
                    <a class="appSideBar" [routerLink]="['/plants']" routerLinkActive="mm-active"
                        (click)="showPlantList()">
                        <i><img src="../../../../assets/images/power/power-plant.png" alt="" /></i>
                        Plants
                    </a>
                </li>
                <li class="app-sidebar__heading text-white">Help Dask</li>
                <li>
                    <a class="appSideBar bottomArrow" routerLinkActive="mm-active">
                        <i><img src="../../../../assets/images/helpDesk.png" alt="" /></i>
                        Help Dask
                    </a>
                    <ul>
                       
                        <li>
                            <a [routerLink]="['/ticket-list']" routerLinkActive="mm-active" (click)="showPlantList()">
                                <i>•</i>
                                Ticket list
                            </a>
                        </li>
                        <li *ngIf="userType !== 'noc_user'  ">
                            <a [routerLink]="['/add-ticket']" routerLinkActive="mm-active" (click)="showPlantList()">
                                <i>•</i>
                                Add Ticket
                            </a>
                        </li>
                        <li *ngIf="userType === 'superuser'  ">
                            <a [routerLink]="['/priority']" routerLinkActive="mm-active" (click)="showPlantList()">
                                <i>•</i>
                                Priority
                            </a>
                        </li>
                        <li  *ngIf="userType === 'superuser'  "> 
                            <a [routerLink]="['/source']" routerLinkActive="mm-active" (click)="showPlantList()">
                                <i>•</i>
                                Source
                            </a>
                        </li>
                        <li  *ngIf="userType === 'superuser'  "> 
                            <a [routerLink]="['/category']" routerLinkActive="mm-active" (click)="showPlantList()">
                                <i>•</i>
                                Category
                            </a>
                        </li>
                        <li  *ngIf="userType === 'superuser'  "> 
                            <a [routerLink]="['/sub-category']" routerLinkActive="mm-active" (click)="showPlantList()">
                                <i>•</i>
                                Sub-Category
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="app-sidebar__heading text-white"> </li>
                <li>
                    <a [routerLink]="['/alarm']" routerLinkActive="mm-active" class="text-white"
                        [routerLinkActiveOptions]="{exact: true}">
                         <i><img src="../../../../assets/images/alarm.png" alt="" /></i>

                         Alarm Center
                    </a>
                </li>
                <li *ngIf="userType === 'superuser' " class="app-sidebar__heading text-white">System Management</li>

                <li   *ngIf="userType === 'superuser' ">
                    <a class="appSideBar bottomArrow" routerLinkActive="mm-active">
                        <i><img src="../../../../assets/images/helpDesk.png" alt="" /></i>
                        User Management
                    </a>
                    <ul>
                        <li>
                            <a [routerLink]="['/user']" routerLinkActive="mm-active" (click)="showPlantList()">
                                <i>•</i>
                                All Users
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div> -->



<div id="app-menu-new" class="app-menu navbar-menu app-menu-new">
    <!-- LOGO -->
    <div class="navbar-brand-box">
        <!-- Dark Logo-->
        <!--
        <a href="index.html" class="logo logo-dark">
            <span class="logo-sm">
                <img src="assets/images/zerocarbon-logo-new.png" alt="" height="22">
            </span>
            <span>
                <img src="assets/images/logo-dash.png" alt="" width="172">
            </span>
        </a>
        -->
        <!-- Light Logo-->
        
        <a href="index.html" class="logo logo-light">
            <!-- 
            <span class="logo-sm">
                <img src="assets/img/zerocarbon-logo-new.png" alt="" height="22">
            </span>
            -->
            <span>
                <img src="assets/images/logo-dash.png" alt="" width="172">
            </span>
        </a>
        <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
            <i class="ri-record-circle-line"></i>
        </button>
    </div>

    <div id="scrollbar">
        <div class="container-fluid">
    
            <div id="two-column-menu">
            </div>
            <ul class="navbar-nav" id="navbar-nav">
                <li class="menu-title"><span data-key="t-menu">Menu</span></li>
                <li class="nav-item">
                    <!-- data-bs-toggle="collapse"  -->
                    <a class="nav-link menu-link" [routerLink]="['/']" routerLinkActive="active"  role="button"
                        aria-expanded="false" aria-controls="sidebarDashboards">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.199" height="17.776" viewBox="0 0 16.199 17.776">
                                <path id="Path_5396" data-name="Path 5396" d="M23.421,11.873,15.788,5.962a.589.589,0,0,0-.728.006L7.671,11.878a.589.589,0,0,0-.221.459V23.027a.588.588,0,0,0,.588.588h4.392a.589.589,0,0,0,.588-.588V16.482h5.06v6.545a.588.588,0,0,0,.588.588h4.393a.589.589,0,0,0,.588-.588V12.338a.587.587,0,0,0-.229-.465Z" transform="translate(-7.45 -5.839)" />
                              </svg>
                              
                        </i> 
                        <span data-key="t-dashboards">Dashboard</span>
                    </a>
                 
                </li> 
                

                <li class="nav-item">
                    <a class="nav-link menu-link" href="#sidebarLayouts" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarLayouts">
                        <i class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.023" height="16.115" viewBox="0 0 22.023 16.115">
                                <g id="energy-panel-solar-sun-svgrepo-com" transform="translate(-0.962 -4.999)" opacity="0.75">
                                  <path id="Path_5403" data-name="Path 5403" d="M20.382,24.465H15.819V23H14.354v1.465h-2.93V23H9.959v1.465H5.732a.732.732,0,1,0,0,1.465h14.65a.732.732,0,1,0,0-1.465Z" transform="translate(-1.08 -4.816)" fill="#fff"/>
                                  <path id="Path_5404" data-name="Path 5404" d="M18.092,13.93,17.309,11H12.284L11.5,13.93Z" transform="translate(-2.819 -1.605)" fill="#fff"/>
                                  <path id="Path_5405" data-name="Path 5405" d="M8.33,11H3.225L2.39,13.93H7.547Z" transform="translate(-0.382 -1.605)" fill="#fff"/>
                                  <path id="Path_5406" data-name="Path 5406" d="M22.284,13.93H27.44L26.605,11H21.5Z" transform="translate(-5.494 -1.605)" fill="#fff"/>
                                  <path id="Path_5407" data-name="Path 5407" d="M10.674,17,9.89,19.93h8.951L18.057,17Z" transform="translate(-2.388 -3.21)" fill="#fff"/>
                                  <path id="Path_5408" data-name="Path 5408" d="M16.56,5H13.894L13.11,7.93h4.234Z" transform="translate(-3.25 0)" fill="#fff"/>
                                  <path id="Path_5409" data-name="Path 5409" d="M28.889,19l-.6-2H23.11l.784,2.93h4.263a.767.767,0,0,0,.732-.93Z" transform="translate(-5.925 -3.21)" fill="#fff"/>
                                  <path id="Path_5410" data-name="Path 5410" d="M25.757,7.93l-.659-2.4A.732.732,0,0,0,24.365,5H19.89L20.7,7.93Z" transform="translate(-5.064)" fill="#fff"/>
                                  <path id="Path_5411" data-name="Path 5411" d="M6.769,17H1.59l-.6,2a.731.731,0,0,0,.732.93H5.985Z" transform="translate(0 -3.21)" fill="#fff"/>
                                  <path id="Path_5412" data-name="Path 5412" d="M9.967,5H5.492a.732.732,0,0,0-.732.535L4.1,7.93H9.154Z" transform="translate(-0.839)" fill="#fff"/>
                                </g>
                              </svg>
                              
                        </i>  <span data-key="t-layouts">Plants</span>
                    </a>
                    
                    <div class="collapse menu-dropdown" id="sidebarLayouts">
                        <ul class="nav nav-sm flex-column">
                            <li class="nav-item">
                                <a [routerLink]="['/plants']"  
                                (click)="showPlantList()"  class="nav-link" routerLinkActive="active"  data-key="t-vertical">Plants List</a>
                            </li>                            
                        </ul>
                    </div>
                </li> <!-- end Dashboard Menu -->

                <li class="menu-title"><i class="ri-more-fill"></i> <span data-key="t-pages">Pages</span></li>

                <li class="nav-item" *ngIf="user_type === 'superuser'">
                    <a class="nav-link menu-link" href="#sidebarAuth" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarAuth">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path id="Path_5414" data-name="Path 5414" d="M12.5,4.5a8,8,0,1,0,8,8A8,8,0,0,0,12.5,4.5Zm-.165,11.692a.793.793,0,1,1,.827-.792.8.8,0,0,1-.827.792Zm1.546-3.727c-.669.388-.9.673-.9,1.165v.3H11.65l-.012-.331a1.6,1.6,0,0,1,.908-1.692c.65-.388.923-.635.923-1.112a.934.934,0,0,0-1.035-.827.973.973,0,0,0-1.031.946H10.038A2.2,2.2,0,0,1,12.527,8.8c1.442,0,2.435.8,2.435,1.95a1.883,1.883,0,0,1-1.081,1.712Z" transform="translate(-4.5 -4.5)" fill="#ccc"/>
                              </svg>
                              
                        </i> 
                        <span data-key="t-authentication">Helpdesk</span>
                    </a>
                    <div class="collapse menu-dropdown" id="sidebarAuth">
                        <ul class="nav nav-sm flex-column">
                            <li class="nav-item">
                                <a [routerLink]="['/ticket-list']" routerLinkActive="active" (click)="showPlantList()" class="nav-link"  role="button"
                                    aria-expanded="false" aria-controls="sidebarSignIn" data-key="t-signin">  Ticket list
                                </a>                              
                            </li>
                            <li class="nav-item" *ngIf="userType !== 'noc_user'  ">
                                <a [routerLink]="['/add-ticket']" routerLinkActive="active" (click)="showPlantList()" class="nav-link"  role="button"
                                    aria-expanded="false" aria-controls="sidebarSignIn" data-key="t-signin">   Add Ticket
                                </a>                              
                            </li>
                            
                            <li class="nav-item" *ngIf="userType === 'superuser'  ">
                                <a [routerLink]="['/priority']" routerLinkActive="active" (click)="showPlantList()" class="nav-link"  role="button"
                                    aria-expanded="false" aria-controls="sidebarSignIn" data-key="t-signin">  Priority
                                </a>                              
                            </li>
                            

                            <li class="nav-item" *ngIf="userType === 'superuser'  ">
                                <a [routerLink]="['/source']" routerLinkActive="active" (click)="showPlantList()" class="nav-link"  role="button"
                                    aria-expanded="false" aria-controls="sidebarSignIn" data-key="t-signin">  Source
                                </a>                              
                            </li>
                           
                            <li class="nav-item"  *ngIf="userType === 'superuser'  ">
                                <a [routerLink]="['/category']" routerLinkActive="active" (click)="showPlantList()" class="nav-link"  role="button"
                                    aria-expanded="false" aria-controls="sidebarSignIn" data-key="t-signin">  Category
                                </a>                              
                            </li>
                            <li class="nav-item"  *ngIf="userType === 'superuser'  ">
                                <a [routerLink]="['/sub-category']" routerLinkActive="active" (click)="showPlantList()" class="nav-link"  role="button"
                                    aria-expanded="false" aria-controls="sidebarSignIn" data-key="t-signin">  Sub-Category
                                </a>                              
                            </li>                         
                        </ul>
                    </div>
                </li>
             
                <li class="nav-item">
                    <a class="nav-link menu-link" href="#sidebarPages" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarPages">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.198" height="17.672" viewBox="0 0 16.198 17.672">
                                <g id="notification-solid" transform="translate(-4.067 -2.367)" opacity="0.75">
                                  <path id="Path_5401" data-name="Path 5401" d="M20.265,16.692l-.185-.163a7.811,7.811,0,0,1-1.364-1.593,6.865,6.865,0,0,1-.734-2.614V9.637A5.876,5.876,0,0,0,12.85,3.793v-.7a.726.726,0,1,0-1.451,0V3.8A5.876,5.876,0,0,0,6.328,9.637v2.685a6.865,6.865,0,0,1-.734,2.614,7.827,7.827,0,0,1-1.343,1.593l-.185.163v1.533h16.2Z" fill="#fff"/>
                                  <path id="Path_5402" data-name="Path 5402" d="M20.427,42.667a1.44,1.44,0,0,0,2.854,0Z" transform="translate(-9.691 -23.871)" fill="#fff"/>
                                </g>
                              </svg>
                              
                        </i> <span data-key="t-pages">Alarm</span>
                    </a>
                    <div class="collapse menu-dropdown" id="sidebarPages">
                        <ul class="nav nav-sm flex-column">
                            <li class="nav-item">
                                <a [routerLink]="['/alarm']" routerLinkActive="active"   class="nav-link" data-key="t-starter"><span data-key="t-advance-ui">Alarm Center </span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/historical-alarms']" routerLinkActive="active"   class="nav-link" data-key="t-starter"><span data-key="t-advance-ui">Historical Alarms</span>
                                    <span class="badge badge-pill bg-success" data-key="t-new">New</span>   </a>
                            </li>
                        </ul>
                    </div>
                </li>                             

                <li class="menu-title" *ngIf="userType === 'superuser' "><i class="ri-more-fill"></i> <span data-key="t-components">Components</span></li>

                <li class="nav-item" *ngIf="userType === 'superuser' ">
                    <a class="nav-link menu-link" href="#sidebarUI" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarUI">
                        <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path id="Path_5397" data-name="Path 5397" d="M7.333,22A1.306,1.306,0,0,1,6,20.667c0-1.333,1.333-5.333,8-5.333s8,4,8,5.333A1.306,1.306,0,0,1,20.667,22ZM14,14a4,4,0,1,0-4-4A4,4,0,0,0,14,14Z" transform="translate(-6 -6)" fill="#fff"/>
                          </svg>
                          </i> <span data-key="t-base-ui">User Management</span>
                    </a>
                    <div class="collapse menu-dropdown mega-dropdown-menu" id="sidebarUI">
                        <div class="row">
                            <div class="col-lg-4">
                                <ul class="nav nav-sm flex-column">
                                    <li class="nav-item">
                                        <a [routerLink]="['/user']" (click)="showPlantList()" routerLinkActive="active" class="nav-link" data-key="t-alerts">Active Users</a>
                                    </li>
                                    <li class="nav-item">
                                        <a  [routerLink]="['/inactive-user']" routerLinkActive="active" class="nav-link" data-key="t-alerts">Inactive Users<span class="badge badge-pill bg-success small" data-key="t-new">New</span></a>
                                    </li>
                                    
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </li>
                
                <!-- <li class="nav-item">
                    <a class="nav-link menu-link" href="#emailPages" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarPages">
                        <i>
                            <svg id="chat-left-dots-fill" xmlns="http://www.w3.org/2000/svg" width="17.938" height="17.146" viewBox="0 0 17.938 17.146">
                                <g id="Group_2882" data-name="Group 2882">
                                  <path id="Path_5527" data-name="Path 5527" d="M0,2.242A2.242,2.242,0,0,1,2.242,0H15.7a2.242,2.242,0,0,1,2.242,2.242v8.969A2.242,2.242,0,0,1,15.7,13.454H4.949a1.121,1.121,0,0,0-.792.328l-3.2,3.2a.561.561,0,0,1-.957-.4ZM5.606,6.727A1.121,1.121,0,1,0,4.485,7.848,1.121,1.121,0,0,0,5.606,6.727Zm4.485,0A1.121,1.121,0,1,0,8.969,7.848,1.121,1.121,0,0,0,10.09,6.727Zm3.363,1.121a1.121,1.121,0,1,0-1.121-1.121A1.121,1.121,0,0,0,13.454,7.848Z" fill="#ef4323"/>
                                </g>
                            </svg>                         
                              
                        </i> <span data-key="t-pages">Communication</span>
                    </a>
                    <div class="collapse menu-dropdown" id="emailPages">
                        <ul class="nav nav-sm flex-column">
                            <li class="nav-item">
                                <a (click)="openEmailModal()" routerLinkActive="active"   class="nav-link" data-key="t-starter"><span data-key="t-advance-ui">Email </span>
                                   
                                </a>
                            </li>
                            
                        </ul>
                    </div>
                </li>                 -->

                <li class="nav-item" style="opacity: 0.5;" >
                    <a class="nav-link menu-link"  data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarPages">
                        <i >
                            <svg id="chat-left-dots-fill" xmlns="http://www.w3.org/2000/svg" width="17.938" height="17.146" viewBox="0 0 17.938 17.146">
                                <g id="Group_2882" data-name="Group 2882">
                                  <path id="Path_5527" data-name="Path 5527" d="M0,2.242A2.242,2.242,0,0,1,2.242,0H15.7a2.242,2.242,0,0,1,2.242,2.242v8.969A2.242,2.242,0,0,1,15.7,13.454H4.949a1.121,1.121,0,0,0-.792.328l-3.2,3.2a.561.561,0,0,1-.957-.4ZM5.606,6.727A1.121,1.121,0,1,0,4.485,7.848,1.121,1.121,0,0,0,5.606,6.727Zm4.485,0A1.121,1.121,0,1,0,8.969,7.848,1.121,1.121,0,0,0,10.09,6.727Zm3.363,1.121a1.121,1.121,0,1,0-1.121-1.121A1.121,1.121,0,0,0,13.454,7.848Z" fill="#ef4323"/>
                                </g>
                            </svg>                         
                              
                        </i> <span  data-key="t-pages" >Communication</span>
                        <span class="badge badge-pill bg-success small" data-key="t-new">New</span>
                    </a>
                    <div class="collapse menu-dropdown" id="emailPages" >
                        <ul class="nav nav-sm flex-column">
                            <li class="nav-item">
                                <!-- <a (click)="openEmailModal()" routerLinkActive="active" disa  class="nav-link" data-key="t-starter"><span data-key="t-advance-ui">Email </span>
                                    
                                </a> -->

                                
                                
                            </li>
                            
                        </ul>
                    </div>
                </li>     
            </ul>
        </div>
        <!-- Sidebar -->
    </div>
</div>