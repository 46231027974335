import { SelectPriority } from "@/Interface/selectPriority"

export class SelectPriorityModel implements SelectPriority{
    ticket_id: number;
    priority: number
    constructor(values:Object = {}){
        Object.assign(this,values)
       }
    getTicketId(){
        return this.ticket_id;
    }
    getPriority(){
        return this.priority;
    }
}