
<section class="dashboard-main container-fluid">
    <!-- <app-comming-soon></app-comming-soon> -->
    <div class="row mb-4 pt-4 block-topbar">
        <div class="col-12 d-sm-flex align-items-center justify-content-between">
            <!-- <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0">Category </h4>
                <button type="button" class="btn btn-orange btn-sm btn-label waves-effect waves-light" (click)="addCategory(contentadd)"><i class="ri-add-line label-icon align-middle fs-16 me-2"></i> Category</button>
            </div> -->
            <h4 class="mb-sm-0">Category </h4>
            <button type="button" class="btn btn-orange btn-sm btn-label waves-effect waves-light" (click)="addCategory(contentadd)"><i class="ri-add-line label-icon align-middle fs-16 me-2"></i> Add Category</button>
        </div>
    </div>
    <div class="contents-main">
        <div class="table-wrap">
            <div class="table-main">
                <table class="table-data">
                    <thead>
                        <tr>
                            <th scope="col">Sr. No</th>
                            <th scope="col">Category Name</th>
                            <th scope="col">Source Name</th>

                            <th scope="col" style="width: 80px;text-align: center;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of categoryList; let i = index">
                            <td>{{ i+1 }}</td>
                            <td class=" ">
                                <div class="d-flex align-items-center">
                                    <span class="mr-1"><i class=" ri-discuss-line color-orange"></i></span>{{ item.getName() }}
                                </div>
                            </td>
                            <td>{{ item.getSource() }}</td>
                            
                            
                            <td style="width: 40px;text-align: center">
                                <div class="d-flex justify-content-center">
                                    <button type="button" (click)="open(item, content)" class="dropdown-item editcategory d-inline"
                                    data-toggle="modal" data-target="#edit-category-vt"
                                        >
                                
                                        <!-- <i class="bx bx-edit"></i> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.433" height="18" viewBox="8 4 14.433 18">
                                            <path d="M8.9 20.2h12.6a.9.9 0 0 1 0 1.8H8.9a.9.9 0 1 1 0-1.8ZM8 15.7l9-9 2.7 2.7-9 9H8v-2.7Zm9.9-9.9L19.7 4l2.7 2.7-1.8 1.8-2.7-2.7Z" fill="currentColor"/>
                                        </svg>
                                    </button>
                                    <button (click)="delateCategory(item)" type="button"
                                        class="dropdown-item deletecategory d-inline">
                                            <!-- <i class=" ri-close-circle-line  "></i>  -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.017" height="18" viewBox="9 6 15.017 18">
                                                <path d="M10.5 22.004c0 1.098.9 1.996 2 1.996h8c1.1 0 2-.898 2-1.996V10.5h-12v11.504ZM24 7.5h-3.75L18.994 6h-4.988L12.75 7.5H9V9h15V7.5Z" fill="currentColor"/>
                                            </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<ng-template #contentadd let-c="close" let-d="dismiss">
    <form [formGroup]="categoryadd" (ngSubmit)="categoryAddSubmit()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Category</h4>
            <button type="button" (click)="d('Cross click')" class="btn-close" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
                    <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
                </svg>         
            </button>
           
        </div>

        <div class="modal-body">
            <div class="row mb-4">
                <div class="col-md-12 form-styles">
                    <label for="typeahead-basic">Category Name:</label>
                    <input id="typeahead-basic" type="text" class="form-control" formControlName="categoryNameAdd"  maxlength="50"/>
                    <small class="text-danger" *ngIf="
                            !categoryadd.get('categoryNameAdd').valid &&
                            categoryadd.get('categoryNameAdd').touched
                        ">Please Enter Category Name</small>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 form-styles">
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Source Name</label>

                        <ng-select formControlName="sourceNameAdd">
                            <ng-option *ngFor="let sourceList of getSourceList" [value]="sourceList.id">{{
                                sourceList.name }}</ng-option>
                        </ng-select>
                        <small class="text-danger" *ngIf="
                                !categoryadd.get('sourceNameAdd').valid &&
                                categoryadd.get('sourceNameAdd').touched
                            ">Please Enter Source Name</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click')"
            >
                Cancel
            </button>
            <button type="button" class="btn btn-orange" [disabled]="categoryadd.invalid"
                (click)="d('Cross click')" type="submit">
                Save
            </button>
        </div>
    </form>
 </ng-template>
<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="categoryForm" (ngSubmit)="categorySubmit()">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Category</h4>
            <button type="button" (click)="d('Cross click')" class="btn-close" aria-label="Close">
              
                <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
                    <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
                </svg>          
            </button>
        </div>

        <div class="modal-body">
            <div class="row mb-4">
                <div class="col-md-12 form-styles">
                    <label for="typeahead-basic">Category Name:</label>
                    <input id="typeahead-basic" type="text" class="form-control" formControlName="categoryName" maxlength="50" />
                    <small class="text-danger" *ngIf="
                            !categoryForm?.get('categoryName').valid &&
                            categoryForm?.get('categoryName').touched
                        ">Please Enter Category Name</small>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 form-styles">
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Source Name</label>

                        <ng-select formControlName="sourceName">
                            <ng-option *ngFor="let sourceList of getSourceList" [value]="sourceList.id">{{
                                sourceList.name }}</ng-option>
                        </ng-select>
                        <small class="text-danger" *ngIf="
                                !categoryForm?.get('sourceName').valid &&
                                categoryForm?.get('sourceName').touched
                            ">Please Enter Source Name</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer no-bg">
            <button
                type="button"
                class="btn btn-outline-dark"
                aria-label="Close"
                (click)="d('Cross click')"
            >
                Cancel
            </button>
            <button type="button" class="btn btn-orange" [disabled]="categoryForm.invalid"
                (click)="d('Cross click')" type="submit">
                Save
            </button>
        </div>
    </form>
</ng-template>