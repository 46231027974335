import {TicketList} from '@/Interface/ticketList';
 
export class TicketListModel implements TicketList {
    ticket_id: number;
    plant_name: string;
    title: string;
    source: string;
    description: string;
    priority: string;
    priority_id:number;
    status: string;
    category: string;
    sub_category: string;
    contact: string;
    filed_by: string;
    assigned_to: string;
    created_at: number;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
    sort(arg0: (a: any, b: any) => number): unknown {
        throw new Error('Method not implemented.');
    }

    getTicketID() {
        return this.ticket_id;
    }
    getPlantName() {
        return this.plant_name;
    }
    getTitle() {
        return this.title;
    }
    getSource() {
        return this.source;
    }
    getDescription() {
        return this.description;
    }
    getPriority() {
        return this.priority;
    }
    getStatus() {
        return this.status;
    }
    getCategory() {
        return this.category;
    }
    getContact() {
        return this.contact;
    }
    getfiledBy() {
        return this.filed_by;
    }
    getAssignBy() {
        return this.assigned_to;
    }
    getCreatedAt() {
        return this.created_at;
    }
    getpid(){
return this.priority_id;
    }
}
