 
        export class Voltage {
            pv1: string;
            pv2: string;
            pv3: string;
            pv4: string;
            pv5: string;
            pv6: string;
            pv7: string;
            pv8: string;
            pv9: string;
            pv10: string;
            pv11: string;
            pv12: string;
            pv13: string;
            pv14: string;
            pv15: string;
            pv16: string;
            pv17: string;
            pv18: string;
            pv19: string;
            pv20: string;
            pv21: string;
            pv22: string;
            pv23: string;
            pv24: string;
        }
    
        export class Current {
            pv1: string;
            pv2: string;
            pv3: string;
            pv4: string;
            pv5: string;
            pv6: string;
            pv7: string;
            pv8: string;
            pv9: string;
            pv10: string;
            pv11: string;
            pv12: string;
            pv13: string;
            pv14: string;
            pv15: string;
            pv16: string;
            pv17: string;
            pv18: string;
            pv19: string;
            pv20: string;
            pv21: string;
            pv22: string;
            pv23: string;
            pv24: string;
        }
    
        export class Power {
            pv1: string;
            pv2: string;
            pv3: string;
            pv4: string;
            pv5: string;
            pv6: string;
            pv7: string;
            pv8: string;
            pv9: string;
            pv10: string;
            pv11: string;
            pv12: string;
            pv13: string;
            pv14: string;
            pv15: string;
            pv16: string;
            pv17: string;
            pv18: string;
            pv19: string;
            pv20: string;
            pv21: string;
            pv22: string;
            pv23: string;
            pv24: string;
        }
    
        export class Pv {
            devId: any;
            voltage: Voltage;
            current: Current;
            power: Power;
        }
    
        // export interface Pv {
        //     message: string;
        //     status: number;
        //     success: boolean;
        //     messageTypeId: string;
        //     data: Datum[];
        // }
    
    
    
    


 