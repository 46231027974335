import { Source } from '@/Interface/source';
import { SourceModel } from '@/model/source';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '@services/utility.service';
import Swal from "sweetalert2";
@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss']
})
export class SourceComponent implements OnInit {
  source: Source;
  addSourceModel = new SourceModel();
  updateSourceModel = new SourceModel();
  addSourceForm: FormGroup;
  updateSourceForm: FormGroup;
  constructor(
    private modalService: NgbModal,
    private _utilityService: UtilityService,
    private formBuilder: FormBuilder
  ) { }
  ngOnInit(): void {
    this.getSource();
    this.addSourceForm = this.formBuilder.group({
      sourcName: new FormControl(null, Validators.required)
    })
    this.updateSourceForm = this.formBuilder.group({
      sourcNameupdate: new FormControl(null, Validators.required)
    })
  }
  open(item:Source,content: any) {
    this.modalService.open(content);
  var getUpdateId =  item.id;
  this._utilityService.getSourceByid(getUpdateId).subscribe((res) => {
    if (res) {
        this.updateSourceModel = res[0];
        this.updateSourceForm.patchValue({
          sourcNameupdate: this.updateSourceModel.getName()
        })
     }
  });
  }

  getSource() {
    this._utilityService.receiveSource().subscribe((res) => {
      if (res) {
        this.source = res;
      }
    });
  }

  addSourceOpen(addConent: any) {
    this.modalService.open(addConent);
  }

  addSource() {
    this.source = null;
    this.addSourceModel.name = this.addSourceForm.value.sourcName;
    this._utilityService.addSourceList(this.addSourceModel).subscribe((res) => {
      if (res) {
        this.getSource();
        this.addSourceForm.reset();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '`<h2>Source Successfully Added</h2>`',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
  }

  deleteSource(item: Source) {
    var deleteSource = item.id;
    Swal.fire({
      title: 'Are you sure to delete this Source?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._utilityService.deleteSourceList(deleteSource).subscribe((res) => {
          if (res) {
            this.getSource();
          }
        })
        Swal.fire(
          'Deleted!',
          'Source has been deleted.',
          'success'
        )
      }
    })
 
  }

  updateSource(){
   this.updateSourceModel.id = this.updateSourceModel.id;
   this.updateSourceModel.name = this.updateSourceForm.value.sourcNameupdate;
    this._utilityService.updateSourceList(this.updateSourceModel).subscribe((res) => {
      if (res) {
        this.getSource();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '`<h2>Source  Successfully Updated</h2>`',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
  }
}
