import { Source } from "@/Interface/source";

export class SourceModel  implements Source{
    id: number;
    name: string


    constructor(values:object={}){
         Object.assign(this,values)
    }

    getId(){
        return this.id;
    }
    getName(){
        return this.name;
    }
}