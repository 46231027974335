import {Priority} from '@/Interface/priority';

export class PriorityModel implements Priority {
    id: number;
    name: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }

    getid() {
        return this.id;
    }
    getName() {
        return this.name;
    }
}
