<div class="loaderwrapper" *ngIf="this.loader">
  <span class="loader" ></span> 
</div> 

<section class="dashboard-main container-fluid">
  <div class="row mb-0 pt-4 block-topbar">
    <div class="col-12 d-sm-flex align-items-center justify-content-between mb-4">
      <h4 class="mb-sm-0">Inactive Users</h4>
    </div>
    <div class="col-12 d-sm-flex align-items-center justify-content-between pb-4 pt-3">
        <div class="dataTables_length p-0">
          <label class="show-entries-list">
            Show
            <!-- (change)="changePageSize()" -->
            <!-- [formControl]="pageSizeControl" -->
            <select [formControl]="pageSizeControl" (change)="changePageSize()"
            class="form-control form-control-sm">
            <option *ngFor="let limit of pageRecordOptions" [value]="limit.value">{{limit.label}}</option>
          </select>
            entries
          </label>
      </div>
      <!-- Filter by Role -->
      <div class="col-md-3">
      </div>
        <div class="d-flex justify-content-end">
          <div class="row userSearch d-flex justify-content-center pe-3 m-0">
              <div class="userSearcForm form-styles p-0">
                  <input class="form-control searchTerm" type="search" name="userName" (input)="searchData($event)" placeholder="Search"  />
                  <span class="search-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.973" height="12.973" viewBox="0 0 12.973 12.973">
                      <g id="search-outline" transform="translate(0.5 0.5)">
                        <path id="Path_2581" data-name="Path 2581" d="M9.313,4.5a4.813,4.813,0,1,0,4.813,4.813A4.813,4.813,0,0,0,9.313,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
                        <path id="Path_2582" data-name="Path 2582" d="M23.786,23.786l3.362,3.362" transform="translate(-15.382 -15.381)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                      </g>
                    </svg>
                  </span>
              </div>
              <!-- <div class="col-md-3">
                  <input class="form-control searchTerm" type="search" name="userEmail" [(ngModel)]="userEmail"
                  (ngModelChange)="searchByEmail()" placeholder="Search By Email " />
              </div> -->
          </div>
        </div>
    </div>
  </div>

  <section class="contents-main">
    <div class="container-fluid card p-0">
      <div class="card pt-0 m-0">
        <ngx-datatable #myTable [style.width]="'100%'" class="material expandable table-responsive" [rows]="row" [headerHeight]="50" 
        [footerHeight]="50"
          [columnMode]="'force'" [scrollbarH]="true" [rowHeight]="'auto'" [externalSorting]="true"
          [messages]="{emptyMessage: 'No User found.'}">
          <ngx-datatable-row-detail [rowHeight]="0" #myDetailRow (toggle)="onDetailToggle(row)">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            </ng-template>
          </ngx-datatable-row-detail>

          <ngx-datatable-column [sortable]="false" [minWidth]="'10%'" name="Sr. No" prop="user_id">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                <div class="form-group" style="margin-bottom: 0px"></div>
                <span>{{ row.srNo }}</span>
            </ng-template>
        </ngx-datatable-column>    

          <ngx-datatable-column [sortable]="false" [minWidth]="'17%'" prop="complaintNo" name="Name">
            <ng-template ngx-datatable-header-template let-column="column">
              <a class="cursor-pointer" (click)="sortUsers('username')">
                Name
                <span class="sort-btn"
                      [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'username',
                                  'datatable-icon-up': sortColumn === 'username' && sortOrder === 'asc',
                                  'datatable-icon-down': sortColumn === 'username' && sortOrder === 'desc'}">
                </span>
              </a>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="form-group" style="margin-bottom: 0px"></div>
              <span>{{ row.username }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column  [sortable]="false" [minWidth]="'17%'"   prop="department"
            name="Email">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="row.priorityID">
              <div class="form-group" style="margin-bottom: 0px"></div>
              <span>
                {{ row.email }}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column   [sortable]="false" [minWidth]="'17%'"  prop="role" name="Role">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ row.role }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column   [sortable]="false" [minWidth]="'17%'"   prop="assignedTo"
            name="Join Date">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ row.date_joined | date: 'short' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column  [sortable]="false" [minWidth]="'17%'"   prop="requestType"
            name="Status">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <!-- <span class="badge badge-success badge-sm" style="font-size: 8px; padding: 5px 13px"
                *ngIf="row.is_active === true">Active</span> -->
              <span class="badge badge-outline-danger" *ngIf="row.is_active === false"
                style="font-size: 8px; padding: 5px 13px">InActive</span>
            </ng-template>
          </ngx-datatable-column>
          

          <ngx-datatable-column name="Action" [sortable]="false" [resizeable]="true"   [minWidth]="'5%'"
            >
            <ng-template let-row="row" ngx-datatable-cell-template>
            
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="View"
                  >
                  </li>
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Edit"
                  >
                  </li>
                  
                  <li
                    class="list-inline-item delete"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Activate"
                    (click)="enableUser(row.user_id);"
                  >
                    <a
                    title="Activate User"
                      data-bs-toggle="modal"
                      class="badge badge-success badge-sm" style="font-size: 8px; padding: 5px 13px; cursor: pointer;color: #fff;"
                    >
                      <!-- <i class=" ri-close-circle-line  "></i>  -->
                      Activate
                    </a>
                  </li>
                </ul>
              </td>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <div class="table-meta">
          <div class="entries">
              <!-- <span>Show</span>
              <select>
                  <option>12</option>
              </select> -->
              <span>Total Records: {{this.total_items }}</span>               
          </div>
          <!-- <nav class="table-pagination">
              <ul>
                  <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                  <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                  <li><a class="active">{{ current_page }}</a></li>
                  <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                  <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
              </ul>
          </nav> -->
          <nav class="table-pagination">
            <ul>
                <li>
                    <div class="go-to-page d-flex align-items-center">
                        <label>Go to Page:</label>
                        <input type="number" class="form-control form-control-sm" [(ngModel)]="pageToGo" min="1" [max]="total_pages" (keydown.enter)="goToPage(pageToGo); pageToGo = null">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" (click)="goToPage(pageToGo); pageToGo = null" [disabled]="pageToGo < 1 || pageToGo > total_pages">Go</button>
                        </div>
                    </div>
                </li>                                                                                                
                <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                <li><a class="active">{{ current_page }}</a></li>
                <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
            </ul>
        </nav>
          
      </div>
      </div>
    </div>
  </section>
</section>