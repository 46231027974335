<div class="loaderwrapper" *ngIf="this.loader">
    <span class="loader" ></span> 
</div> 

<!-- <div class="row">
    <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0">Alarm</h4>
        </div>
    </div>
</div> -->

<section class="dashboard-main container-fluid">
    <div class="breadcrumb-main"> 
        <ul class="breadcrumb"> 
            <li><a [routerLink]="">Alarm</a></li> 
            <li class="skip-arrow">Historical Alarms</li>
        </ul>
    </div>

    <div class="contents-main">
        <div class="row form-styles mx-n2">
            <div class="col-12 d-sm-flex justify-content-between align-items-center">
                <div class="d-flex">
                    <div class="col mr-3" style="padding: 7px 0 0;">
                        <button class="btn alaramCleareButton" title="Clear" (click)="onUnClear()" [disabled]="clearAllButtonDisabled">
                            Unclear All
                        </button>
                    </div>
                    <div class="dataTables_length  p-0">
                        <label class="show-entries-list">
                          Show
                          <!-- (change)="changePageSize()" -->
                          <!-- [formControl]="pageSizeControl" -->
                          <select [formControl]="pageSizeControl" (change)="changePageSize()"
                            class="form-control form-control-sm">
                            <option *ngFor="let limit of pageRecordOptions" [value]="limit.value">{{limit.label}}</option>
                          </select>
                          entries
                        </label>
                    </div>
                </div>
                <div class="userSearcForm form-group form-styles ">
                    <input class="form-control searchTerm" type="search" name="plantName" (input)="searchData($event)" placeholder="Search Alarms" />
                    <span class="search-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.973" height="12.973" viewBox="0 0 12.973 12.973">
                            <g id="search-outline" transform="translate(0.5 0.5)">
                            <path id="Path_2581" data-name="Path 2581" d="M9.313,4.5a4.813,4.813,0,1,0,4.813,4.813A4.813,4.813,0,0,0,9.313,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
                            <path id="Path_2582" data-name="Path 2582" d="M23.786,23.786l3.362,3.362" transform="translate(-15.382 -15.381)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
                            </g>
                        </svg>
                    </span>
                </div>
            </div>
            <!-- <div class="col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Search By Station Name</label>
                    <input class="form-control searchTerm" type="search" name="stationName" [(ngModel)]="stationName"
                    (input)="Search($event)" placeholder="Enter Keyword" />
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Search By Esn Code</label>
                    <input class="form-control searchTerm" type="search" name="esnCode" [(ngModel)]="esnCode"
                    (input)="Search($event)" placeholder="Enter Keyword" />
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Search By Dev Name</label>
                    <input class="form-control searchTerm" type="search" name="devName" [(ngModel)]="devName"
                    (input)="Search($event)" placeholder="Enter Keyword" />
                </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-3">
                <div class="form-group">
                    <label for="">Search By Dev Name</label>
                    <input class="form-control searchTerm" type="search" name="devName" [(ngModel)]="devName"
                    (ngModelChange)="SearchdevName()" placeholder="Enter Keyword" />
                </div>
            </div> -->

        
        </div>
        <div class="card w-100">
            <ngx-datatable #myTable [style.width]="'100%'"  class="material expandable table-responsive" [rows]="row" [headerHeight]="50"
                [footerHeight]="0" [columnMode]="'force'" [scrollbarH]="true" [rowHeight]="'auto'" [offset]="0"
                [externalSorting]="true" [messages]="{emptyMessage: 'No Alarm found.'}" (activate)="onActivate($event,ticketDetail)" (sort)="onSort($event)">

            <!-- <ngx-datatable-row-detail   [rowHeight]="293"   #myDetailRow (toggle)="onDetailToggle($event)">
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                    <div  >
                    <div class="row alartDetail">
                        <div class="col-md-3 p-1 border"> <b>Alarm ID: </b> {{   row.alarmId}} </div>
                        <div class="col-md-3 p-1 border"><b> Station Name: </b> {{ row.stationName }} </div>
                        <div class="col-md-3 p-1 border"> <b>Alarm Severity: </b> {{ row.alarmSeverity }}</div>
                        <div class="col-md-3 p-1 border"><b>Status: </b>{{ row.status }} </div>
                        <div class="col-md-3 p-1 border"><b>Alarm Type: </b>{{ row.alarmType }} </div>
                            <div class="col-md-3 p-1 border"> <b>Alarm Name: </b>{{ row.alarmName }}</div>
                        <div class="col-md-3 p-1 border"> <b>Dev Name: </b>{{ row.devName }}</div>
                        <div class="col-md-3 p-1 border"> <b>Dev Type Id: </b>{{ row.devTypeId }} </div>
                        <div class="col-md-3 p-1 border"> <b>Occurrence Time: </b>{{ row.occurrenceTime }} </div>
                        <div class="col-md-3 p-1 border"><b>Cause Id: </b>{{ row.causeId }} </div>
                        <div class="col-md-3 p-1 border"><b>EsnCode: </b>{{ row.esnCode }}</div>
                        <div class="col-md-6 p-1 border"><b>Alarm Cause: </b>{{ row.alarmCause }} </div>
                        <div class="col-md-6 p-1 border"> <b>Suggestion: </b>{{ row.suggestion }}</div>
                    </div>                 
                    </div>                     
                </ng-template>
                </ngx-datatable-row-detail> -->
                <ngx-datatable-column [sortable]="false">
                    <!-- Checkbox in the column header -->
                    <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                      <div>
                        <label class="custom-checkbox large">
                          <input type="checkbox" [checked]="columnCheckboxSelected" (change)="toggleAllRows($event.target.checked)" />
                          <span class="checkbox-item"></span>
                        </label>
                      </div>
                    </ng-template>
                    
                    <!-- Checkbox in each row -->
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <label class="custom-checkbox large">
                        <input type="checkbox" [checked]="row.selected" (change)="toggleRowSelection(row)"/>
                        <span class="checkbox-item"></span>
                      </label>
                    </ng-template>
                  </ngx-datatable-column>   
                                 <!-- commented by eman -->
            <!-- <ngx-datatable-column [resizeable]="true" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <label class="custom-checkbox large">
                        <input type="checkbox"/>
                        <span class="checkbox-item"></span>
                    </label>
                    <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
                    title="Expand/Collapse Row" (click)="toggleExpandRow(row)">
                    </a>
                </ng-template> 
            </ngx-datatable-column> -->
            
            <ngx-datatable-column   [sortable]="false" name="Alarm ID" prop="id" >
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="row.id">
                    <div class="form-group" style="margin-bottom: 0px"></div>
                    <span>
                        {{ row.alarmId }}
                    </span>
                </ng-template>
            </ngx-datatable-column>

            <!-- <ngx-datatable-column  [sortable]="true" name="Station Name" prop="stationName"
                >
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"
                    let-value="row.stationName">
                    <div class="form-group" style="margin-bottom: 0px">
                        <span>
                            {{ row.stationName }}
                        </span>
                    </div>
                </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column [sortable]="false" name="Station Name" prop="stationName">
                <ng-template ngx-datatable-header-template let-column="column">
                  <a class="cursor-pointer" (click)="sortAlarms('stationName')">
                    Station Name
                    <span class="sort-btn"
                          [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'stationName',
                                      'datatable-icon-up': sortColumn === 'stationName' && sortOrder === 'asc',
                                      'datatable-icon-down': sortColumn === 'stationName' && sortOrder === 'desc'}">
                    </span>
                  </a>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="form-group" style="margin-bottom: 0px">
                    <span>{{ row.stationName }}</span>
                  </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column   [sortable]="false" name="Alarm Severity" prop="alarmSeverity">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"
                    let-value="row.alarmSeverity">
                    <div class="form-group" style="margin-bottom: 0px"></div>
                    <span>
                        {{ row.alarmSeverity }}
                    </span>
                </ng-template>
            </ngx-datatable-column>
            
            <!-- <ngx-datatable-column   [sortable]="true" name="Alarm Name" prop="alarmName">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"
                    let-value="row.alarmName">
                    <div class="form-group" style="margin-bottom: 0px"></div>
                    <span>
                        {{ row.alarmName }}
                    </span>
                </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column [sortable]="false" name="Alarm Name" prop="alarmName">
                <ng-template ngx-datatable-header-template let-column="column">
                <a class="cursor-pointer" (click)="sortAlarms('alarmName')">
                    Alarm Name
                    <span class="sort-btn"
                        [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'alarmName',
                                    'datatable-icon-up': sortColumn === 'alarmName' && sortOrder === 'asc',
                                    'datatable-icon-down': sortColumn === 'alarmName' && sortOrder === 'desc'}">
                    </span>
                </a>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="form-group" style="margin-bottom: 0px"></div>
                <span>{{ row.alarmName }}</span>
                </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column   [sortable]="true" name="Dev Name" prop="priority" >
    
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"
                    let-value="row.devName" (click)="showTicketList()">
                    <div class="form-group" style="margin-bottom: 0px"></div>
                    <span>
                        {{ row.devName }}
                    </span>
                </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column [sortable]="false" name="Dev Name" prop="devName">
                <ng-template ngx-datatable-header-template let-column="column">
                  <a class="cursor-pointer" (click)="sortAlarms('devName')">
                    Dev Name
                    <span class="sort-btn"
                          [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'devName',
                                      'datatable-icon-up': sortColumn === 'devName' && sortOrder === 'asc',
                                      'datatable-icon-down': sortColumn === 'devName' && sortOrder === 'desc'}">
                    </span>
                  </a>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="form-group" style="margin-bottom: 0px"></div>
                  <span>{{ row.devName }}</span>
                </ng-template>
              </ngx-datatable-column>
            <!-- <ngx-datatable-column [sortable]="true" name="Dev Name" prop="devName">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"
                  let-value="row.devName">
                  <div class="form-group" style="margin-bottom: 0px"></div>
                    <span>
                      {{ row.devName }}
                    </span>                 
                </ng-template>
              </ngx-datatable-column> -->

            <ngx-datatable-column    [sortable]="false" name="Occurrence Time" prop="occurrenceTime">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"
                    let-value="row.occurrenceTime">
                    <div class="form-group" style="margin-bottom: 0px"></div>
                    <span>
                        {{ row.occurrenceTime }}
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="false" name="Status" prop="status">
                <ng-template ngx-datatable-header-template let-column="column">
                  <a class="cursor-pointer" (click)="sortAlarms('status')">
                    Status
                    <span class="sort-btn"
                          [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'status',
                                      'datatable-icon-up': sortColumn === 'status' && sortOrder === 'asc',
                                      'datatable-icon-down': sortColumn === 'status' && sortOrder === 'desc'}">
                    </span>
                  </a>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="form-group" style="margin-bottom: 0px"></div>
                  <span>{{ row.status }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="false" name="Esn Code" prop="esnCode">
                <ng-template ngx-datatable-header-template let-column="column">
                  <a class="cursor-pointer" (click)="sortAlarms('esnCode')">
                    Esn Code
                    <span class="sort-btn"
                          [ngClass]="{'datatable-icon-sort-unset': sortColumn !== 'esnCode',
                                      'datatable-icon-up': sortColumn === 'esnCode' && sortOrder === 'asc',
                                      'datatable-icon-down': sortColumn === 'esnCode' && sortOrder === 'desc'}">
                    </span>
                  </a>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="form-group" style="margin-bottom: 0px"></div>
                  <span>{{ row.esnCode }}</span>
                </ng-template>
              </ngx-datatable-column>

            <!-- <ngx-datatable-column   [sortable]="true" name="Status" prop="status">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"
                    let-value="row.status">
                    <div class="form-group" style="margin-bottom: 0px"></div>
                    <span>
                        {{ row.status }}
                    </span>
                </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column   [sortable]="true" name="Esn Code" prop="esnCode">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row"
                    let-value="row.esnCode">
                    <div class="form-group" style="margin-bottom: 0px"></div>
                    <span>
                        {{ row.esnCode }}
                    </span>
                </ng-template>
            </ngx-datatable-column> -->

            <ngx-datatable-column [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <button class="btn alaramCleareButton cross-btn" title="Unclear" (click)="onUnClear()" [disabled]="row.clearButtonDisabled">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" fill="currentColor"/></svg>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
         
        <div class="table-meta">
            <div class="entries">
                <!-- <span>Show</span>
                <select>
                    <option>12</option>
                </select> -->
                <span>Total Records: {{this.total_items }}</span>               
            </div>
            <!-- <nav class="table-pagination">
                <ul>
                    <li><a class="no-bg" id="prevPage" (click)="this.Pagination(this.previous_page)"><i class="fa fa-chevron-left"></i></a></li>
                    <li><a class="active" [routerLink]="" (click)="Pagination(this.current_page)">{{this.current_page }}</a></li>
                    <li><a (click)="this.Pagination((this.current_page + 1))" *ngIf="(this.current_page + 1) <= this.total_pages">{{(this.current_page) + 1 }}</a></li>
                    <li><a (click)="this.Pagination((this.current_page + 2))"  *ngIf="(this.current_page + 2) <= this.total_pages">{{(this.current_page) + 2}}</a></li>
                    <li class="no-bg" *ngIf="((+this.current_page + 3)) < this.total_pages">...</li>
                    <li><a (click)="this.Pagination(this.total_pages)" *ngIf="(this.current_page + 2) < this.total_pages">{{this.total_pages }}</a></li>
                    <li><a  class="no-bg" id="nextPage"  (click)="this.Pagination(this.next_page)"><i class="fa fa-chevron-right"></i></a></li>
                </ul>
            </nav> -->
            <nav class="table-pagination">
                <ul>
                    <li>
                        <div class="go-to-page d-flex align-items-center">
                            <label>Go to Page:</label>
                            <input type="number" class="form-control form-control-sm" [(ngModel)]="pageToGo" min="1" [max]="total_pages" (keydown.enter)="goToPage(pageToGo); pageToGo = null">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" (click)="goToPage(pageToGo); pageToGo = null" [disabled]="pageToGo < 1 || pageToGo > total_pages">Go</button>
                            </div>
                        </div>
                    </li>       
                    <li><a class="init-bg" id="firstPage" (click)="goToPage(1)" [class.disabled]="current_page === 1">1</a></li>
                    <li><a class="no-bg" id="prevPage" (click)="navigatePage('prev')"><i class="fa fa-chevron-left"></i></a></li>
                    <li><a class="active">{{ current_page }}</a></li>
                    <li><a class="no-bg" id="nextPage" (click)="navigatePage('next')" [class.disabled]="current_page === total_pages"><i class="fa fa-chevron-right"></i></a></li>
                    <li><a class="init-bg" id="lastPage" (click)="goToPage(total_pages)" [class.disabled]="current_page === total_pages">{{this.total_pages}}</a></li>
                </ul>
            </nav>
        </div>
       
        </div>
    </div>
</section>

<ng-template #ticketDetail let-c="close" let-d="dismiss" let-width="500">
    <form>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Alarm</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.633" height="14.25" viewBox="1222.583 108.567 13.633 14.25">
                    <g data-name="Group 2829"><g data-name="chevron-back"><path d="m1224 110 5.393 5.393 5.393-5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g><g data-name="chevron-back"><path d="m1224 121.393 5.393-5.393 5.392 5.393" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 5498"/></g></g>
                </svg>
            </button>
        </div>


        <div class="modal-body">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <!-- <th scope="col">ID</th> -->
                        <th scope="col">Station Name</th>
                        <th scope="col">Station Code</th>
                        <th scope="col">Occurrence Time</th>
                        <th scope="col">Device Name</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>{{ this.alarmDetailsItem.stationName }}</td>
                        <td>{{ this.alarmDetailsItem.stationCode }}</td>
                        <td>{{ this.alarmDetailsItem.occurrenceTime }}</td>
                        <td>{{ this.alarmDetailsItem.devName }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Alarm Id</th>
                        <th scope="col">Alarm Severity</th>
                        <th scope="col">Alarm Name</th>
                        <th scope="col">Alarm Cause</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>{{ this.alarmDetailsItem.alarmId }}</td>
                        <td>{{ this.alarmDetailsItem.alarmSeverity }}</td>                        
                        <td>{{ this.alarmDetailsItem.alarmName }}</td>
                        <td>{{ this.alarmDetailsItem.alarmCause }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">status</th>
                        <!-- <th scope="col">Occurrence Time</th> -->
                        <th scope="col">Cause Id</th>
                        <th scope="col">Esn Code</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <!-- <td>{{ alarmInfo.devTypeId }}</td> -->
                        <td>{{ alarmDetailsItem.status }}</td>
                        <td>{{ alarmDetailsItem.causeId }}</td>

                        <td>{{ alarmDetailsItem.esnCode }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <!-- <th scope="col">Alarm Cause</th> -->
                        <th scope="col">Suggestion</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <!-- <td>{{ alarmInfo.alarmCause }}</td> -->
                        <td>{{ alarmDetailsItem.suggestion }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
</ng-template>
